import React, { useEffect, useState } from 'react'
import { GoogleMap, MarkerF, useJsApiLoader, Autocomplete } from '@react-google-maps/api';

import firebase from 'firebase';
import { paciSearch } from '../../../actions/order.actions';

const containerStyle = {
  width: '100%',
  height: '288px'
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  enableEventPropagation: false,
  clickableLabels: false,
  isableDoubleClickZoom: false,
  clickableIcons: false,
  disableDoubleClickZoom: false,
  zoom: 8,
};

const autocompleteOpts = {
  strictBounds: true,
  componentRestrictions: { country: "kw" }
};

function EditAddressMap(props: any) {
  // const center = new google.maps.LatLng(29.241971299229583,47.96440653977878);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.REACT_APP_MAP_API_KEY}`,
    libraries: ['places']
  });
  const [marker, setMarker]: [google.maps.LatLng | any, Function] = useState(isLoaded && window.google && new window.google.maps.LatLng(props.updatedAddress?.location?._lat, props.updatedAddress?.location?._long));
  const [autocomplete, setAutocomplete]: [any, Function] = useState(undefined);

  function onLoad(autocomplete: any) {
    setAutocomplete(autocomplete);
    const center = new window.google.maps.LatLng(props.updatedAddress?.location?._lat, props.updatedAddress?.location?._long);
    setMarker(center);
  }

  function onPlaceChanged() {
    if (autocomplete !== null) {
      const name = autocomplete.getPlace()?.address_components?.length ?
        autocomplete.getPlace()?.address_components?.reduce((acc: any, item: any) => acc + item.long_name + " ", "") :
        autocomplete.getPlace()?.name;
      props?.setSelectedMapAddress(name);
      const lat = autocomplete.getPlace()?.geometry?.location.lat();
      const lng = autocomplete.getPlace()?.geometry?.location.lng()
      const location = new firebase.firestore.GeoPoint(lat, lng);
      setMarker(isLoaded && new window.google.maps.LatLng(lat, lng))
      props?.setLocation(location);
      const updatedAddress = {
        ...props?.updatedAddress,
        addressStr: name,
        location: location,
        area_id: null,
        block_id: null,
        areaAr: null,
        streetAr: null,
        blockAr: null,
        area: null,
        street_id: null,
        block: null
      };
      
      props?.setUpdatedAddress(updatedAddress);
      

    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  const handleMapClick = async(event: any) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    console.log(`Map clicked at latitude: ${lat} and longitude: ${lng}`);
    setMarker(new window.google.maps.LatLng(lat, lng));
    const location = new firebase.firestore.GeoPoint(lat, lng);
    props?.setLocation(location);
    // Create a Geocoder instance
    const geocoder = new window.google.maps.Geocoder();

    // Call the geocode method
    await geocoder.geocode({ 'location': { lat: lat, lng: lng } }, (results: any, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          console.log("Address details:", results[0].formatted_address);
          props?.setSelectedMapAddress(results[0].formatted_address);
          const updatedAddress = {
            ...props?.updatedAddress,
            addressStr: results[0].formatted_address, 
            location: location
          }
          props?.setUpdatedAddress(updatedAddress);
        } else {
          console.log('No results found');
          const updatedAddress = {
            ...props?.updatedAddress,
            addressStr: '', 
            location: location
          }
          props?.setUpdatedAddress(updatedAddress);
        }
      } else {
        console.log('Geocoder failed due to:', status);
      }
    });
    //Call Paci api
    const paciDetails = await paciSearch(lat, lng);    
    props.setPaciDetails(paciDetails);
  };

  useEffect(() => {
    if (props.location) setMarker(isLoaded && new window.google.maps.LatLng(props.location?.latitude, props.location?.longitude));
  }, [props.location]);

  return isLoaded ? (
    <div className="relative bg-white rounded-t-md w-full overflow-hidden">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={marker || new window.google.maps.LatLng(29.241971299229583, 47.96440653977878)}
        zoom={14}
        options={options}
        onClick={handleMapClick}
      >
        <MarkerF draggable={false}
          position={marker || new window.google.maps.LatLng(29.241971299229583, 47.96440653977878)} />
        <Autocomplete
          options={autocompleteOpts}
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
        >
          <input
            type="text"
            placeholder="Search..."
            className="absolute top-1 right-1 z-10 w-48 px-2 h-8 cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-0 focus-visible:ring-offset-blue-500 sm:text-sm"
          />
        </Autocomplete>
      </GoogleMap>
    </div>
  ) : <></>
}

export default React.memo(EditAddressMap)
