import { useState } from "react";
import Dialog from "../../../../../components/Dialog/SideDrawer";
import EditAddress from "./editAddressDialog";
import { useAppSelector } from "../../../../../redux";
import { CUSTOMER_STORE_NAME } from "../../../../../contants/stores";
import { getSavedAddressesById } from "../../../../customer/actions/customer.actions";

export default function AddressDetailsBox(props: any) {
  const address = props?.address;
  const title = props?.type === "pickup" ? "Pickup from" : "Delivery to";
  const [isEditAddressDialogOpen, setAddressDialogOpen] = useState(false);
  const saveAddresses = useAppSelector(state => state[CUSTOMER_STORE_NAME].saveAddresses);

  return (
    <>
      <div className="flex-1 truncate">
        <div className="">
          <div className="card px-4 py-2 border border-light rounded-lg truncate">
            <div className="flex justify-between items-center border-b border-light pb-2 mb-2 truncate">
              <h2 className="font-bold text-base truncate">
                {title} {address?.name}
              </h2>
              {((props?.type === 'pickup' && props?.orderType === 'COURIER') || props.subStatus === "PAYMENT_PENDING") ? <></> :
                <div>
                  <button
                    type="button" onClick={() => {
                      setAddressDialogOpen(true);
                      if(!saveAddresses?.length) getSavedAddressesById(props.userId)}
                    }
                    className="flex items-center text-indigo-400 hover:text-indigo-300 ml-2"
                  >
                    <span className="mr-1 text-xs">Edit</span>
                    <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd"></path></svg>
                  </button>
                </div>
              }
            </div>
            <div className="flex flex-col gap-2 justify-start items-start text-sm">
              <div className="flex items-center">
                <p className="w-24">Contact name</p>
                :<span className="ml-2">{address?.contactName ?? props.customerName}</span>
              </div>
              <div className="flex items-center">
                <p className="w-24">Contact phone</p>
                :<span className="ml-2 cursor-pointer" onClick={() => {
									(window as any)["_jorel-callPhoneNumber"](address?.contactPhone ?? props.customerPhone);
								}}>{address?.contactPhone ?? props.customerPhone}</span>
              </div>
            </div>
          </div>
        </div>
        {isEditAddressDialogOpen ? <Dialog open={isEditAddressDialogOpen} onClose={() => setAddressDialogOpen(false)} title={`Edit ${props?.type} address`}>
          <EditAddress address={address} otherAddress={props?.otherAddress} title={title} type={props?.type} orderId={props?.orderId} orderNo={props?.orderNo} orderValidation={props?.orderValidation}
          deliveryNo={props?.deliveryNo} orderType={props?.orderType} fleetId={props?.fleetId} userId={props?.userId} primaryStatus={props?.primaryStatus}/>
        </Dialog> : null}
      </div>
    </>
  )
}
