import { callSupportApi } from "../services/support.services"

export const supportAction = async (orderNo: string, event: string) => {
  try {
    const data = {
      'orderNo': orderNo,
      'event': event
    }
  
    await callSupportApi(data)
  } catch (error) {
    console.log('callSupportApi', error)
  }
}