import { createSlice } from "@reduxjs/toolkit"
import { CUSTOMER_STORE_NAME } from "../../../contants/stores";
import { Customer } from "../types/customer.data";

interface CustomerState {
  customer: Customer,
  searchCustomer: any;
  customerTagsConfig: any;
  savedAddresses: any[];
  status: string,
  error: Error | undefined
}

const initialState: CustomerState = {
  customer : {},
  searchCustomer: {},
  customerTagsConfig: {},
  savedAddresses: [],
  status: 'idle',
  error: undefined
}

export const slice = createSlice({
  name: CUSTOMER_STORE_NAME,
  initialState,
  reducers: {
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    setSearchCustomer: (state, action) => {
      state.searchCustomer = action.payload;
    },
    setcustomerTagsConfig: (state, action) => {
      state.customerTagsConfig = action.payload;
    },
    setSavedAddresses: (state, action) => {
      state.savedAddresses = action.payload;
    },
  },
});



export const actions = slice.actions
export const reducer = slice.reducer
