//getOrders -> sort by defined matrix

import { DATABASE, FUNCTION, STORAGE} from "../../../config/firebase"
import { BASE_URL } from "../../../config/url";
import { assignValueToObject } from "../types/order.data";
import { TOrderItem } from "../types/order.item";

type Listeners = {
  onUpdate?: Function,
  onAdd?: Function,
  onGetAll?: Function
}

type fetchActiveOrdersProps = {
  listeners: Listeners
}

export const fetchActiveOrders = (id: string, isAgent: boolean, { listeners } : fetchActiveOrdersProps) => {
  const query = isAgent ? DATABASE.collection("orders").where("agent.id", "==", id) : DATABASE.collection("orders")
  return query
  .where("primaryStatus", "!=", "OTHERS")
  .orderBy("primaryStatus")
  .orderBy("placedAt", "desc")
  .orderBy("deliveryAt", "asc")
  .onSnapshot((snapshot) => {
    snapshot.docChanges().forEach(function (change) {
      if (change.type === "added") {
        listeners && listeners.onAdd && listeners.onAdd({
          id: change.doc.id,
          data: change.doc.data()
        });
      }
      if(change.type === "modified") {
        listeners && listeners.onUpdate && listeners.onUpdate({
          id: change.doc.id,
          data: change.doc.data()
        });
        if(change.doc.data().subStatus == "BUDDY_REQ"){ 
          const data = {
            data:change.doc.data()
          };
          omsOrderChangeFleetTag(data);
        }
      }
    });
    listeners && listeners.onGetAll && listeners.onGetAll({
      orders: snapshot.docs.map((q) => {        
        return assignValueToObject(q);
      })
    });
  });
}

export const getOrdersByFleetId = async (fleetId: number) => {
  try {
    const query = await DATABASE.collection("orders")
    .where("deliveryInfo.fleetId", "==", fleetId)
    .where("primaryStatus", "!=", "OTHERS").get();
    
    if(query.empty) {
      return [];
    }
    return query.docs;
  } catch (error) {
    console.log("Error fetching buddy orders: ", error)
  }
}
export const saveCart = async(id: string, items: TOrderItem[], ETAData: any, discountData: any, itemCount: number, itemTotal: number, orderValidation: any) =>{

  try {
    const ref = await DATABASE.collection("orders").doc(id);
    await DATABASE.runTransaction(async (transaction) => {
      const orderDoc = await transaction.get(ref);
      if (!orderDoc.exists) {
        throw "Document does not exist!";
      }
      transaction.update(orderDoc.ref, { 
        "orderInfo.items": items, 
        "orderInfo.discount": discountData, 
        "orderInfo.deliveryFee": ETAData.deliveryFee,
        "orderInfo.serviceFee": ETAData.serviceFee,
        "orderInfo.itemCount": itemCount,
        "orderInfo.itemTotal": itemTotal,
        "orderValidation": orderValidation
      });
    });
    console.log("Transaction successfully committed!");
  } catch (e) {
    console.log("Transaction failed: ", e);
  }

}

export const callOmsOrderCartUpdate = async (orderNo: string, updatedBy: string) => {
  try {
    const reqBody = {
      "orderNo": orderNo,
      "updatedBy": updatedBy
    }
  
    const request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody),
    };
  
    const url = `${BASE_URL}omsOrderOnCartUpdate`;
    fetch(url, request);
    console.log("Cart updated!");
  } catch (error) {
    console.log("OMS cart update API error:", error);
  }
}

export const callETAUpdate = async (orderId: string, orderNo: string) => {
  try {
    const payload = [{
      eventType: 'UPDATE_ORDER_ETA',
      data: {
        orderId: orderId,
        orderNo: orderNo,
      }
    }];
  
    const request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
  
    const url = `${BASE_URL}triggerPubSubEvent`;
    await fetch(url, request);
    console.log("ETA updated!");
  } catch (error) {
    console.log("ETA update API error:", error);
  }
}

export const callOrderETAService = async(props: any ) => {
  try {
    const url = `${BASE_URL}getOrderEtaAndPrice?origin=${props.pickup.latitude},${props.pickup.longitude}&destination=${props.delivery.latitude},${props.delivery.longitude}&orderType=${props.orderType}&storeId=${props.storeId}&itemCount=${props.itemCount}&itemTotal=${props.itemTotal}`
    return await fetch(url).then(res => res.json());
  } catch (error) {
    console.log("callOrderETAService Error: ", error)    
  }
}

export const applyPromoCode = async (body: any) => {
  try {
    return await fetch(`${BASE_URL}/applyPromoCode`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(body)
    }).then(res => res.json());
  } catch (error) {
    console.log("error-applyPromoCode", error);
  }
}

export const orderSearch = async (orderNo: string, { listeners } : fetchActiveOrdersProps) => {
  try {
    // console.log(orderNo)
    // let query = await DATABASE.collection("orders").where("orderNo", "==", orderNo).onSnapshot();
    const ref = DATABASE.collection("orders").where("orderNo", "==", orderNo).onSnapshot((snapshot) => {
      snapshot.docChanges().forEach(function (change) {
        if (change.type === "added") {
          listeners && listeners.onAdd && listeners.onAdd();
        }
        if(change.type === "modified") {
          listeners && listeners.onUpdate && listeners.onUpdate({
            id: change.doc.id,
            data: change.doc.data()
          });
        }
      });
      listeners && listeners.onGetAll && listeners.onGetAll({
        orders: snapshot.docs.map((q) => {        
          return assignValueToObject(q);
        })
      });
    });
    return ref;
  } catch (error) {
    console.log("error-orderSearch", error);
  }
}

export const callSearch = async (searchAtribute: string, phone: string) => {
  try {
    return await DATABASE.collection("orders")
    .where("primaryStatus", "!=", "OTHERS")
    .where(searchAtribute, 'in', [phone, "965" + phone, "+965" + phone, phone.substring(1)])
    .orderBy("primaryStatus")
    .orderBy("placedAt", "desc")
    .get();
  } catch (error) {
    console.log("Ziwo search error: ", error);
  }
  
}

export const unassignOrder = async(deliveryNo: string) => {
  try {
    
    const url = `${BASE_URL}v1/b/ig/order/unassign-buddy`;
    const requestBody = {
      "order_number": deliveryNo,
    };
    const request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };
  
    const response = await fetch(url, request);
    const responseJson = await response.json();
    console.log(`Reassign API response: ${responseJson}`);
  } catch (error) {
    console.log(`An error was occured while reassigning orders ${error}`);
  }
}

export const save = async(id: string, data: any) => {
  try {
    console.log("save", id, data);
    await DATABASE.collection("orders").doc(id).update(data);

    // await DATABASE.runTransaction(async (transaction) => {
    //   const orderDoc = await transaction.get(ref);
    //   if (!orderDoc.exists) {
    //     throw "Document does not exist!";
    //   }
    //   transaction.update(orderDoc.ref, data);
    // });
    console.log("Transaction successfully committed!");
  } catch (e) {
    console.log("Transaction failed: ", e);
  }

}

export const checkDelayNotificationValidity = async(orderId: string, manualDelayObj: any) => {
  try {
    const reqBody = {
      "orderId": orderId,
      "isManuallyTriggered": true,
      "manualDelayObj": manualDelayObj,
    }
    await fetch(`${BASE_URL}/omsOrderNotifyOfDelay`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(reqBody)
    }).then(res => res.json());
  } catch (error) {
    console.log("error-checkDelayNotificationValidity: ", error);
  }

}

export const saveAddressInCourier = async (payload: any, orderNo: string, type: string, address: any, id: string, fleetConfig:any) => {
  try {
    const courierBaseUrl = fleetConfig.courierBaseUrl;
    const courierApiKey = fleetConfig.courierApiKey;
    const courierAuthKey = fleetConfig.courierAuthKey;
    const url = `${courierBaseUrl && courierBaseUrl}/v1/b/ig/update/${orderNo}/${type === 'pickup' ? 'pickup': 'drop-off'}`;
    const requestHeaders = {
      'x-api-key': `${courierApiKey && courierApiKey}`,
      'Authorization': `Bearer ${courierAuthKey && courierAuthKey}`,
      'Content-Type': 'application/json'
    }
    const reqBody = {
      method: 'PUT',
      headers: new Headers(requestHeaders),
      body: JSON.stringify(payload)
    }
    fetch(url, reqBody);
    
  } catch (error) {
    console.log("error-updateAddressInCourier", error);
  }
}

export const callOmsOrderComplete = async (body: any) => {
  try {
    const rawResponse = await fetch(`${BASE_URL}/omsOrderComplete`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(body)
    });
  } catch (error) {
    console.log("error-callOmsOrderComplete", error);
  }

}
export const omsOrderChangeFleetTag = async (body: any) => {
  try {
    const rawResponse = await fetch(`${BASE_URL}omsOrderChangeFleetTag`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(body)
    });
  } catch (error) {
    console.log("error-omsOrderChangeFleetTag", error);
  }

}
export const serachPaciFromLoc = async(lat: number, lng:number, config: any) => {
  try {
    
    const res = await locationSearch(config, lat, lng);
    
    return res;

  } catch (error) {
    console.log("Error getting the detilas for the location: ", error)
  }
}

export async function updateToken(token: string) {
  await DATABASE.collection("config").doc("business_config").update({"paciArcgis.token" : token});
}

export async function generateToken(config: any) {
  const url = config?.url + config?.generateTokenSlug;
  console.log("Token url: ", url)
  try {

    const reqBody =  new URLSearchParams({
      username: config?.username,
      password: config?.password,
      referer: "ip",
      expiration: config?.expiration,
      f: "pjson",
    });

    const request = {
      method: "POST",
      body: reqBody,
    };

    console.log("Token request: ", request)

    const response = await fetch(url, request);

    const responseJson = await response.json();
    console.log("PACI generate token response:", response, responseJson);
    return responseJson.token;
  } catch (err) {
    console.log("Error generating token:", err);
  }
}

async function locationSearch(config: any, lat: number, lng: number) {
  const geometry = `{y:${lat},x:${lng}, Spatial Reference:4326}`;
  const mapExtent = `XMin: 5069581.550573585 YMin: 3310706.102544307 XMax: 5534770.219630809 YMax: 3526899.554510004 Spatial Reference: 102100 (3857)`;

  const params = new URLSearchParams();
  params.append("geometry", geometry);
  params.append("geometryType", "esriGeometryPoint");
  params.append("sr", "4326");
  params.append("layers", "all");
  params.append("token", config.token);
  params.append("tolerance", "100");
  params.append("mapExtent", mapExtent);
  params.append("imageDisplay", "600,550,96");
  params.append("returnGeometry", "false");
  params.append("f", "pjson");

  const url = `${config?.url + config?.searchSlug}?${params.toString()}`;

  console.log("Constructed URL:", url);

  try {
    const response = await fetch(url, {
      method: "GET",
    });

    const responseJson = await response.json();
    console.log("PACI location search response:", responseJson);

    return responseJson;
  } catch (err) {
    console.log("Error generating search:", err);
  }
}

export const callUnAssignAPI = async (deliveryNo: string, fleetConfig: any) => {
  try {
    const courierBaseUrl = fleetConfig.courierBaseUrl;
    const courierApiKey = fleetConfig.courierApiKey;
    const courierAuthKey = fleetConfig.courierAuthKey;

    const url = `${courierBaseUrl && courierBaseUrl}/v1/b/ig/order/unassign-buddy`;
    const reqBody = {
      method: 'POST',
      headers: new Headers({
        'x-api-key': `${courierApiKey && courierApiKey}`,
        'Authorization': `Bearer ${courierAuthKey && courierAuthKey}`,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        "order_number": deliveryNo,
      })
    }
    const res = await fetch(url, reqBody);
    return await res.json();
  } catch (error) {
    console.log("error-callUnAssignAPI", error);
  }
}

export const changeOrderStatus = async(orderId: string, deliveryNo:string) => {
  await DATABASE.collection("orders").doc(orderId)
  .update({
    "deliveryInfo": {deliveryNo: deliveryNo},
    "primaryStatus": "PENDING",
    "subStatus" : "BUDDY_UNASSIGNED"
  })
}

export const changeOrderType = async (orderId: string, orderType: string) => {
  await DATABASE.collection("orders").doc(orderId).update({orderType})
}

export const reschedule = async (orderNo: string, fleetConfig: any) => {
  try {
    const courierBaseUrl = fleetConfig.courierBaseUrl;
    const courierApiKey = fleetConfig.courierApiKey;
    const courierAuthKey = fleetConfig.courierAuthKey;
    
    const url = `${courierBaseUrl && courierBaseUrl}/v1/b/ig/bulk-order/reschedule`;
    const reqBody = {
      method: 'POST',
      headers: new Headers({
        'x-api-key': `${courierApiKey && courierApiKey}`,
        'Authorization': `Bearer ${courierAuthKey && courierAuthKey}`,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        "order_number": orderNo,
      })
    }
    const res = await fetch(url, reqBody);
    return await res;
  } catch (error) {
    console.log("error-reschedule", error);
  }
}
export const changePaymentInCourier = async (orderNo: string, paymentType: number, cashToCollect: number, fleetConfig: any) => {
  try {
    const courierBaseUrl = fleetConfig.courierBaseUrl;
    const courierApiKey = fleetConfig.courierApiKey;
    const courierAuthKey = fleetConfig.courierAuthKey;
    
    const url = `${courierBaseUrl && courierBaseUrl}/v1/b/ig/update/${orderNo}/payment`;
    const reqBody = {
      method: 'PUT',
      headers: new Headers({
        'x-api-key': `${courierApiKey && courierApiKey}`,
        'Authorization': `Bearer ${courierAuthKey && courierAuthKey}`,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        "payment_type": paymentType,
        "amount_to_collect": cashToCollect,
      })
    }
    const res = await fetch(url, reqBody);
    return await res;
  } catch (error) {
    console.log("error-changePaymentInCourier", error);
  }
}

export const savePaymentDetails = async(orderId: string, paymentType: string, cashToCollect: number) => {
  await DATABASE.collection("orders").doc(orderId)
  .update({
    "orderInfo.paymentType": paymentType,
    "orderInfo.cashToCollect": cashToCollect
  })
}

export const sendNotifications = async(payload: any, service: string) =>{
  const callableReturnMessage = FUNCTION.httpsCallable(service);
    callableReturnMessage(payload).then((result) => {
      console.log(result.data.output);
    }).catch((error) => {
      console.log(`error: ${JSON.stringify(error)}`);
    });
}

export const getBuddyDetails = async(fleetId: number) => {
  try {

    const query = await DATABASE.collection("buddies").where("fleetId", "==", fleetId).get();
    if(query.empty) return;
    const buddyDoc = query.docs[0];
    const buddyData = {
      id: buddyDoc.id,
      pushTokens: buddyDoc.data().pushTokens
    }
    return buddyData;
    
  } catch (error) {
    console.log("getBuddyDetails-error: ", error)
  }
}

export const uploadFileToStorage = (orderId: string, file: any) => {
  if (file) {
    const storageRef = STORAGE.ref(`orders/${orderId}/${file.name}`);
    return storageRef.put(file).then((loadedFile) => {
      return loadedFile.ref.getDownloadURL()
    });
  }
  return undefined;
};
