import { BASE_URL } from "../config/url";

export const triggerPubSubEventCloudFunction = async (payload: any) => {
  const { data, topicName } = payload;
  const bodySchema = [
    {
      data: data,
      eventType: topicName,
    },
  ];
  const temp = JSON.stringify(bodySchema);
  try {
    await fetch(BASE_URL + "triggerPubSubEvent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: temp,
    });
    console.log("Timeline updated!");
  } catch (error: any) {
    console.error(`Received error : ${error}`);
  }
};
