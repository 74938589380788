import { useEffect, useState } from "react";
import Input from "../../../../../../components/Input/input";

export function OrderValidateDialogContent(props: any) {
  
  const [internalNotes, setInternalNotes] = useState(props.notes ?? ['']);
  const [internalValidation, setInternalValidation] : [any, Function] = useState(props.orderValidation);

  useEffect(() => {
    props.setOrderValidation({...internalValidation});
  }, [internalValidation]);

  useEffect(() => {
    props.setNoteList([...internalNotes]);
  }, [internalNotes]);


  const handleValidationsChange = (e: any, key: string) => {
    setInternalValidation({...internalValidation, [key]: e.target.checked});
    if(key === 'cart') {
      setInternalValidation({
        ...internalValidation, [key]: e.target.checked,
        cartValidate: {
          ...internalValidation.cartValidate,
          validatedBeforePickup: e.target.checked && props.primaryStatus === 'PENDING' ? true: false,
        }
      });
    } else if(key === 'pickup') {
      setInternalValidation({
        ...internalValidation, [key]: e.target.checked,
        pickupValidate: {
          ...internalValidation.pickupValidate,
          validatedBeforePickup: e.target.checked && props.primaryStatus === 'PENDING' ? true: false,
        }
      });
    } else {
      setInternalValidation({
        ...internalValidation, [key]: e.target.checked,
        deliveryValidate: {
          ...internalValidation.deliveryValidate,
          validatedBeforePickup: e.target.checked && props.primaryStatus === 'PENDING' ? true: false,
        }
      });
    }
    
  }

  const handleNoteChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const list : any = [...internalNotes];
    list[index] = value;
    setInternalNotes(list);
  };

  const handleNoteRemove = (index: number) => {
    const list = [...internalNotes];
    list.splice(index, 1);
    setInternalNotes(list);
  };

  const handleNoteAdd = () => {
    setInternalNotes([...internalNotes, ""]);
  };

  return (
    <>
      <div className="flex flex-col items-start gap-3 my-2 py-2 mx-3">
        <div className="flex-1">
          <label className="space-x-3"
            key="pickup"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              handleValidationsChange(e, "pickup")
            }}>
            <input type="checkbox" defaultChecked={props.orderValidation?.pickup} value="pickup" className="form-tick  bg-white bg-check h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none" />
            <span className="font-normal text-gray-700">
              Pickup address validation.
            </span>
          </label>
        </div>
        <div className="flex-1">
          <label className="space-x-3"
            key="delivery"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              handleValidationsChange(e, "delivery")
            }}>
            <input type="checkbox" defaultChecked={props.orderValidation?.delivery} value="delivery" className="form-tick  bg-white bg-check h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none" />
            <span className="font-normal text-gray-700">
              Delivery address validation.
            </span>
          </label>
        </div>
        <div className="flex-1">
          <label className="space-x-3"
            key="cart"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              handleValidationsChange(e, "cart")
            }}>
            <input type="checkbox" defaultChecked={props.orderValidation?.cart} value="cart" className="form-tick  bg-white bg-check h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none" />
            <span className="font-normal text-gray-700">
              Cart validation.
            </span>
          </label>
        </div>
      </div>
      <span className="my-2 py-2 mx-3 font-normal text-gray-700">
        Notes
      </span>
      {internalNotes?.map((note: string, index: number) => (
        <div key={index} className="my-2">
          <div className="flex justify-between items-end gap-1 mx-3">
            <Input rules={{
                required: false,
                maxLength: 50
              }} onChange={(e: any) => handleNoteChange(e, index)} type="text" value={note} placeholder={note} name="note"/>
            {internalNotes?.length !== 1 && (
              <button type="button" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
                onClick={() => handleNoteRemove(index)}>
                <svg className="w-4 h-4" fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                </svg>
              </button> )}
            {internalNotes?.length - 1 === index && internalNotes.length < 5 && (
              <div className="cursor-pointer font-bold flex items-center text-sm border-gray-300 py-2 h-8">
                <button onClick={() => handleNoteAdd()}
                  type="button" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center">
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  )
}