import { DATABASE } from "../../../config/firebase";
import { assignValueToObject } from "../types/promocode.data";

//addPromoCode

//deletePromoCode

//editPromoCode

type Listeners = {
  onUpdate?: Function,
  onAdd?: Function,
  onGetAll?: Function
}

type fetchActiveOrdersProps = {
  listeners: Listeners
}

export const fetchPromoCodes = ({ listeners } : fetchActiveOrdersProps) => {
  DATABASE.collection("promo_codes")
  .orderBy("createdAt")
  .onSnapshot((snapshot) => {
    snapshot.docChanges().forEach(function (change) {
      if (change.type === "added") {
        listeners && listeners.onAdd && listeners.onAdd({
          id: change.doc.id,
          data: change.doc.data()
        });
      }
      if(change.type === "modified") {
        listeners && listeners.onUpdate && listeners.onUpdate({
          id: change.doc.id,
          data: change.doc.data()
        });
      }
    });
    listeners && listeners.onGetAll && listeners.onGetAll({
      codes: snapshot.docs.map((q) => {        
        return assignValueToObject(q);
      })
    });
  });
}

export const Delete = async (id: string) => {
  try {
    await DATABASE.collection("promo_codes").doc(id).delete();
  } catch (error) {
    console.log("promocode delete error: ", error)
  }
}

export const create = async (promoCode: any) => {
  try {
    await DATABASE.collection("promo_codes").add(promoCode);
  } catch (error) {
    console.log("promocode create error: ", error)
  }
  
}

export const update = async(id:string, promoCode: any) => {
  try {
    await DATABASE.collection("promo_codes").doc(id).update(promoCode);
  } catch (error) {
    console.log("promocode update error: ", error)
  }
  
}
