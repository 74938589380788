import { PROMOCODE_STORE_NAME } from "../../../contants/stores";
import { store } from "../../../redux";
import { Delete, create, fetchPromoCodes, update } from "../services/promocode.services"
import { actions as promoCodeActions, initialState } from "../store/promocode.store";


export const getPromoCodes = () => {
  try {
    return fetchPromoCodes({
      listeners: {
        onGetAll: (res: any) => {
          if (res.errors) {
            throw new Error("Could not fetch orders");
          }
          // if(res?.orders?.length) updateSelectedOrder(res?.orders[0]?.id ?? '');
          // console.log(res)
          store.dispatch(promoCodeActions.setCodes(res.codes));
        },
        onAdd: () => {},
        onUpdate: (res: any) => {
          store.dispatch(promoCodeActions.setCodes({ ...res }));
        },
      },
    });
  } catch (error) {
    console.log("get-promocodes", error);
  }
}

export const updateQuery = (query: string) => {
  store.dispatch(promoCodeActions.updateSearchQuery(query));
}

export function search(query: string) {
  const codes = store.getState()[PROMOCODE_STORE_NAME].promoCodes;
  
  return codes.filter((item: any) => {
    return (
      item.title.toLowerCase().includes(query.toLowerCase()) || 
      item.orderType.toLowerCase().includes(query.toLowerCase()) || 
      item.discount.on.toLowerCase().includes(query.toLowerCase()) ||
      item.discount.type.toLowerCase().includes(query.toLowerCase()) ||
      item.user.type.toLowerCase().includes(query.toLowerCase())
    );
  });
}

export const updatePromoCodeFilters = (filters: any []) => {
  store.dispatch(promoCodeActions.setAppliedFilters(filters));
}

export const clearFilters = () => {
  store.dispatch(promoCodeActions.setAppliedFilters(initialState.filters));
}

export const deletePromoCode = async (id: string) => {
await Delete(id);
}

export const createPromoCode = async (promoCode: any) => {
  let obj = {...promoCode, expiresAt: new Date(promoCode.expiresAt)}
  await create(obj);
}

export const updatePromoCode = async(id: string, promoCode: any) => {
  let obj = {...promoCode, expiresAt: new Date(promoCode.expiresAt)}
  await update(id, obj)
}