import { Agent } from "../../agent/types/agent.data";
import { Customer } from "../../customer/types/customer.data";
import { TOrderAddress } from "./order.address";
import { OrderInfo } from "./order.orderInfo";
import { OrderStatus } from "./order.status";

export interface Order {
  id: string;
  agent: Agent;
  orderNo: string;
  orderType: string;
  b2bOrderType: number;
  isAlreadyRescheduled: boolean;
  primaryStatus: string;
  subStatus: string;
  deliveryInfo: any;
  placedAt: any;
  deliveryAt: any;
  status: OrderStatus;
  orderInfo: OrderInfo;
  deliveryDistance: number;
  deliveryAddress: TOrderAddress;
  pickupAddress: TOrderAddress;
  pickToDropTime: number;
  timestamps: any;
  sellerInfo: any;
  activePaymentInfo: any;
  cancelInfo: any;
  priorityStatus: any;
  orderValidation: any;
  notes: String[];
  compensation: any;
  user: Customer;
  tag: string;
  applePayRef: any;
  orderDelays: any[];
  tripInfo: any;
}

function _serverTimeStampToDateTime(time: EpochTimeStamp) {
  if (time == null) return null;
  return new Date(time);
}
export function assignValueToObject(query: any) {
  return {
    id: query.id,
    placedAt: query.data().placedAt.seconds * 1000,
    deliveryAt: query.data().deliveryAt.seconds * 1000,
    agent: query.data().agent,
    orderNo: query.data().orderNo,
    orderType: query.data().orderType,
    b2bOrderType: query.data().b2bOrderType ?? null,
    isAlreadyRescheduled: query.data().timestamps?.rescheduledAt ? true : false,
    primaryStatus: query.data().primaryStatus,
    subStatus: query.data().subStatus,
    deliveryInfo: query.data().deliveryInfo,
    orderInfo: query.data().orderInfo,
    deliveryDistance: query.data().deliveryDistance,
    pickupAddress: query.data().pickupAddress,
    deliveryAddress: query.data().deliveryAddress,
    pickToDropTime: query.data().pickToDropTime,
    timestamps: query.data().timestamps,
    sellerInfo: query.data().sellerInfo,
    activePaymentInfo: query.data().activePaymentInfo,
    cancelInfo: {
      reason: query.data().cancelInfo?.reason,
      cancelledAt: query.data().cancelInfo?.cancelledAt?.seconds * 1000,
    },
    refundInfo: query.data().refundInfo !== null ? {
      reason: query.data().refundInfo?.reason,
      status: query.data().refundInfo?.status,
      amount: query.data().refundInfo?.amount
    } : null,
    priorityStatus: query.data().priorityStatus,
    orderValidation: query.data().orderValidation,
    notes: query.data().notes ?? [''],
    compensation: query.data().compensation,
    user: query.data().user,
    tag: query.data().tag,
    applePayRef: query.data().applePayRef,
    orderDelays: query.data().orderDelays ?? null,
    tripInfo: query.data().tripInfo ?? null,
    slotId: query.data().slotId ?? null,
  };
}