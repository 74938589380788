import { useEffect, useState } from "react";
import { getBuseyModeHistoy } from "../../actions/settings.actions";
import { useAppSelector } from "../../../../redux";
import { SETTINGS_STORE_NAME } from "../../../../contants/stores";
import { BusyMode } from "../../types/settings.busyMode";
import { formatDate } from "../../../../utils/helpers";
import { BusyModeDuration, BusyModeTimer } from "./busyModeDuration";

export default function BusyModeHistory(props: any) {
  const historyDos = useAppSelector(state => state[SETTINGS_STORE_NAME].historyDocs)

  useEffect(() => {
    getBuseyModeHistoy();
  }, []);

  return (
    <>
      <div className="text-sm p-2">
        <div className="my-2 mb-4">
          <p className="font-bold">
            Mashkor App busy mode history
          </p>
          <p className="mt-1 text-sm">
            Busy mode history records for the current month
          </p>
        </div>
        <div className="border-t border-gray-300">
          <div className="pt-1 mt-1">
            <div className="flex justify-center">
              <p className="flex-1 p-2 font-bold">
                Date
              </p>
              <p className="flex flex-1 gap-2 p-2 font-bold">
                <svg
                  width="512px"
                  height="512px"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                >
                  <path
                    fill="#000"
                    d="M256 23c-3.7 0-7.4.1-11.1.27l.8 17.98c3.4-.16 6.8-.25 10.3-.25 118.8 0 215 96.2 215 215s-96.2 215-215 215c-89.6 0-166.35-54.7-198.65-132.6l27.63-8.3-48.43-34.3-19.05 54.5 22.55-6.7C74.68 428.8 158.4 489 256 489c128.6 0 233-104.4 233-233S384.6 23 256 23zm-30.8 2.04c-13.3 1.75-26.1 4.6-38.6 8.48l5.6 17.09c11.4-3.54 23.3-6.15 35.4-7.75l-2.4-17.82zm-57 15.12c-12.4 5.05-24.2 11.12-35.4 18.12l9.5 15.21c10.3-6.44 21.2-12.03 32.6-16.67l-6.7-16.66zM116.4 69.5a234.139 234.139 0 0 0-29.35 26.12l13.05 12.28c8.3-8.77 17.4-16.81 27-24.06l-4.8-6.57-5.9-7.77zm69.5 8.58l-4.4 17.44 217 55.48 4.4-17.4-217-55.52zM74.07 110.5c-8.19 10.2-15.54 21.2-21.94 32.7l15.65 8.8c5.91-10.7 12.69-20.8 20.26-30.3l-13.97-11.2zm127.63 8.8c-3.9 26 2.8 55.2 14.2 79.2 6.4 13.4 14.2 25.2 21.9 33.8 4.2 4.7 8.4 8.3 12.2 10.9l-5.4 21.2c-4.6.4-10 1.6-16 3.7-10.9 3.8-23.4 10.4-35.4 19.1-21.6 15.6-41.4 37.9-50.4 62.6l167.5 42.9c3.9-26-2.8-55.2-14.2-79.2-6.4-13.4-14.2-25.2-21.9-33.8-4.2-4.7-8.4-8.3-12.2-10.9l5.4-21.2c4.5-.5 10-1.6 16-3.7 10.9-3.8 23.4-10.4 35.4-19.1 21.6-15.6 41.4-37.9 50.4-62.6l-167.5-42.9zM43.24 160.9c-5.33 12-9.7 24.4-13 37.3l17.48 4.2c3.03-11.8 7.04-23.2 11.95-34.2l-16.43-7.3zM26.2 217.5C24.11 230 23 242.9 23 256v.9l18-.2v-.7c0-12.1 1.02-24 2.95-35.6l-17.75-2.9zM113.5 361l-4.4 17.4 217 55.5 4.4-17.4-217-55.5z"
                  />
                </svg>
                Duration
              </p>
              <p className="flex-1 p-2 font-bold">
                Type
              </p>
              <p className="flex-1 p-2 font-bold">
                Time
              </p>
              <p className="flex-1 p-2 font-bold">
                Agent
              </p>
              <p className="flex-1 p-2 font-bold">
                Free buddies
              </p>
              <p className="flex-1 p-2 font-bold">
                Active orders
              </p>
              <p className="flex-1 p-2 font-bold">
                Unassigned orders
              </p>
            </div>
          </div>
          <div className="h-[65vh] overflow-y-auto">
            {historyDos?.map((doc: BusyMode) => (
              <div key={doc.id} className="py-2 my-2 bg-gray-100 rounded-md">
                <div className="flex">
                  <div className="relative flex flex-1 p-2">
                    <p className="absolute top-full -translate-y-1/2">
                      {formatDate(doc.activatedAt, { year: "numeric",
                        month: "numeric",
                        day: "numeric" })}
                    </p>
                  </div>
                  <div className="relative flex flex-1 gap-2 p-2">
                    <div className={`${doc?.deactivatedAt ? "bg-green-100" : "bg-red-200"} rounded-lg px-2 py-1 w-24 text-center absolute top-full -translate-y-1/2`}>
                      {doc?.deactivatedAt ? <BusyModeDuration activatedAt={doc.activatedAt} deactivatedAt={doc.deactivatedAt} /> : <BusyModeTimer activatedAt={doc.activatedAt} />}
                    </div>
                  </div>
                  <p className="flex-1 p-2 font-bold">
                    Activation
                  </p>
                  <p className="flex-1 p-2">
                    {formatDate(doc.activatedAt, {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })}
                  </p>
                  <p className="flex-1 p-2">
                    {doc.activatedBy?.name}
                  </p>
                  <p className="flex-1 p-2">
                    {doc.freeBuddies}
                  </p>
                  <p className="flex-1 p-2">
                    {doc.totalActiveOrders}
                  </p>
                  <p className="flex-1 p-2">
                    {doc.unassignedOrders}
                  </p>
                  
                </div>
                {doc?.deactivatedBy ?
                  <div className="flex">
                    <p className="flex-1 p-2">
                    </p>
                    <p className="flex-1 p-2"></p>
                    <p className="flex-1 p-2 font-bold">
                      Deactivation
                    </p>
                    <p className="flex-1 p-2">
                      {formatDate(doc.deactivatedAt, {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })}
                    </p>
                    <p className="flex-1 p-2">
                      {doc.deactivatedBy?.name}
                    </p>
                    <p className="flex-1 p-2">
                      {doc.freeBuddiesOnDeactivate}
                    </p>
                    <p className="flex-1 p-2">
                      {doc.totalActiveOrdersOnDeactivate}
                    </p>
                    <p className="flex-1 p-2">
                      {doc.unassignedOrdersOnDeactivate}
                    </p>
                    
                  </div> : <></>}
              </div>

            ))}
          </div>
        </div>
      </div>
    </>
  )
}