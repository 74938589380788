export default function OrderInfoCard(props: any) {
  return (
    <>
      <div className="flex justify-between -mx-2 bg-blue-light/[.2] text-black px-2 py-3 rounded-lg text-xs">
        <div className="mx-2 flex flex-col gap-2">
          <div className="flex items-center"><p className="w-24">Online Payment</p>:<span className="font-bold ml-2"> {props?.orderInfo?.onlinePaid ?? 0} KD</span></div>
          <div className="flex items-center"><p className="w-24">Wallet Payment</p>:<span className="font-bold ml-2"> {props?.orderInfo?.walletPaid ?? 0} KD</span></div>
          <div className="flex items-center"><p className="w-24">Promo</p>:<span className="font-bold ml-2"> {props?.orderInfo?.promoCode?.title ?? ''}</span></div>
          <div className="flex items-center"><p className="w-24">Discount</p>:<span className="font-bold ml-2"> {props?.orderInfo?.discount ?? 0} KD</span></div>
          <div className="flex items-center"><p className="w-24">Items total</p>:<span className="font-bold ml-2"> {props?.orderInfo?.itemTotal ?? 0} KD</span></div>
          <div className="flex items-center"><p className="w-24">Total amount</p>:<span className="font-bold ml-2"> {((props?.orderInfo?.totalAmount ?? 0) + (props?.orderInfo?.cardCharges ?? 0)).toFixed(2)} KD</span></div>
        </div>
        <div className="mx-2 flex flex-col gap-2">
          <div className="flex items-center"><p className="w-24">Service Fee</p>:<span className="font-bold ml-2"> {((props?.orderInfo?.serviceFee ?? 0) + (props?.orderInfo?.cardCharges ?? 0)).toFixed(2)} KD</span></div>
          <div className="flex items-center"><p className="w-24">DeliveryFee</p> :<span className="font-bold ml-2"> {props?.orderInfo?.deliveryFee ?? 0} KD</span></div>
          <div className="flex items-center"><p className="w-24">Cash to collect</p>:<span className="font-bold ml-2"> {props?.orderInfo?.cashToCollect ?? 0} KD</span></div>
          <div className="flex items-center"><p className="w-24">Excess Amount</p>:<span className="font-bold ml-2"> {props?.excessAmount ?? 0} KD</span></div>
          <div className="flex items-center"><p className="w-24">Buddy tip</p>:<span className="font-bold ml-2"> {props?.orderInfo?.buddyTip ?? 0} KD</span></div>
          <div className="flex items-center"><p className="w-24">Card charges</p>:<span className="font-bold ml-2"> {props?.orderInfo?.cardCharges ?? 0} KD</span></div>
        </div>
      </div>
    </>
  )
}
