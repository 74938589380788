import { useState } from "react"
import Input from "../../../../../components/Input/input"
import { uploadItemImage } from "../../../actions/order.actions";

export default function OrderCartItem({ orderType, primaryStatus, item, idx, length, onRemove, onChange, orderId }: { orderType: string, primaryStatus: string, item: any, idx: number, length: number, onRemove: Function, onChange: Function, orderId: string }) {
  const [qty, setQty] = useState(item.quantity);
  const [fileUrl, setFileUrl]: [any, Function] = useState(null);
  const [loading, setLoading]: [any, Function] = useState(false);

  return (<>
    <li key={item.id} className="bg-white rounded-lg flex border-gray-300 p-4 my-5 first:mt-3 relative">
      {primaryStatus === 'OTHERS' || (idx === 0 && length === 1) || (item.isCancelled) ? <></> :
        <button
          type="button"
          className="absolute bg-white border border-gray-300 rounded p-1 top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-red-600 hover:text-red-500"
          onClick={() => onRemove()}
        >
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
        </button>}
      <div className="relative h-24 w-24 flex-shrink-0 rounded-md border border-gray-300">
        {/* {primaryStatus === 'OTHERS' ? <></> :
          <button
            type="button"
            className="absolute bg-white border border-gray-300 rounded-full p-1 top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-red-600 hover:text-red-500"
            onClick={() => setFileUrl(null)}
          >
            <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
          </button>} */}

        {loading ?
          <div className="h-full w-full flex justify-center items-center" role="status">
            <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg>
          </div>

          : item.imageUrl ? <img
            src={item.imageUrl}
            alt={item.imageAlt}
            className="h-full w-full object-cover object-center"
          /> : <svg className="h-full w-full object-cover object-center" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.37879 5.0463C4.48414 3.88737 5.45584 3 6.61955 3H17.3799C18.5436 3 19.5153 3.88737 19.6207 5.04629L20.8479 18.5463C20.9677 19.8639 19.9302 21 18.6072 21H5.39227C4.0692 21 3.03173 19.8639 3.15151 18.5463L4.37879 5.0463ZM11.9996 12C9.51435 12 7.49963 9.98528 7.49963 7.5V6.75C7.49963 6.33579 7.83542 6 8.24963 6C8.66385 6 8.99963 6.33579 8.99963 6.75V7.5C8.99963 9.15685 10.3428 10.5 11.9996 10.5C13.6565 10.5 14.9996 9.15685 14.9996 7.5V6.75C14.9996 6.33579 15.3354 6 15.7496 6C16.1638 6 16.4996 6.33579 16.4996 6.75V7.5C16.4996 9.98528 14.4849 12 11.9996 12Z" fill="#3A52EE" />
          </svg>}
        {primaryStatus === 'OTHERS' ? <></> : <label className="mt-2 w-full flex flex-col items-center px-2 py-1 bg-white text-indigo-700 rounded-md shadow-lg tracking-wide uppercase border border-gray-300 cursor-pointer hover:bg-indigo-400 hover:text-white">

          <span className="text-xs">Upload</span>
          <input type='file' className="hidden" onChange={async (e: any) => {
            setLoading(true);
            const url = await uploadItemImage(orderId, e?.target?.files[0])
            onChange({
              target: {
                name: 'imageUrl',
                value: url,
                type: 'text'
              }
            })
            setLoading(false);
          }
          } />
        </label>}
        {item?.isCancelled ?
          <div className="flex justify-center items-center bg-red-100 p-1 my-2 rounded-lg">
            Cancelled
          </div>
          : <></>}
        <div className="flex justify-center items-center bg-yellow-100 p-1 my-2 rounded-lg">
          {item?.price > 0 ? "BUY" : "PICKUP"}
        </div>
      </div>
      <div className="ml-4 flex gap-2 flex-1 flex-col">
        <Input
          rules={{
            required: true,
            minLength: 3,
            maxLength: 25,
            disabled: orderType === 'SELLER' || primaryStatus === "OTHERS" ? true : false,
          }}
          labelText="Name (Required)" onChange={onChange} value={item.name} defaultValue={item.name} type="text" placeholder="name" name="name" />
        <Input
          rules={{
            maxLength: 40,
            disabled: orderType === 'SELLER' || primaryStatus === "OTHERS" ? true : false,
          }} labelText="Description" onChange={onChange} value={item.description} defaultValue={item.description} type="text" placeholder="description" name="description" />
        <div className="flex gap-2 flex-wrap justify-between items-end  w-full text-base font-medium text-gray-900">
          <Input
            rules={{
              required: true,
              min: 0,
              step: "any",
              disabled: orderType !== 'BUY' || primaryStatus === "OTHERS" ? true : false,
            }}
            //onInput={(e : React.ChangeEvent<HTMLInputElement>) => e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')}
            labelText={`Price ${orderType !== 'PICKUP' ? '(Required)': ''}`} onChange={onChange} value={item.price} defaultValue={item.price} type="number" placeholder="price" name="price"/>
          <div className="custom-number-input mx-1 px-1">
            <label htmlFor="custom-input-number" className="font-normal text-xs capitalize">Qty
            </label>
            <div className="flex flex-row h-9 rounded-lg relative bg-transparent">
              <button onClick={() => {
                setQty(qty - 1)
                onChange({
                  target: {
                    name: "quantity",
                    value: qty - 1,
                    type: "number"
                  }
                })
                }} type="button" data-action="decrement" className="focus:outline-none bg-gray-100 text-indigo-700 h-full w-6 rounded-l-md cursor-pointer outline-none">
                <span className="m-auto text-xl font-medium">−</span>
              </button>
              <Input
                rules={{
                  required: true,
                  min: 0,
                  disabled: orderType === 'SELLER' || primaryStatus === "OTHERS" ? true : false,
                }} onChange={onChange} value={qty} defaultValue={qty} type="number" placeholder="quantity" name="quantity"
                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none outline-none focus:outline-none text-center bg-gray-100 text-sm hover:text-black focus:text-black flex items-center text-gray-700 border-0 rounded-none h-full w-10 px-0" />
              <button onClick={() => {
                setQty(qty + 1);
                onChange({
                  target: {
                    name: "quantity",
                    value: qty + 1,
                    type: "number"
                  }
                })
                }} type="button" data-action="increment" className="focus:outline-none bg-gray-100 text-indigo-700 h-full w-6 rounded-r-md cursor-pointer">
                <span className="m-auto text-xl font-medium">+</span>
              </button>
            </div>
          </div>
          <div className="text-right ml-auto">
            <span className="font-normal text-sm capitalize">Total</span>
            <p className="h-9 py-[1px] text-xl text-gray-700 font-bold">{((item.quantity * item.price) ?? 0).toFixed(2)} KD</p>
          </div>
        </div>
      </div>
    </li>
  </>)
}