import { AUTH_STORE_NAME, SETTINGS_STORE_NAME } from "../../../contants/stores";
import { store } from "../../../redux";
import { actions as settingsActions } from "../stores/settings.store";
import { addNewBusyModeDoc, deactivateCurrentBusyModeHistoryDoc, fetchBusyModeHistory, fetchBusyMoodStatusAPI, fetchBusyMoodTypesAPI, fetchConfig, fetchFleetConfig, fetchTaggingConfig, fetchZones, updateConfig } from "../services/settings.services";

export const getFleetConfig = async () => {
  const fleetConfig = await fetchFleetConfig();
  store.dispatch(settingsActions.setFleetConfig(fleetConfig));
}

export const changeActiveTab = (tab: string) => {
  store.dispatch(settingsActions.setActiveTab(tab));
}

export const getBusinessConfig = async () => {
  try {
    return fetchConfig({
      listeners: {
        onUpdate: (res: any) => {
          store.dispatch(settingsActions.setBusinessConfig({ ...res }));
        },
      },
    });
  } catch (error) {
    console.log('error-getBusinessConfig: ', getBusinessConfig)
  }
}

export const getBuseyModeHistoy = async() => {
  try {
    const docs = await fetchBusyModeHistory();
    store.dispatch(settingsActions.setBusyModeHistory(docs));
  } catch (error) {
    console.log('error-getBuseyModeHistoy: ', getBusinessConfig)
  }
}

export const updateBusinessConfig = async(busyMode: boolean, startTime: string, endTime: string) => {
  try {
    await updateConfig('business_config',{ 
      "busyMode": busyMode, 
      "startTime": startTime,
      "endTime": endTime,
      "timeOutInfo.messageEn":`Please place the order in our operation time between ${convert24HrsTo12Hrs(startTime, "EN")} to ${convert24HrsTo12Hrs(endTime, "EN")}`,
      "timeOutInfo.messageAr": `يرجى تقديم الطلب في وقت عمليتنا بين الساعة ${convert24HrsTo12Hrs(startTime, "Ar")} و ${convert24HrsTo12Hrs(endTime, "Ar")}`
    });
  } catch (error) {
    console.log('error-updateBusinessConfig: ', error)
  }
}

export const updateBusyModeHistory = async(isActive: boolean) => {
  const zonesSummary = store.getState()[SETTINGS_STORE_NAME].zonesSummary;
  const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;

  if(isActive) {
    //Activate and create a new document
    await addNewBusyModeDoc(zonesSummary, {id: agent.id, name: agent.name});

  } else {
    //Deactivate -> Close the current document
    await deactivateCurrentBusyModeHistoryDoc(zonesSummary, {id: agent.id, name: agent.name})
  }
}

export const getTaggingConfig = async () => {
  
  try {
    return fetchTaggingConfig({
      listeners: {
        onUpdate: (res: any) => {
          store.dispatch(settingsActions.setAppTaggingConfig({...res?.customBuy}));
          store.dispatch(settingsActions.setCourierTaggingConfig({...res?.courier}));        },
      },
    });
  } catch (error) {
    console.log('error-getTaggingConfig: ', getBusinessConfig)
  }
}

export const updateAppTaggingConfig = async(excludedPickups: string[], maxOrderAmount: number) => {
  try {
    await updateConfig('order_tagging', { 
      "customBuy.excludedPickups": excludedPickups, 
      "customBuy.maxOrderAmount": +maxOrderAmount,
    });
  } catch (error) {
    console.log('error-updateAppTaggingConfig: ', error)
  }
}

export const updateCourierTaggingConfig = async(pickupZones: any[], distance: number) => {
  try {
    await updateConfig('order_tagging', { 
      "courier.pickupZones": pickupZones, 
      "courier.distance": +distance,
    });
  } catch (error) {
    console.log('error-updateCourierTaggingConfig: ', error)
  }
}

export const getZonesData = async () => {
  const query = await fetchZones();
  const zones: { value: string; label: any; }[] = [];
  query.forEach((doc) => {
    
    if (doc.id !== 'zones_summary') {
      const zone = {
        value: doc.id,
        label: doc.data().name,
      };
      zones.push(zone);
    }
    else store.dispatch(settingsActions.setZonesSummary(doc.data()));
  });
  store.dispatch(settingsActions.setZones(zones));
}

export const getCourierBusyModeConfig = async() => {
  const fleetConfig = await fetchFleetConfig();
  store.dispatch(settingsActions.setFleetConfig(fleetConfig));
  const status = await fetchBusyMoodStatusAPI(fleetConfig);
  const types = await fetchBusyMoodTypesAPI(fleetConfig);
  store.dispatch(settingsActions.setCourierBusyModeStatus(status.data));
  store.dispatch(settingsActions.setCourierBusyModeTypes(types.data));
}

export const activateCourierBusyMode = (duration: number, reason: string, typeId: string, activatedBy: string) => {

}

const convert24HrsTo12Hrs = (timeString: any, lang: string) =>{
  var H = +timeString.substr(0, 2);
  var h = (H % 12) || 12;
  var ampmEN = H < 12 ? " AM" : " PM";
  var ampmAR = H < 12 ? " صباحًا " : "مساءً";
  const timeStringEn = h + timeString.substr(2, 3) + ampmEN;
  const timeStringAr = h + timeString.substr(2, 3) + ampmAR;
  return lang === "EN" ? timeStringEn : timeStringAr;
}

