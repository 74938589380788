export default function RefineAddress(props: any) {
  return (
    <>
      <div className="flex gap-2 items-center justify-between w-full rounded-b-md bg-indigo-400/10 text-xs p-2">
        <div className="flex flex-col gap-1">
          <div className="flex gap-1 tems-center">
            <svg
              fill="currentColor"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 24 24"
              xmlSpace="preserve"
              className="w-4 h-4 text-indigo-700"
            >
              <style type="text/css">{"\n\t.st0{fill:none;}\n"}</style>
              <path d="M12,2C8.7,2,6,4.7,6,8c0,5.2,6,11.1,6,11.1s6-6,6-11.1C18,4.7,15.3,2,12,2z M12,5.9c1.1,0,2.1,1,2.1,2.1 c0,1.2-0.9,2.1-2.1,2.1S9.9,9.1,9.9,8C9.9,6.8,10.9,5.9,12,5.9z M6.8,15.1c-1.3,0.3-2.3,0.6-3.2,1c-0.4,0.2-0.8,0.5-1.1,0.8 S2,17.8,2,18.3c0,0.8,0.5,1.4,1.1,1.8s1.3,0.7,2.2,1C7.1,21.7,9.4,22,12,22s4.9-0.3,6.7-0.8c0.9-0.3,1.6-0.6,2.2-1s1.1-1,1.1-1.8 c0-1-0.8-1.7-1.6-2.2c-0.8-0.5-1.9-0.8-3.2-1l-0.3,2c1.1,0.2,2,0.5,2.6,0.8c0.4,0.2,0.5,0.4,0.6,0.4c0,0-0.1,0.1-0.2,0.2 c-0.3,0.2-0.9,0.5-1.6,0.7C16.6,19.7,14.4,20,12,20s-4.6-0.3-6.1-0.8c-0.7-0.2-1.3-0.5-1.6-0.7c-0.1-0.1-0.2-0.2-0.2-0.2 c0.1-0.1,0.2-0.2,0.5-0.4c0.6-0.3,1.5-0.6,2.7-0.8L6.8,15.1z" />
              <rect className="st0" width={24} height={24} />
            </svg>
            <p className="text-gray-800 font-bold">
              {props?.name}
            </p>
          </div>
          <p className="text-gray-800">
            {props?.addressStr ?? (`Area: ${props.area}, Block: ${props.block}, Street: ${props.street}`) ?? ''}
          </p>
        </div>
        {/* <button className="bg-indigo-700 p-1 rounded-sm text-xs text-white">
          Refine
        </button> */}
      </div>
    </>
  )
}