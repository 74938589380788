import { Tab } from '@headlessui/react'
import Input from '../../../../../components/Input/input'
const addressTypes = [
  {
    name: "Home",
    type: "HOME",
    mandatoryFields: ["name", "building"],
    icon: <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="770.4px"
      height="770.4px"
      viewBox="0 0 770.4 770.4"
      className="text-gray-500 w-4 h-4"
      xmlSpace="preserve"
    >
      <g>
        <path fill="currentColor" d="M770.4,381.95l-42.8,52l-342.4-281.6L42.8,433.95l-42.8-52l385.2-316.8L770.4,381.95z M385.3,217.25l248.4,206v192.601v59 v30.399H136.6v-31.199v-58.2v-193.4L385.3,217.25z M405.601,434.65c-1.727,1.266-20.4,18-20.4,18s-18.699-16.801-20.4-18 c-25.282-17.824-52.5-19-73.3-8.1c-20.5,9.898-32.8,31.698-34.3,56.8c-0.1,0.898-0.1,1.8-0.1,2.7c-0.3,19.1,7.2,36.8,18,52.5 c4.8,7,9.9,13.6,15.2,20c7.8,9.898,94.85,91.767,94.85,91.767s87.149-81.967,94.95-91.767c5.3-6.4,10.398-13.102,15.198-20 c10.802-15.802,18.302-33.4,18-52.5c0-0.9,0-1.802-0.1-2.7c-1.5-25.102-13.8-47-34.3-56.8 C458.101,415.65,430.544,416.354,405.601,434.65z" />
      </g>
    </svg>,
    component: (props: any) => <HomeComponent {...props} />

  },
  {
    name: "Apartment",
    type: "APARTMENT",
    mandatoryFields: ["name", "building"],
    icon: <svg
      fill="#000000"
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="text-gray-500 w-4 h-4"
    >
      <path fill="currentColor" d="M22,1H10A1,1,0,0,0,9,2V7H2A1,1,0,0,0,1,8V22a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V2A1,1,0,0,0,22,1ZM3,9H9V21H3ZM21,21H11V3H21ZM19,7H13V5h6Zm0,4H13V9h6Zm0,4H13V13h6Zm0,4H13V17h6ZM5,10H7v2H5Zm0,4H7v2H5Zm0,4H7v2H5Z" />
    </svg>,
    component: (props: any) => <ApartmentComponent {...props} />
  },

  {
    name: "Office",
    type: "OFFICE",
    mandatoryFields: ["name", "building"],
    icon: <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="383.235px"
      height="383.235px"
      viewBox="0 0 383.235 383.235"
      className="text-gray-500 w-4 h-4"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path fill="currentColor" d="M322.618,73.735h-38V55.096C284.618,24.716,259.902,0,229.521,0h-75.808c-30.38,0-55.096,24.716-55.096,55.096v18.639 h-38c-27.614,0-50,22.386-50,50v62.883h362v-62.883C372.618,96.12,350.232,73.735,322.618,73.735z M244.618,73.735h-106V55.096 c0-8.324,6.771-15.096,15.096-15.096h75.809c8.324,0,15.096,6.772,15.096,15.096L244.618,73.735L244.618,73.735z" />
            <path fill="currentColor" d="M323.284,244.333c0,6.425-5.207,11.632-11.631,11.632h-41.404c-6.424,0-11.631-5.207-11.631-11.632v-20.702h-134v20.702 c0,6.425-5.207,11.632-11.631,11.632H71.581c-6.422,0-11.629-5.207-11.629-11.632v-20.702H10.618v109.604 c0,27.613,22.386,50,50,50h262c27.614,0,50-22.387,50-50V223.631h-49.334V244.333L323.284,244.333z" />
          </g>
        </g>
      </g>
    </svg>,
    component: (props: any) => <OfficeComponent {...props} />
  },
  {
    name: "Store",
    type: "STORE",
    mandatoryFields: ["name"],
    icon: <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      id="store"
      className="text-gray-500 w-4 h-4"
    >
      <path
        d="M20,14.81V20a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V14.81A4.25,4.25,0,0,0,5.25,15a4.3,4.3,0,0,0,2.25-.64,4.28,4.28,0,0,0,4.5,0,4.28,4.28,0,0,0,4.5,0,4.3,4.3,0,0,0,2.25.64A4.25,4.25,0,0,0,20,14.81ZM21.76,9,20.17,3.45A2,2,0,0,0,18.25,2H5.75A2,2,0,0,0,3.83,3.45L2.24,9A6.48,6.48,0,0,0,2,10.75a3.25,3.25,0,0,0,5.5,2.34,3.24,3.24,0,0,0,4.5,0,3.24,3.24,0,0,0,4.5,0A3.25,3.25,0,0,0,22,10.75,6.48,6.48,0,0,0,21.76,9Z"
        style={{
          fill: "currentColor",
        }}
      />
    </svg>,
    component: (props: any) => <StoreComponent {...props} />
  },
]

function HomeComponent(props: any) {
  return (
    <>
      <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
        <div className="flex-1">
          <Input rules={{
            required: props.orderType !== 'COURIER',
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText="Name (Required)" onChange={(e: any) => props.handleChange(e, "name")} value={props?.address?.name ?? ''} defaultValue={props?.address?.name ?? ''} type="text" placeholder="name" />
        </div>
        <div className="flex-1">
          <Input rules={{
            required: props.orderType !== 'COURIER',
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText={`Building ${props.orderType === 'COURIER' ? '' :' (Required)'}`} onChange={(e: any) => props.handleChange(e, "building")} value={props?.address?.building ?? ''} defaultValue={props?.address?.building ?? ''} type="text" placeholder="building" />
        </div>
      </div>
      <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">

        <div className="flex-1">
          <Input rules={{
            required: false,
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText="Avenue" onChange={(e: any) => props.handleChange(e, "avenue")} value={props?.address?.avenue ?? ''} defaultValue={props?.address?.avenue ?? ''} type="text" placeholder="Avenue" />
        </div>
        <div className="flex-1">
          <Input rules={{
            required: false,
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText="Additional info" onChange={(e: any) => props.handleChange(e, "roomNumber")} value={props?.address?.roomNumber ?? ''} defaultValue={props?.address?.roomNumber ?? ''} type="text" placeholder="Additional info" />
        </div>
      </div>
    </>
  )
}

function ApartmentComponent(props: any) {
  return (
    <>
      <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
        <div className="flex-1">
          <Input rules={{
            required: props.orderType !== 'COURIER',
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText="Name (Required)" onChange={(e: any) => props.handleChange(e, "name")} value={props?.address?.name ?? ''} defaultValue={props?.address?.name ?? ''} type="text" placeholder="name" />
        </div>
        <div className="flex-1">
          <Input rules={{
            required: props.orderType !== 'COURIER',
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText={`Building name/ no ${props.orderType === 'COURIER' ? '' :' (Required)'}`} onChange={(e: any) => props.handleChange(e, "building")} value={props?.address?.building ?? ''} defaultValue={props?.address?.building ?? ''} type="text" placeholder="building" />
        </div>
      </div>
      <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">

        <div className="flex-1">
          <Input rules={{
            required: false,
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText="Floor" onChange={(e: any) => props.handleChange(e, "floorNumber")} value={props?.address?.floorNumber ?? ''} defaultValue={props?.address?.floorNumber ?? ''} type="text" placeholder="floorNumber" />
        </div>
        <div className="flex-1">
          <Input rules={{
            required: false,
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText="Door / Apt no" onChange={(e: any) => props.handleChange(e, "roomNumber")} value={props?.address?.roomNumber ?? ''} defaultValue={props?.address?.roomNumber ?? ''} type="text" placeholder="roomNumber" />
        </div>
      </div>
    </>
  )
}

function OfficeComponent(props: any) {
  return (
    <>
      <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
        <div className="flex-1">
          <Input rules={{
            required: props.orderType !== 'COURIER',
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText="Name (Required)" onChange={(e: any) => props.handleChange(e, "name")} value={props?.address?.name ?? ''} defaultValue={props?.address?.name ?? ''} type="text" placeholder="name" />
        </div>
        <div className="flex-1">
          <Input rules={{
            required: props.orderType !== 'COURIER',
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText={`Building ${props.orderType === 'COURIER' ? '' :' (Required)'}`} onChange={(e: any) => props.handleChange(e, "building")} value={props?.address?.building ?? ''} defaultValue={props?.address?.building ?? ''} type="text" placeholder="building" />
        </div>
      </div>
      <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">

        <div className="flex-1">
          <Input rules={{
            required: false,
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText="Floor" onChange={(e: any) => props.handleChange(e, "floorNumber")} value={props?.address?.floorNumber ?? ''} defaultValue={props?.address?.floorNumber ?? ''} type="text" placeholder="floorNumber" />
        </div>
        <div className="flex-1">
          <Input rules={{
            required: false,
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText="Office name / no" onChange={(e: any) => props.handleChange(e, "roomNumber")} value={props?.address?.roomNumber ?? ''} defaultValue={props?.address?.roomNumber ?? ''} type="text" placeholder="roomNumber" />
        </div>
      </div>
    </>
  )
}

function StoreComponent(props: any) {
  return (
    <>
      <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
        <div className="flex-1">
          <Input rules={{
            required: props.orderType !== 'COURIER',
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText="Name (Required)" onChange={(e: any) => props.handleChange(e, "name")} value={props?.address?.name ?? ''} defaultValue={props?.address?.name ?? ''} type="text" placeholder="name" />
        </div>
        <div className="flex-1">
          <Input rules={{
            required: false,
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText="Building name " onChange={(e: any) => props.handleChange(e, "building")} value={props?.address?.building ?? ''} defaultValue={props?.address?.building ?? ''} type="text" placeholder="building" />
        </div>
      </div>
      <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">

        <div className="flex-1">
          <Input rules={{
            required: false,
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText="Floor" onChange={(e: any) => props.handleChange(e, "floorNumber")} value={props?.address?.floorNumber ?? ''} defaultValue={props?.address?.floorNumber ?? ''} type="text" placeholder="floorNumber" />
        </div>
        <div className="flex-1">
          <Input rules={{
            required: false,
            maxLength: 25,
            disabled: props.primaryStatus === "OTHERS" ? true : false,
          }} labelText="Additional info" onChange={(e: any) => props.handleChange(e, "roomNumber")} value={props?.address?.roomNumber ?? ''} defaultValue={props?.address?.roomNumber ?? ''} type="text" placeholder="roomNumber" />
        </div>
      </div>
    </>
  )
}

export default function AddressTypes(props: any) {
  return (
    <>
      {props?.updatedAddress?.type ? <div className="w-full max-w-md px-2 py-1 sm:px-0">
        <Tab.Group defaultIndex={addressTypes.findIndex((a: any) => a.type === (props?.updatedAddress?.type ?? props?.addressType))}
          selectedIndex={addressTypes.findIndex((a: any) => a.type === (props?.updatedAddress?.type ?? props?.addressType))}
          onChange={(index) => {
            console.log('Changed selected tab to:', index);
            props.setAddressType(addressTypes[index].type);
            props.setUpdatedAddress({...props.updatedAddress, type: addressTypes[index].type })
          }}>
          <Tab.List className="flex space-x-1 rounded-xl">
            {addressTypes.map((address: any) => (
              <Tab
                key={address.name}
                className={({ selected }) => `flex justify-center items-center gap-1 text-xs ${selected ? 'border-indigo-400 bg-indigo-400/20 font-semibold' : 'bg-white border-gray-300'} border rounded-md  py-2 px-2 m-1 w-full ring-white ring-opacity-60 ring-offset-2 focus:outline-none focus:ring-2`}
              >
                {address.icon}
                {address.name}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="">
            {addressTypes.map((address: any) => (
              <Tab.Panel
                key={address.name}
                className="rounded-xl bg-white text-xs"
              >
                {address.component({ address: props.updatedAddress, handleChange: props.onChange, orderType: props.orderType, primaryStatus: props.primaryStatus })}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div> : <></>}
    </>
  )
}
