import { useState } from "react";
import { search } from "../../actions/order.actions";
import OrdersFilter from "./ordersFilter";
import OrdersGrouping from "./ordersGrouping";
import PinnedOrders from "./ordersPinned";
import { AUTH_STORE_NAME, ORDER_STORE_NAME } from '../../../../contants/stores'
import { useAppSelector } from '../../../../redux'

export default function OrdersSearch() {
	const agent = useAppSelector(state => state[AUTH_STORE_NAME].loggedInAgent);
	const searchOrder = useAppSelector(state => state[ORDER_STORE_NAME].searchOrder);

	const [searchValue, setSearchValue] = useState("");

	const handleKeyPress = async (event: any) => {
		const key = event.key;
		const value = (document.getElementById("chat") as HTMLInputElement).value.trim();
		setSearchValue(value);
		if (key === "Enter" && value) {
			setTimeout(() => {
				search(value?.startsWith("C-") || value?.startsWith("B-") ? "ORDER" : "CUSTOMER", value);
			}, 0)
		}
	};

	return (
		<div className="rounded-t-lg bg-gray-100">
			<div className="flex items-center px-3 py-2 gap-2">
				<div className="relative h-10 flex-1 rounded-lg border border-gray-300 overflow-hidden focus-within:border-transparent focus-within:ring-indigo-400 focus-within:ring-2">
					{/* <div className="absolute flex items-center gap-2 px-2 top-0 right-0 transform border-l border-gray-300 bg-white h-full">
						<button onClick={() => { handleSearchOptChange("ORDER") }}
							className="inline-flex justify-center text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-10">
							<svg className={`w-5 h-5 ${searchOpt === "ORDER" ? "text-indigo-400" : "text-gray-500"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clipRule="evenodd"></path></svg>            <span className="sr-only">Upload image</span>
						</button>
						<button onClick={() => { handleSearchOptChange("CUSTOMER") }}
							className="text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-10">
							<svg className={`w-5 h-5 ${searchOpt === "CUSTOMER" ? "text-indigo-400" : "text-gray-500"}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
						</button>
					</div> */}
					<input
						onKeyPress={handleKeyPress}
						id="chat" className="block p-2.5 w-full text-sm text-gray-900 bg-white focus:text-indigo-400 focus:outline-none" placeholder="Search ..." defaultValue={""} />
						
				</div>
				<div className="flex items-center gap-1 h-10">
					<OrdersFilter />
				</div>
				{agent?.pinnedOrders?.length ? <PinnedOrders agentId={agent?.id} pinnedOrders={agent?.pinnedOrders}/> : <></>}
			</div>
			{! searchOrder && searchValue ? <p className="text-xs text-red-500 px-3 pb-1">No result found</p>: <></>}

			<div>
				<OrdersGrouping />
			</div>
		</div>
	);
}