export type OrderLog = {
  id: string;
  status: string,
  cashToCollect: number,
  totalAmount: number,
  itemTotal: number,
  updatedAt: any,
  updatedBy: string,
  assignedBuddyFleetId: number,
  assignedBuddyName: string,
  assignedAgent: string
}
export function assignValueToObject(query: any) {
  return {
    id: query.id,
    status: query.data()?.status,
    cashToCollect: query.data()?.cashToCollect,
    totalAmount: query.data()?.totalAmount,
    itemTotal: query.data()?.itemTotal,
    updatedAt: query.data()?.updatedAt?.seconds * 1000,
    updatedBy: query.data()?.updatedBy,
    assignedBuddyFleetId: query.data()?.assignedBuddyFleetId,
    assignedBuddyName: query.data()?.assignedBuddyName,
    assignedAgent: query.data()?.assignedAgent
  };
}