import firebase from "firebase";
import { useEffect, useRef, useState } from "react";
import { Autocomplete } from "../../../../../components/Autocomplete/Autocomplete";
import Select from "../../../../../components/Select/select";
import { ORDER_STORE_NAME } from "../../../../../contants/stores";
import { useAppSelector } from "../../../../../redux";
import { fetchBlocks, fetchBlocksAndStreets, indexAreas } from "../../../actions/config.actions";
import ConfirmPinPoint from "./pinPointConfirmationPopup";
import { calculateLinearDistance } from "../../../../../utils/distance";

export default function EditAddressPaci(props:any) {
  const streetAutocomplete = useRef<any>(null);

  const [areas, setAreas] = useState(useAppSelector((state) => state[ORDER_STORE_NAME].areas));
  const [blocks, setBlocks] : [any, Function] = useState([]);
  const [blockOpts, setBlockOpts] = useState([]);
  const [streets, setStreets] : [any, Function] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [confirmPin, setConfirmPin] = useState(false);
  const [streetLatLng, setStreetLatLng] : [any, Function] = useState(null);

  useEffect(() => {
    async function fetchMyAPI() {
      if (!areas?.length) {
        const data = await indexAreas();
        setAreas(data);
      }
    }
    fetchMyAPI();
  }, []);

  useEffect(()=> {

    (async function () {
      
      props?.setSelectedArea({
        id: +props.paciDetails?.areaId,
        name: props.paciDetails?.areaName
      });
      setConfirmPin(false);
      props?.setSelectedBlock();
      props?.setSelectedStreet()
      let blks = [];
      let streetOpts = [];
      let areaDoc: any = {}
      try {
        areaDoc = await fetchBlocksAndStreets(+props.paciDetails?.areaId, props.paciDetails?.blockName);
        if (areaDoc.blockDetails) {

          blks = areaDoc?.blocks;
          setBlocks(blks);
          const blocksOpts = areaDoc?.blocks?.map((block: any) => {
            return {
              id: block.id,
              name: "Block-" + block.name_en,
            }
          });
          blocksOpts?.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          setBlockOpts(blocksOpts);
          props?.setSelectedBlock({
            id: +areaDoc?.blockDetails.id,
            name: props.paciDetails?.blockName
          });
          const updatedAddress = {
            ...props?.updatedAddress,
            area_id: +props.paciDetails?.areaId,
            block_id: +areaDoc.blockDetails?.id,
            street_id: null,
            area: props.paciDetails?.areaName,
            block: props.paciDetails?.blockName,
            street: null,
          };
          
          props?.setUpdatedAddress(updatedAddress);
          streetAutocomplete?.current?.clearSelected();

          streetOpts = areaDoc.blockDetails?.streets?.map((st: any) => {
            return {
              id: st.id,
              name: st.name_en,
              latitude: st.latitude,
              longitude: st.longitude
            }
          });
          streetOpts?.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          setStreets(streetOpts);
        }
      } catch (e) {
        console.error(e);
      }
    })();

  },[props.paciDetails])

  const handleChange = async (name: string, e: any) => {
    let blks = [];
    let streetOpts = [];
    if(name === "area") {
      setConfirmPin(false);
      props?.setSelectedBlock();
      props?.setSelectedStreet()
      try {
        const areaDoc = await fetchBlocks(e.id);
        
        if(areaDoc) {
          props?.setSelectedArea({
            id: +areaDoc?.id,
            name: e.name
          });
          blks = areaDoc?.blocks;
          setBlocks(blks);
          const blocksOpts = areaDoc?.blocks?.map((block: any) => {return {
            id: block.id,
            name: "Block-" + block.name_en,
          }});
          blocksOpts.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          setBlockOpts(blocksOpts);
        }  
      } catch (error) {
        console.log("error", error)
      }
    }
    if(name === "block") {
      setConfirmPin(false);
      props?.setSelectedBlock({
        id: +e.id,
        name: e.name
      })
      
      streetOpts = blocks?.find((block: any) => block.id === +e.id)?.streets;
      streetOpts = streetOpts?.map((st: any) => {return {
        id: st.id,
        name: st.name_en,
        latitude: st.latitude,
        longitude: st.longitude
      }});
      streetOpts.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      setStreets(streetOpts);
    }
    if(name === "street") {
      setOpenModal(true);
      const st = streets?.find((street: any) => street.id === +e.id);
      const location = new firebase.firestore.GeoPoint(st?.latitude, st?.longitude);
      const str = `${props?.selectedArea?.name}, ${props?.selectedBlock?.name}, ${st?.name}.`
      setStreetLatLng(location);
      const updatedAddress = {
        ...props?.updatedAddress,
        addressStr: str,
        area_id: +props?.selectedArea?.id,
        block_id: +props?.selectedBlock?.id,
        street_id: +st.id,
        area: props?.selectedArea?.name,
        block: props?.selectedBlock?.name,
        street: st?.name,
      };
      
      props?.setUpdatedAddress(updatedAddress);
    }
  }


  useEffect(() => {
    async function fetchMyAPI() {

      const d = await calculateLinearDistance(streetLatLng?._lat , streetLatLng?._long, props?.updatedAddress?.location?._lat, props?.updatedAddress?.location?._long);
      props.setDistance(d);
    }

    if(streetLatLng) {
      if(confirmPin) {
        const location = new firebase.firestore.GeoPoint(streetLatLng.latitude, streetLatLng.longitude);
        const updatedAddress = {
          ...props?.updatedAddress,
          location: location
        };
        props?.setUpdatedAddress(updatedAddress);
        props?.setLocation(location);
        props.setDistance(0);
      } else {
        console.log("Not confirmed")
        fetchMyAPI();
      }
    } 

  }, [confirmPin, openModal])

  return (
    <>
      <div className="flex gap-2 justify-between">
        <div className="flex-1">
          <Autocomplete onChange={(e: any) => {handleChange("area", e)}} title="Area" key="area"
            defaultValue={props?.selectedArea?.name ?? props?.updatedAddress?.area}
            placeholder={props?.selectedArea?.name ?? props?.updatedAddress?.area ?? 'Choose area'} 
            options={areas} disabled={props.primaryStatus === "OTHERS" ? true : false} required={false}>
          </Autocomplete>
        </div>
        <div className="flex-1">
          <Autocomplete onChange={(e: any) => {handleChange("block", e)}} title="Block" key="block"
            defaultValue={props?.selectedBlock?.name ?? props?.updatedAddress?.block}
            placeholder={props?.selectedBlock?.name ?? props?.updatedAddress?.block ?? 'Choose block'} 
            options={blockOpts} disabled={props.primaryStatus === "OTHERS" ? true : false} required={false}>
          </Autocomplete>
        </div>
        <div className="flex-1">
          <Autocomplete ref={streetAutocomplete} onChange={(e: any) => {handleChange("street", e)}} title="Street" key="street"
            defaultValue={props?.selectedStreet?.name ?? props?.updatedAddress?.street ?? ''} 
            placeholder={props?.selectedStreet?.name ?? props?.updatedAddress?.street ?? 'Choose street'} 
            options={streets} disabled={props.primaryStatus === "OTHERS" ? true : false} required={false}>
          </Autocomplete>
        </div>
        {openModal ? <ConfirmPinPoint setOpenModal={setOpenModal} setConfirmPin={setConfirmPin}/> : <></>}
      </div>
    </>
  )
}