
import { MutableRefObject, useEffect, useRef, useState } from "react";
import Dialog from "../../../components/Dialog/SideDrawer";
import Input from "../../../components/Input/input";
import Button from "../../../components/Button/Button";
import { EarningConfig } from "../types/earning.data";
import { updateEarningConfigs } from "../actions/earnings.actions";
// import { createPromoCode, updatePromoCode } from "../actions/propmoCode.actions";

const EarningsDialog = (props: any) => {
  const formSubmitButtonRef = useRef() as MutableRefObject<{ click: Function }>;
  const [config, setConfig] : [EarningConfig, Function] = useState({...props.config});
  const form = useRef() as MutableRefObject<HTMLFormElement>;


  useEffect(() => {
    setConfig({...props.config})
    props.setOpen(props.open);
  }, [props.open]);

  function onClose(value: boolean) {
    props.setOpen(value);
    if (!value) {
      props.onClose();
    }
  }

  const saveChanges = async () => {
    await updateEarningConfigs(config);
    props.onClose();
  }

  return (
    <Dialog onClose={onClose} open={props.open} title={`${props.action} earning config`} config={config}>
      <>
        <form
          ref={form}
          onSubmit={(e: any) => {
            e.preventDefault();
            formSubmitButtonRef?.current?.click();
          }} className="flow-root">
          <input name="id" type="hidden" value={config.title} />
          <div className="flex gap-2 flex-1 flex-col bg-white rounded-lg h-1/2 w-full">
            <fieldset className="mb-2 border border-solid border-gray-300 px-3 pb-3 rounded-lg">
              <legend className="text-sm px-1">Config information</legend>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                  <Input onChange={(e: any) => { 
                    setConfig({ ...config, title: e.target.value.toUpperCase() }) 
                    if(e.target.value.length > 2) {
                      if(props.titles?.findIndex((title: string) => title.toUpperCase() === e.target.value.toUpperCase().trim()) > -1) {
                        e.target.setCustomValidity("Title must be unique.");
                      } else {
                        e.target.setCustomValidity("");
                      }                      
                    }
                  }}
                    rules={{
                      required: true,
                      maxLength: 100,
                      minLength: 3
                    }}
                    className="h-9"
                    labelText="Title" name="title" value={config?.title} defaultValue={config?.title} type="text" placeholder="title" />
                </div>
              </div>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                  <Input onChange={(e: any) => { 
                    setConfig({ ...config, baseDistance: +e.target.value }) 
                  }}
                    rules={{
                      required: true,
                      min: 0, step: "any",
                    }}
                    className="h-9"
                    labelText="Base distance" name="baseDistance" value={config?.baseDistance} defaultValue={config?.baseDistance} type="number" placeholder="1000" />
                </div>
                <div className="flex-1">
                  <Input onChange={(e: any) => { setConfig({ ...config, bulkPrice: +e.target.value }) }}
                    rules={{
                      required: true,
                      min: 0, step: "any",
                    }}
                    className="h-9"
                    labelText="Bulk price" name="bulkPrice" value={config?.bulkPrice} defaultValue={config?.bulkPrice} type="number" placeholder="0.8" />
                </div>
              </div>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                  <Input onChange={(e: any) => { setConfig({ ...config, earningWindowHours: +e.target.value }) }}
                    rules={{
                      required: true,
                      min: 0, step: "any",
                    }}
                    className="h-9"
                    labelText="Duration" name="earningWindowHours" value={config?.earningWindowHours} defaultValue={config?.earningWindowHours} type="number" placeholder="15" />
                </div>
                <div className="flex-1">
                  <Input onChange={(e: any) => {
                    setConfig({ ...config, saviorBasePrice: +e.target.value })
                  }}
                    rules={{
                      required: true,
                      min: 0, step: "any",
                    }}
                    className="h-9"
                    labelText="Savior base price" name="saviorBasePrice" value={config?.saviorBasePrice} defaultValue={config?.saviorBasePrice} type="number" placeholder="0.2" />
                </div>
              </div>
            </fieldset>
            <fieldset className="mb-2 border border-solid border-gray-300 px-3 pb-3 rounded-lg">
              <legend className="text-sm px-1">Bonus information</legend>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                  <Input onChange={(e: any) => { setConfig({ ...config, bonusBaseDistance: +e.target.value }) }}
                    rules={{
                      min: 1000, step: "any",
                    }}
                    className="h-9"
                    labelText="Base dist." name="bonusBaseDistance" value={config?.bonusBaseDistance} defaultValue={config?.bonusBaseDistance} type="number" placeholder="1000" />
                </div>
                <div className="flex-1">
                  <Input onChange={(e: any) => {
                    setConfig({ ...config,  bonusBasePrice: +e.target.value })
                  }}
                    rules={{
                      min: 0, step: "any",
                    }}
                    className="h-9"
                    labelText="base price" name="bonusBasePrice" value={+config?.bonusBasePrice} defaultValue={config?.bonusBasePrice} type="number" placeholder="0" />
                </div>
              </div>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                  <Input className="h-9" onChange={(e: any) => {
                    setConfig({ ...config, bonusExtraDistanceUnit: +e.target.value })
                  }}
                    rules={{
                      min: 1000, step: "any",
                    }} labelText="Extra dist unit" name="bonusExtraDistanceUnit" value={+config?.bonusExtraDistanceUnit} defaultValue={config?.bonusExtraDistanceUnit} type="number" placeholder="1000" />
                </div>
                <div className="flex-1">
                  <Input className="h-9" onChange={(e: any) => {
                    setConfig({ ...config, bonusExtraDistanceUnitPrice: +e.target.value })
                  }}
                    rules={{
                      min: 0, step: "any",
                    }} labelText="Extra dist price" name="bonusExtraDistanceUnitPrice" value={+config?.bonusExtraDistanceUnitPrice} defaultValue={config?.bonusExtraDistanceUnitPrice} type="number" placeholder="0.1" />
                </div>
              </div>
            </fieldset>
            <fieldset className="mb-2 border border-solid border-gray-300 px-3 pb-3 rounded-lg">
              <legend className="text-sm px-1">Champion information</legend>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                <Input className="h-9" onChange={(e: any) => {
                    setConfig({ ...config, championBasePrice: +e.target.value })
                  }}
                    rules={{
                      required: true,
                      min: 0, step: "any",
                    }} labelText="Base price" name="championBasePrice" value={+config?.championBasePrice} defaultValue={config?.championBasePrice} type="number" placeholder="0.1" />
                </div>
                <div className="flex-1">
                  <Input className="h-9" onChange={(e: any) => {
                    setConfig({ ...config, championOrderCount: +e.target.value });
                  }} rules={{
                    required: true,
                    min: 0, step: "any",
                  }} name="championOrderCount" labelText="Orders" value={config?.championOrderCount} defaultValue={config?.championOrderCount} type="number" placeholder="12" />
                </div>
                <div className="flex-1">
                  <Input className="h-9" onChange={(e: any) => {
                    setConfig({ ...config, championTotalDistanceStart: +e.target.value });
                  }} rules={{
                    required: true,
                    min: 0, step: "any",
                  }} name="championTotalDistanceStart" labelText="distance" value={config?.championTotalDistanceStart} defaultValue={config?.championTotalDistanceStart} type="number" placeholder="12000" />
                </div>
              </div>
            </fieldset>
            <fieldset className="mb-2 border border-solid border-gray-300 px-3 pb-3 rounded-lg">
              <legend className="text-sm px-1">Hero information</legend>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                <Input className="h-9" onChange={(e: any) => {
                    setConfig({ ...config, heroBasePrice: +e.target.value })
                  }}
                    rules={{
                      required: true,
                      min: 0, step: "any",
                    }} labelText="Base price" name="heroBasePrice" value={+config?.heroBasePrice} defaultValue={config?.heroBasePrice} type="number" placeholder="0.1" />
                </div>
                <div className="flex-1">
                  <Input className="h-9" onChange={(e: any) => {
                    setConfig({ ...config, heroOrderCount: +e.target.value });
                  }} rules={{
                    required: true,
                    min: 0, step: "any",
                  }} name="heroOrderCount" labelText="Orders" value={config?.heroOrderCount} defaultValue={config?.heroOrderCount} type="number" placeholder="15" />
                </div>
                <div className="flex-1">
                  <Input className="h-9" onChange={(e: any) => {
                    setConfig({ ...config, heroTotalDistanceStart: +e.target.value });
                  }} rules={{
                    required: true,
                    min: 0, step: "any",
                  }} name="heroTotalDistanceStart" labelText="distance" value={config?.heroTotalDistanceStart} defaultValue={config?.heroTotalDistanceStart} type="number" placeholder="15000" />
                </div>
              </div>
            </fieldset>
            <fieldset className="mb-2 border border-solid border-gray-300 px-3 pb-3 rounded-lg">
              <legend className="text-sm px-1">Extra distance information</legend>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                <Input className="h-9" onChange={(e: any) => {
                    setConfig({ ...config, extraDistanceMaxPrice: +e.target.value });
                    console.log(config)
                  }}
                    rules={{
                      min: 0, step: "any",
                    }} labelText="Max price" name="extraDistanceMaxPrice" value={+config?.extraDistanceMaxPrice} defaultValue={config?.extraDistanceMaxPrice} type="number" placeholder="0.1" />
                </div>
                <div className="flex-1">
                  <Input className="h-9" onChange={(e: any) => {
                    setConfig({ ...config, extraDistanceUnit: +e.target.value });
                  }} rules={{
                    min: 0, step: "any",
                  }} name="extraDistanceUnit" labelText="Unit" value={config?.extraDistanceUnit} defaultValue={config?.extraDistanceUnit} type="number" placeholder="1000" />
                </div>
                <div className="flex-1">
                  <Input className="h-9" onChange={(e: any) => {
                    setConfig({ ...config, extraDistanceUnitPrice: +e.target.value });
                  }} rules={{
                    min: 0, step: "any",
                  }} name="extraDistanceUnitPrice" labelText="Unit price" value={config?.extraDistanceUnitPrice} defaultValue={config?.extraDistanceUnitPrice} type="number" placeholder="0.1" />
                </div>
              </div>
            </fieldset>
            <fieldset className="mb-2 border border-solid border-gray-300 px-3 pb-3 rounded-lg">
              <legend className="text-sm px-1">Strategic vendor information</legend>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                <Input className="h-9" onChange={(e: any) => {
                    setConfig({ ...config, strategicVendorMaxEarning: +e.target.value })
                  }}
                    rules={{
                      min: 0, step: "any",
                    }} labelText="Max earning" name="strategicVendorMaxEarning" value={+config?.strategicVendorMaxEarning} defaultValue={config?.strategicVendorMaxEarning} type="number" placeholder="0" />
                </div>
                <div className="flex-1">
                  <Input className="h-9" onChange={(e: any) => {
                    setConfig({ ...config, strategicVendorFixedCost: +e.target.value });
                  }} rules={{
                    min: 0, step: "any",
                  }} name="strategicVendorFixedCost" labelText="Fixed cost" value={config?.strategicVendorFixedCost} defaultValue={config?.strategicVendorFixedCost} type="number" placeholder="0" />
                </div>
              </div>
            </fieldset>
            <div className="flex items-center justify-end">
              <Button
                ref={formSubmitButtonRef}
                type="submit"
                notify={false}
                id="editCode"
                cb={saveChanges}
                text="Save"
                loadingText="Saving..."
                successMsg="Changes saved successfully"
              />
            </div>
          </div>
        </form>
      </>
    </Dialog>
  )
}
export default EarningsDialog;