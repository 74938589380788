import { FormEvent, Fragment, MutableRefObject, useEffect, useRef, useState } from 'react';
import { createNewAgent, updateAgentStatus } from "../actions/agent.actions";
import { Agent } from '../types/agent.data';
import { useAppSelector } from '../../../redux'
import Select from '../../../components/Select/select';
import Button from '../../../components/Button/Button';
import Dialog from '../../../components/Dialog/SideDrawer';
import Input from '../../../components/Input/input';


const AgentsStatusDialog = (props: any) => {
  const formSubmitButtonRef = useRef() as MutableRefObject<{ click: Function }>;

  const agents = useAppSelector(state => state.agent.agents);
  const [res, setRes] : [any, Function] = useState(null);
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState("ONLINE");
  const [selectedStatus, setSelectedStatus] = useState("ONLINE");
  const [replacementAgent, setReplacementAgent] : [any, Function] = useState();

  useEffect(() => {
    setOpen(true);
    setStatus(props.agent.status);
  }, [props.agent.id]);

  function onClose(value: boolean) {
    setOpen(value);
    if (!value) {
      setStatus("ONLINE");
      setReplacementAgent(undefined);
      props.onClose();
    }
  }

  async function saveChanges() {
    if(props.action === 'Add') {
      try {
        const res = await createNewAgent(props.agent);
        if (res.error !== null) {
          throw new Error(res.error.message); 
        }
        props.onClose();
      } catch (error) {
        props.onClose();
        throw error;
      }
    } else {
      await updateAgentStatus(props.agent, status, replacementAgent);
      props.onClose();
    }
  }

  function onStatusChange(status: string) {
    setStatus(status);
    setSelectedStatus(status);
  }

  function onAgentChange(id: string) {
    const agent = agents.find((agent: Agent) => agent.id === id);
    setReplacementAgent(agent);
  }

  return (
    <Dialog onClose={onClose} open={open} title="Edit agent">
      <form onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation()
        formSubmitButtonRef?.current?.click();
      }}>
        <div className="mb-3">
        {props.action === 'Add' ? 
          <div className="grid grid-cols-2 gap-6">
            <div className="flex-1">
              <Input className="h-9" onChange={(e: any) => {
                props.setSelectedAgent({
                  ...props.agent,
                  email: e.target.value,
                })
              }} rules={{
                required: true,
                maxLength: 50,
                pattern: '[a-zA-Z]+@mashkor.com' 
              }} name="email" labelText="Email" value={props?.agent?.email} type="text" placeholder="mennah@mashkor.com" />
            </div>
            <div className="flex-1">
              <Input className="h-9" onChange={(e: any) => {
                props.setSelectedAgent({
                  ...props.agent,
                  name: e.target.value,
                })
              }} rules={{
                required: true,
                maxLength: 50,
              }} name="name" labelText="Name" value={props?.agent?.name} type="text" placeholder="Mennah" />
            </div>
          </div>
          :<div className="grid grid-cols-2 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="countries" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label>
              <Select rules={{
                required: true,
              }} className="h-9" value={status}
                id="status" onChange={(e: any) => onStatusChange(e.target.value)} name="Status">
                <option value="" selected disabled hidden>Choose status</option>
                <option defaultValue="ONLINE" value="ONLINE">ONLINE</option>
                <option defaultValue="OFFLINE" value="OFFLINE">OFFLINE</option>
                <option defaultValue="BREAK" value="BREAK">BREAK</option>
              </Select>
            </div>
            {selectedStatus === "OFFLINE" && !(props?.agent?.isAdmin || props.agent?.isSuperAdmin) ?
              <div className="col-span-6 sm:col-span-3">
                <Select className="h-9" value={replacementAgent?.id ?? ''}
                  id="agent" onChange={(e: any) => onAgentChange(e.target.value)} name="Agent">
                  <option value="" selected disabled hidden>Choose agent</option>
                  {agents?.filter((agent: Agent) => !(agent.isAdmin || agent.isSuperAdmin) && agent.status !== "OFFLINE" && agent.id !== props?.agent?.id).map((a: Agent) => (
                    <option value={a.id} key={a.id}>{a.name}</option>
                  ))}
                </Select>
                <p className='text-xs mt-2'>Orders assigned to {props.agent.name} will be reassigned to the selected agent</p>
              </div>
              : ""}
          </div>}
        </div>

        <div className="py-3 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
          <Button
            ref={formSubmitButtonRef}
            type="submit"
            notify={false}
            id="agentStatus"
            cb={saveChanges}
            text="Save"
            loadingText="Saving..."
            successMsg="Changes saved successfully"
            errorMsg={res?.error?.message ?? "Error"}
          />
        </div>
      </form>
    </Dialog>
  )
}

export default AgentsStatusDialog;