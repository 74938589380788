import firebase from "firebase";
import { DATABASE, FUNCTION } from "../../../config/firebase";
import { BASE_URL } from "../../../config/url";
import { assignValueToObject } from "../types/settings.busyMode";

type Listeners = {
  onUpdate?: Function,
}

type fetchActiveOrdersProps = {
  listeners: Listeners
}

export const fetchFleetConfig = async () => {
  const query = await DATABASE.collection("config").doc("fleet_api").get();
  const data = await query.data();
  return {
    courierBaseUrl: data?.courierBaseUrl,
    courierApiKey: data?.courierApiKey,
    courierAuthKey: data?.courierAuthKey,
  };
};

export const fetchConfig = async ({ listeners } : fetchActiveOrdersProps) => {
  try {
    return DATABASE.collection("config").doc("business_config").onSnapshot((snapshot: any) => {
      const data = snapshot.data();
      listeners && listeners.onUpdate && listeners.onUpdate({
        startTime: data?.startTime,
        endTime: data?.endTime,
        busyMode: data?.busyMode,
        showCourierSettings: data?.showCourierSettings,
      });
    });
  } catch (error) {
    console.log("fetchConfig-error: ", error);
  }
};
export const updateConfig = async (config: string, data: any) => {
  try {
    await DATABASE.collection("config").doc(config).update(data);
  } catch (error) {
    console.log("error-updateConfig: ", error);
  }
};

export const fetchBusyModeHistory = async () => {
  try {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const query = await DATABASE.collection("busy_mode_history")
      .where("activatedAt", ">", firstDayOfMonth)
      .orderBy("activatedAt", "desc")
      .get();
    if (query.empty) return;
    return query.docs.map((q) => {
      return assignValueToObject(q);
    });
  } catch (error) {
    console.log("fetchBusyModeHistory-error: ", error);
  }
};

export const deactivateCurrentBusyModeHistoryDoc = async (zonesSummary: any, agent: any) => {
  const buddyMetrics = zonesSummary?.buddyMetrics.find((buddy: any) => buddy.tag === "MASHKOR_APP");
  const orderMetrics = zonesSummary?.orderMetrics.find((order: any) => order.tag === "MASHKOR_APP");
  try {
    const query = await DATABASE.collection("busy_mode_history").orderBy("activatedAt", "desc").get();
    if(query.empty) return;
    await DATABASE.collection("busy_mode_history").doc(query?.docs[0].id)
    .update({
        'deactivatedAt': firebase.firestore.FieldValue.serverTimestamp(),
        'deactivatedBy': agent,
        'totalActiveOrdersOnDeactivate': orderMetrics.total ?? 0,
        'unassignedOrdersOnDeactivate': orderMetrics.unassigned ?? 0,
        'freeBuddiesOnDeactivate': buddyMetrics.free ?? 0,
    });

  } catch (error) {
    console.log('error: deactivateCurrentBusyModeHistoryDoc', error)
  }
}

export const addNewBusyModeDoc = async(zonesSummary: any, agent: any) => {
  const buddyMetrics = zonesSummary?.buddyMetrics.find((buddy: any) => buddy.tag === "MASHKOR_APP");
  const orderMetrics = zonesSummary?.orderMetrics.find((order: any) => order.tag === "MASHKOR_APP");
  try {
    console.log("addNewBusyModeDoc")
    await DATABASE.collection("busy_mode_history")
    .add({
        activatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        activatedBy: agent,
        totalActiveOrders: orderMetrics.total ?? 0,
        unassignedOrders: orderMetrics.unassigned ?? 0,
        freeBuddies: buddyMetrics.free ?? 0,
    });
    
  } catch (error) {
    console.log('error: addNewBusyModeDoc', error)
  }
}

export const fetchTaggingConfig = async ({ listeners } : fetchActiveOrdersProps) => {
  try {
    return DATABASE.collection("config").doc("order_tagging").onSnapshot((snapshot: any) => {

      listeners && listeners.onUpdate && listeners.onUpdate(snapshot.data());

    });
  } catch (error) {
    console.log("fetchTaggingConfig-error: ", error);
  }
};

export const fetchZones = async () => {
  const query = await DATABASE.collection("zones").get();
  return query?.docs;
};

export async function fetchBusyMoodStatusAPI(fleetConfig: any) {
  const url = `${fleetConfig.courierBaseUrl}/v1/b/ig/config/busy-mode/status`;
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${fleetConfig.courierAuthKey}`,
      "x-api-key": fleetConfig.courierApiKey,
    },
  };
  console.log("Get busy mode status: ", url, JSON.stringify(request));
  const response = await fetch(url, request);

  if (!response.ok) {
    const responseText = await response.text();
    console.log("Get busy mode status error from fleet: ", responseText);
    throw new Error("Error response from fleet");
  }

  const respJson = await response.json();
  return respJson;
}

export async function fetchBusyMoodTypesAPI(fleetConfig: any) {
  const url = `${fleetConfig.courierBaseUrl}/v1/b/ig/config/busy-mode-type/list`;
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${fleetConfig.courierAuthKey}`,
      "x-api-key": fleetConfig.courierApiKey,
    },
  };
  console.log("Get busy mode types: ", url, JSON.stringify(request));
  const response = await fetch(url, request);

  if (!response.ok) {
    const responseText = await response.text();
    console.log("Get busy mode types error from fleet: ", responseText);
    throw new Error("Error response from fleet");
  }

  const respJson = await response.json();
  return respJson;
}

export const addCourierBusyMode = async (fleetConfig: any, busyModeObj: any) => {
  try {
    const url = `${fleetConfig.courierBaseUrl}/v1/b/ig/config/busy-mode/add`;
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${fleetConfig.courierAuthKey}`,
      "x-api-key": fleetConfig.courierApiKey,
    },
    body: JSON.stringify(busyModeObj)
  };
  console.log("Activate busy mode: ", url, JSON.stringify(request));
  const response = await fetch(url, request);

  if (!response.ok) {
    const responseText = await response.text();
    console.log("Activate busy mode: ", responseText);
    throw new Error("Error response from fleet");
  }

  const respJson = await response.json();
  return respJson;
  } catch (error) {
    console.log("error: addCourierBusyMode ", error)
  }
}

export const deactivateCourierBusyMode = async (fleetConfig: any, busyModeId: any) => {
  try {
    const url = `${fleetConfig.courierBaseUrl}/v1/b/ig/config/busy-mode/deactivate`;
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${fleetConfig.courierAuthKey}`,
      "x-api-key": fleetConfig.courierApiKey,
    },
    body: JSON.stringify({
      "id": busyModeId
    })
  };
  console.log("Activate busy mode: ", url, JSON.stringify(request));
  const response = await fetch(url, request);

  if (!response.ok) {
    const responseText = await response.text();
    console.log("Activate busy mode: ", responseText);
    throw new Error("Error response from fleet");
  }

  const respJson = await response.json();
  return respJson;
  } catch (error) {
    console.log("error: addCourierBusyMode ", error)
  }
}
