import { MutableRefObject, useRef, useEffect, useState } from "react";
import toast from "react-hot-toast";
import Button from "../../../../../components/Button/Button";
import Input from "../../../../../components/Input/input";
import { changePaymentDetails } from "../../../actions/order.actions";

export default function CourierOrderInfo(props: any) {

  const formSubmitButtonRefCourier = useRef() as MutableRefObject<{ click: Function }>;
  const [paymentType, setPaymentType] = useState('');
  const [cashToCollect, setCashToCollect] = useState(0);
  

  useEffect(() => {
    setPaymentType(props?.orderInfo?.paymentType);
    setCashToCollect(props?.orderInfo?.cashToCollect);
  }, [props.orderNo]);

  useEffect(() => {
    if(!paymentType) setPaymentType(props?.orderInfo?.paymentType);
    if(!cashToCollect) setCashToCollect(props?.orderInfo?.cashToCollect);
  }, [paymentType, cashToCollect]);

  const handlePaymentTypeChange = (value: string) => {
    setPaymentType(value);
  }

  const handleCashChange = (e: any) => {
    setCashToCollect(e.target.value);
  }

  const saveChanges = async () => {
    await changePaymentDetails(props.orderId, props.orderNo, paymentType, cashToCollect);
  }

  return (
    <>
      <div className="flex w-full h-full justify-between items-center">
        <ul className="flex-1 grid gap-3 md:grid-cols-2 mx-1 my-2">
          <li className="relative">
            <label className={`flex justify-between items-center p-3 bg-white border ${paymentType === 'KNET' ? "bg-blue-500 text-white" : "border-gray-300"}  rounded-lg cursor-pointer focus:outline-none`}
              onClick={() => handlePaymentTypeChange('KNET')}
            >
              KNET
              <input className="sr-only peer" type="radio" value="KNET" name="payment" />
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path></svg>
            </label>
          </li>

          <li className="relative">
            <label className={`flex justify-between items-center p-3 bg-white border ${paymentType === 'CASH' ? "bg-blue-500 text-white" : "border-gray-300"}  rounded-lg cursor-pointer focus:outline-none`}
              onClick={() => handlePaymentTypeChange('CASH')}
            >
              CASH
              <input className="sr-only peer" type="radio" value="CASH" name="payment" />
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path></svg>          </label>
          </li>
        </ul>
        <form onSubmit={(e: any) => {
          e.preventDefault();
          formSubmitButtonRefCourier?.current?.click();
        }} className="flex flex-1 justify-around items-center gap-1 mx-1">
          {paymentType === 'CASH' ?
            <div className="flex-1 mb-2">
              <Input
                rules={{
                  required: true,
                  min: 0,
                  max: 9999999,
                  step: "any",
                  disabled: props.primaryStatus === "OTHERS" ? true : false,
                }}
                onChange={(e: any) => handleCashChange(e)} labelText="Cash to collect" defaultValue={cashToCollect} value={cashToCollect} type="number" placeholder="cashToCollect" />
            </div> :
            <></>}
          {props.primaryStatus !== 'OTHERS' ? 
            <div className="flex-1 items-center justify-end">
              <Button
                ref={formSubmitButtonRefCourier}
                type="submit"
                notify={false}
                id="editCourier"
                cb={saveChanges}
                text="Save"
                loadingText="Saving..."
                successMsg="Changes saved successfully"
              />
            </div>
          : <></>}
        </form>
      </div>
    </>
  )
}