import { useEffect, useState } from "react";
import { EARNINGS_STORE_NAME } from "../../../contants/stores";
import { useAppSelector } from "../../../redux";
import { deleteEarningConfig, getEarnings, updateQuery } from "../actions/earnings.actions";
import { EarningsList } from "./earningsList";
import EarningsDialog from "./earningsDialog";
import EarningSearch from "./earningsSearch";
import { useLocation } from "react-router-dom";
import { EarningConfig } from "../types/earning.data";

const Earnings = () => {
  const location = useLocation();
  const newEarningConfig : EarningConfig = useAppSelector(state => state[EARNINGS_STORE_NAME].defaultConfig);
  const earningConfigsInitial = useAppSelector(state => state[EARNINGS_STORE_NAME].earningConfigs);
  const [config, setSelectedConfig] : [EarningConfig, Function] = useState(newEarningConfig);
  const [action, setAction] = useState('Add');
  const [titles, setTitles] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getEarnings();
  }, []);

  useEffect(() => {
    // Clear search input when location changes
    updateQuery('');
  }, [location.pathname]);

  useEffect(() => {
    let ts = earningConfigsInitial?.map((code:any) => {return code.title.toUpperCase()});
    setTitles(ts);
  }, [earningConfigsInitial]);

  const deleteCode = async (title: string) => {
    let isSure = window.confirm(`Are you sure you want to delete this earning config: ${title}?`)
    if (isSure) {
      await deleteEarningConfig(title);
    }
  }

  return (
    <div className="shadow-md sm:rounded-lg flex flex-col items-center py-4 bg-white m-4 p-4">
      <div className="w-full flex justify-between">
        <EarningSearch/>
        <button className="h-10 pl-5 pr-2 flex justify-between items-center bg-blue-600 text-white font-medium text-xs leading-normal uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out" 
        onClick={() => {
          setSelectedConfig(newEarningConfig);
          setAction('Add');
          setOpen(true);
        }}>
          <span>Add New earning config</span>
          <svg width={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 7.5C12.4142 7.5 12.75 7.83579 12.75 8.25V11.25H15.75C16.1642 11.25 16.5 11.5858 16.5 12C16.5 12.4142 16.1642 12.75 15.75 12.75H12.75V15.75C12.75 16.1642 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1642 11.25 15.75V12.75L8.25 12.75C7.83579 12.75 7.5 12.4142 7.5 12C7.5 11.5858 7.83579 11.25 8.25 11.25L11.25 11.25V8.25C11.25 7.83579 11.5858 7.5 12 7.5Z" fill="#fff" />
          </svg>
        </button>
      </div>
      <EarningsList
        earnings={earningConfigsInitial}
        onPressItem={async (config: any, formAction: string) => {
          // console.log(config)
          if (formAction === 'EDIT') {
            setAction('Edit')
            setSelectedConfig({ ...config });
            setOpen(true);
          } else {
            await deleteCode(config.title);
          }

        }}
      />
      {config ? <EarningsDialog titles={titles} config={config} action={action} open={open} setOpen={setOpen} onClose={() => setSelectedConfig(undefined)} /> : null}
    </div>
  );
}

export default Earnings;