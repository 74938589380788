import { Autocomplete } from "../../../../../components/Autocomplete/Autocomplete";
import { CUSTOMER_STORE_NAME } from "../../../../../contants/stores";
import { useAppSelector } from "../../../../../redux";

export default function SaveAddressesDropDown(props: any) {
  const saveAddresses = useAppSelector(state => state[CUSTOMER_STORE_NAME].savedAddresses).filter((a: any) => a.name !== props.otherAddress);
  return (
    <>
      <div className="w-full">
        <Autocomplete onChange={(e: any) => { 
          props.setAddressType(e.type ?? 'HOME')
          props.setUpdatedAddress(e);
          props.setSavedAddressId(e.id);
        }} title="" key="address-search"
          defaultValue=''
          placeholder='Select and update - Customer saved addresses'
          options={saveAddresses} disabled={false} required={false}>
        </Autocomplete>
      </div>
    </>
  )
}