import { MutableRefObject, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginAction } from "../actions/auth.actions";
import { Logo } from "../../../assets/images/index";
import Input from "../../../components/Input/input";
import Button from "../../../components/Button/Button";

const Login = () => {
  const navigate = useNavigate();
  const submitButtonRef = useRef() as MutableRefObject<{ click: Function }>;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function handleChange(e: any, name: string) {
    if (name === 'email') setEmail(e.target.value);
    if (name === 'password') setPassword(e.target.value);
  }

  const login = async () => {
    try {
      const agent = await loginAction(email, password);
      if (agent) {
        navigate('/orders')
      };
    } catch (error) {
      console.log("catch", error)
      throw error
    }
  }

  return (
    <>
      <div className="container mx-auto h-[calc(100vh-64px)]">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <img src={Logo} height={80} width={200} alt=""></img>
              </div>
              <div className="rounded-t mb-0 px-6 py-6">
                <p>Login to mashkor OMS dashboard</p>
              </div>
              <div className="flex-auto px-6 py-10 pt-0">
                <form onSubmit={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  submitButtonRef?.current?.click();
                }}>
                  <div className="relative w-full mb-3">
                    <Input rules={{
                      required: true,
                      pattern: ".+@mashkor\.com"
                    }} labelText="Email" onChange={(e: any) => handleChange(e, "email")} type="email" placeholder="email" defaultValue="" value={email}/>
                  </div>

                  <div className="relative w-full mb-3">
                    <Input 
                      rules={{
                        required: true,
                      }} labelText="Password" onChange={(e: any) => handleChange(e, "password")} type="password" placeholder="password" defaultValue="" value={password}/>
                  </div>

                  <div className="text-center mt-6">
                    <Button
                      ref={submitButtonRef}
                      type="submit"
                      notify={false}
                      id="login"
                      cb={login}
                      text="Login"
                      loadingText="Loggin in..."
                      successMsg="Logged in successfully"
                      errorMsg="Incorrect email or password"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
