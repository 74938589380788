import { ORDER_STORE_NAME } from "../../../../../../contants/stores";
import { useAppSelector } from "../../../../../../redux";

export function CancelDialogContent(props: any) {
  const cancellationReasons = useAppSelector(state => props?.orderType === 'COURIER' ? state[ORDER_STORE_NAME].cancellationReasons.courier : state[ORDER_STORE_NAME].cancellationReasons.app);

  return (
    <>
      <div className="mx-3 my-2">
        <ul className="grid gap-3 w-full md:grid-cols-2">
          {cancellationReasons?.map((reason: string, idx: number) => (
            <li onClick={() => props.setSelectedCancellationReason(reason)} key={idx}>
              <input type="radio" id="react-option" value="" className="hidden peer" required={true} />
              <label className={`inline-flex justify-between items-center p-3 w-full bg-white rounded-lg border-2  cursor-pointer ${props.selectedCancellationReason === reason ? "border-blue-600 text-gray-600" : "text-gray-500 border-gray-200"} hover:text-gray-600 hover:bg-gray-50`}>
                <div className="block">
                  <div className="w-full text-base font-semibold">{reason}</div>
                </div>
              </label>
            </li>
          ))}
        </ul>
        {(props?.cardCharges && props?.cardCharges > 0) ? 
          <div className="text-xs text-red-500 my-2">
            **Card charges {props?.cardCharges} KD.** <br></br>
            Please confirm with the customer that the credit card charges will not be refunded.
          </div>
        :<></>}
      </div>
    </>
  )
}