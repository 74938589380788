import { MutableRefObject, useEffect, useRef } from "react";

export default function Select(props: any) {
  const inputRef: MutableRefObject<HTMLInputElement | undefined> = useRef();

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  function setValue(value: any) {
    if (inputRef?.current) {
      inputRef.current.value = value;
    }
  }

  return (
    <>
      <label>
        <span className="font-normal text-xs capitalize">{props.labelText ?? props.name}</span>
        <select
          ref={inputRef}
          value={props.value ?? ""}
          // defaultValue={props.defaultValue ?? ""}
          {...props.rules}
          onChange={props.onChange}
          className={`form-control
            block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded-lg
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ${props.className}`}
          aria-describedby="emailHelp124" placeholder={props.placeholder} name={props.name}>
            {props.children}
        </select>
      </label>
    </>
  )
}