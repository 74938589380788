export function formatDate(seconds: any, config: any = {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
  year: "numeric",
  month: "numeric",
  day: "numeric",
}) {
  if (seconds) {
    const value = new Date(seconds);
    return value.toLocaleString("en-US", {
      ...config
    });
  }
}

export function selectContents(id: any) {
  var range = document.createRange();
  range.selectNode(document.getElementById(id)!);
  let selection = window.getSelection();
  selection?.removeAllRanges();
  selection?.addRange(range);
  document.execCommand("copy");
}
