import { Fragment } from 'react';
import { Transition, Menu } from '@headlessui/react';
import { updatePinnedOrders } from '../../../agent/actions/agent.actions';
import { search } from '../../actions/order.actions';

export default function PinnedOrders(props: any) {

  const removePinnedOrder = async(orderNo : string) => {
    const pinnedOrders = props?.pinnedOrders?.filter(
      (pinnedOrder : any) => pinnedOrder !== orderNo
    );
    updatePinnedOrders(props?.agentId, pinnedOrders);
  }

  const toggleSearchDialog = async(orderNo: string) => {

    await search("ORDER", orderNo);
  }

  return (
    <div className="">
      <Menu >
        {({ open }) => (
          <div className="relative mt-1">
            <div className="relative">
              <Menu.Button className="inline-flex w-full justify-center rounded-md p-1 text-sm font-medium text-indigo-400 hover:bg-indigo-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <svg className="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path fill="currentColor" d="M18 3v2h-1v6l2 3v2h-6v7h-2v-7H5v-2l2-3V5H6V3z" />
                  </g>
                </svg>
              </Menu.Button>
              <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 flex justify-center items-center w-5 h-5 rounded-full text-xs bg-indigo-500 text-white">
                {props?.pinnedOrders?.length}
              </div>
            </div>
            {open && (
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Menu.Items static className="absolute w-60 space-y-3 py-2 px-3 z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <Menu.Item>
                    {({ active }) => (
                      <div>
                        {props?.pinnedOrders?.map((orderNo: any, idx: number) => (
                          <div onClick={(e) => {
                            e.stopPropagation();
                            }} key={orderNo}  className="flex justify-center items-center m-2 font-medium py-2 px-2 rounded text-gray-700 bg-gray-100 border border-gray-300 hover:cursor-pointer">
                            <div onClick={(e) => {
                              e.stopPropagation();
                              toggleSearchDialog(orderNo);
                              }} className="text-sm font-normal leading-none max-w-full flex-initial">
                              {orderNo}
                            </div>
                            <div onClick={(e) => {
                                e.stopPropagation();
                                toggleSearchDialog(orderNo);
                              }}className="flex flex-auto flex-row-reverse">
                              <div onClick={(e) => {
                                  e.stopPropagation();
                                  removePinnedOrder(orderNo);
                                }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x cursor-pointer hover:text-red-400 rounded-full w-4 h-4 ml-2">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            )}
          </div>
        )}
      </Menu>
    </div>
  )
}
