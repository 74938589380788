import { EarningConfig } from "../types/earning.data";
import { useAppSelector } from '../../../redux';
import { EARNINGS_STORE_NAME, PROMOCODE_STORE_NAME } from "../../../contants/stores";
import { formatDate } from "../../../utils/helpers";
import Table from "../../../components/Table/Table";
// import { search } from "../actions/propmoCode.actions";
import { useEffect, useState } from "react";
import { search } from "../actions/earnings.actions";

export const EarningsList = (props: any) => {
  const query = useAppSelector(state => state[EARNINGS_STORE_NAME].searchQuery)
  // const promoCodes = useAppSelector(state => state[PROMOCODE_STORE_NAME].promoCodes);
  const headers = ["Title", "Base dist", "Extra dist", "Savior", "Champion", "Hero", "Action"];
  const [earnings, setEarnings] = useState(props.earnings);

  useEffect(() => {
    updateSearchList()
  }, [props.earnings])

  useEffect(() => {
    updateSearchList()
  }, [query]);

  const updateSearchList = () => {
    if (!query.length) {
      setEarnings(props.earnings);
    } else {
      setEarnings(search(query));
    }
  }

  return (
    <>
      {query.length && !earnings.length ?
        <div className="w-full order-1 flex-1 bg-white rounded-lg m-4 text-xs">
          No results found
        </div> :
        <Table headers={headers}>
          {earnings && earnings?.map((config: EarningConfig) => (
            <tr className="bg-white border-b hover:bg-gray-50 text-xs" key={config?.title}>
              <th scope="row" className="flex items-center py-4 px-6 text-gray-900 whitespace-nowra text-xs">
                <div className="">
                  {/* <div className="text-base font-semibold">{config?.title}</div> */}
                    <div className="text-sm font-semibold text-gray-700">{config?.title}</div>
                  <div className="font-normal text-gray-500">{config?.earningWindowHours} Hr</div>
                </div>
              </th>
              <td className="py-4 px-6">
                {(config?.baseDistance ?? 0) / 1000} KM
              </td>
              <td className="py-4 px-6">
                <p className="text-xs font-semibold text-gray-700">{config?.extraDistanceMaxPrice ?? 0} KD</p>
                <p className="font-normal text-gray-500">{(config?.extraDistanceUnit ?? 0) / 1000 } KM - {config?.extraDistanceUnitPrice} KD</p>
              </td>
              <td className="py-4 px-6">
                {config?.saviorBasePrice} KD
              </td>
              <td className="py-4 px-6">
                <p className="text-xs font-semibold text-gray-700">{config?.championBasePrice} KD </p>
                <p className="font-normal text-gray-500">{(config?.championTotalDistanceStart ?? 0) / 1000} KM or {config?.championOrderCount} Order</p>
              </td>
              <td className="py-4 px-6">
                <p className="text-xs font-semibold text-gray-700">{config?.heroBasePrice} KD</p>
                <p className="font-normal text-gray-500">{(config?.heroTotalDistanceStart ?? 0) / 1000} KM or {config?.heroOrderCount} Order</p>
              </td>
              <td className="flex gap-2 py-4 px-6">
                <button
                  onClick={() => props.onPressItem(config, 'EDIT')}
                  data-modal-toggle="editUserModal" className="font-medium text-blue-600 hover:underline">Edit</button>
                <button
                  onClick={() => props.onPressItem(config, 'DELETE')}
                  data-modal-toggle="editUserModal" className="font-medium text-red-600 hover:underline">Delete</button>
              </td>
            </tr>
          ))}
        </Table>}
    </>
  );
}