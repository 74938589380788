
// Firebase config
import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const firebaseConfig = {
  projectId: process.env.REACT_APP_PROJECT_ID,
  apiKey: process.env.REACT_APP_API_KEY,
  messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
  appId: process.env.REACT_APP_APP_ID,
  authDomain: `${process.env.REACT_APP_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_PROJECT_ID}.firebaseio.com`,
  storageBucket: `${process.env.REACT_APP_PROJECT_ID}.appspot.com`,
};

firebase.initializeApp(firebaseConfig);

export const AUTH = firebase.auth();
export const DATABASE = firebase.firestore();
export const STORAGE = firebase.storage();
export const FUNCTION = firebase.app().functions("asia-south1");
export default firebase;


// export default {AUTH, DATABASE, FUNCTIONS}