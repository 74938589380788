import { FormEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../redux";
import { SETTINGS_STORE_NAME } from "../../../../contants/stores";
import Select, { MultiValue } from 'react-select'
import Input from "../../../../components/Input/input";
import Button from "../../../../components/Button/Button";
import { updateCourierTaggingConfig } from "../../actions/settings.actions";


export default function CourierTagging(props: any) {
  const zones = useAppSelector(state => state[SETTINGS_STORE_NAME].zones);
  const courierTagging = useAppSelector(state => state[SETTINGS_STORE_NAME].courierTagging);
  const submitButtonRefCourierTagging = useRef() as MutableRefObject<{ click: Function }>;
  const [selectedZones, setSelectedZones] = useState([]);
  const [maxDistance, setMaxDistance] = useState(0);

  useEffect(() => {
    let selectedZones = courierTagging?.pickupZones?.map((zone: any) => ({
      value: zone.id,
      label: zone.name,
    }));
    setSelectedZones(selectedZones);
    setMaxDistance(courierTagging?.distance)
  }, []);

  function handleZonesChange(value: any): void {
    setSelectedZones(value);
  }

  const handleSubmit = async() => {
    let arr : any[] = []
    selectedZones.forEach((zone: any) => {
      arr.push({
        id: zone.value,
        name: zone.label
      });
    });
    await updateCourierTaggingConfig(arr, maxDistance)
  }

  return (
    <>{!courierTagging.enableConfig ?
      <>
        <div className="my-2 mb-4">
          <p className="font-bold">
            Courier Dashboard tagging configuration
          </p>
          <p className="mt-1 text-sm">
            Counfiguration is not available
          </p>
        </div>
      </> :
      <form onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation()
        submitButtonRefCourierTagging?.current?.click();
      }} className="text-sm p-2">
        <div className="my-2 mb-4">
          <p className="font-bold">
            Courier Dashboard tagging configuration
          </p>
          <p className="mt-1 text-sm">
            Update courier (BIKE) configuration
          </p>
        </div>
        <div className="py-2 border-t border-gray-300">
          <div className="flex items-center gap-3">
            <p className="mr-2">Order maximum distance</p>
            <div className="flex items-center">
              <Input type="number" value={maxDistance}
                onChange={(e: any) => {
                  setMaxDistance(e.target.value)
                }}
                rules={{
                  required: true,
                  min: 0,
                  max: 9999999,
                  step: "any",
                }}
              />
            </div>
          </div>
        </div>
        <div className="py-2 border-t border-gray-300">
          <p className="font-semibold my-2">
            Zones
          </p>
          <Select className="basic-multi-select focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            isClearable
            isSearchable={true}
            noOptionsMessage={() => "Loading..."}
            onChange={(value) => handleZonesChange(value)}
            isMulti
            options={zones}
            classNamePrefix="select"
            value={selectedZones}
          />
        </div>
        <div className="py-2 border-t border-gray-300">
          <div className="w-fit float-right">
            <Button
              ref={submitButtonRefCourierTagging}
              type="submit"
              notify={false}
              id="appTagging"
              cb={handleSubmit}
              text="Save"
              loadingText="Saving..."
              successMsg="Changes successfully"
              errorMsg="Incorrect email or password"
            />
          </div>
        </div>
      </form>}
    </>
  )
}