
export const Table = (props: any) => {

  return (
    <div className="w-full order-1 flex-1 bg-white rounded-lg m-4">
      <table className="text-xs text-left text-gray-500 w-full h-full ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            {props.headers.map((th: string) => (
              <th key={th} scope="col" className="py-3 px-6">
                {th}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.children}
        </tbody>
      </table>
    </div>
  );
}

export default Table;