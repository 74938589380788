import { createSlice } from "@reduxjs/toolkit"
import { PROMOCODE_STORE_NAME } from "../../../contants/stores";
import { PromoCode } from '../types/promocode.data';

interface PromoCodeState {
  promoCodes: PromoCode[],
  searchQuery: string,
  filters: any
  status: string,
  error: Error | undefined
}

export const initialState: PromoCodeState = {
  promoCodes: [],
  searchQuery: '',
  filters: {
    orderType: {
      label: 'Order',
      options: [
        {
          value: 'Buy',
          path: "BUY",
          checked: false,
        },
        {
          value: "Pickup",
          path: "PICKUP",
          checked: false,
        },
        {
          value: "Custom order",
          path: "CUSTOM_ORDER",
          checked: false,
        },
        {
          value: "All",
          path: "ALL",
          checked: false,
        },
      ]
    },
    discountType: {
      label: 'Discount',
      options: [
        {
          value: "Delivery",
          name: "delivery",
          checked: false,
        },
        {
          value: "Service",
          name: "service",
          checked: false,
        },
        {
          value: "Delivery & service",
          name: "Delivery_and_service",
          checked: false,
        },
      ]
    },
    codeInfo: {
      label: 'Info',
      options: [
        {
          path: "isNewUserCode",
          value: "FTO",
          checked: false,
        },
        {
          path: "toPreload",
          value: "Preload",
          checked: false,
        },
        {
          path: "isCompensationCode",
          value: "Compensation",
          checked: false,
        },
        {
          path: "isListed",
          value: "Listed",
          checked: false,
        },
      ]
    },
  },
  status: 'idle',
  error: undefined
}

export const slice = createSlice({
  name: PROMOCODE_STORE_NAME,
  initialState,
  reducers: {
    setCodes: (state, action) => {
      state.promoCodes = action.payload;
    },
    updateCode: (state, action) => {
      let orderIndex = state.promoCodes.findIndex(
        (o) => o.id === action.payload.id
      );
      if (orderIndex >= 0) {
        state.promoCodes.splice(orderIndex, 1, {
          ...action.payload.data,
          id: action.payload.id,
        });
      }
    },
    updateSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setAppliedFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const actions = slice.actions
export const reducer = slice.reducer

