import { useEffect, useState } from "react"
import { ORDER_STORE_NAME } from "../../../../contants/stores";
import { useAppSelector } from "../../../../redux";
import { formatDate } from "../../../../utils/helpers";
import { getTimelineEvents } from "../../actions/config.actions";
import { OrderTimeLine } from "../../types/order.timeLine";

export default function OrderTimelineEvents(props: any) {
	const events = useAppSelector(store => store[ORDER_STORE_NAME].orderTimeLine);
	useEffect(() => {
		const unsubscribe = getTimelineEvents(props.orderId);
		return () => {
			if (unsubscribe) unsubscribe();
		}
	}, [props]);
	return (
		<>
			<div className="h-full py-3 pl-3 text-base overflow-y-auto">
				<div className="border-light pb-3 mb-3 font-bold">
					<ol className="relative border-l border-gray-200">
						{events?.map((event: OrderTimeLine, i: number) => (
							<li key={`event-${i}`} className="mb-4 ml-4">
								<span className="flex absolute -left-3 justify-center items-center w-5 h-5 bg-mashkorGreen/20 rounded-full ring-8 ring-white">
									<svg className="w-5 h-5 text-mashkorGreen" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>                            </span>
								<h3 className="flex items-center justify-between mb-1 text-sm font-semibold text-gray-900">{event.type === "ORDER_STATUS" ? "Order status change" : event.type}
									{i === 0 ? <span className="bg-mashkorGreen/20 text-mashkorGreen text-xsm font-medium px-2.5 py-0.5 rounded ml-3">Latest</span> : <></>}
								</h3>
								<p className="mb-1 text-xs font-normal text-gray-700"> {event.name} {event?.agentName ? ` | ${event?.agentName}` : "" }</p>
								<p className="truncate mb-2 text-xs font-normal text-gray-500"> {event.comment}</p>
								<time className="block mb-2 text-xs font-normal leading-none text-gray-400">{formatDate(event?.createdAt)}</time>
							</li>
						))}

					</ol>
				</div>
			</div>

		</>

	)
}