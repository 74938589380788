import { updateQuery } from "../actions/earnings.actions";

const EarningSearch = () => {

  const handleKeyPress = async (event: any) => {
    const value = (document.getElementById("chat") as HTMLInputElement).value.trim();
    updateQuery(value);
  };

  return (
    <>
      <div className="mr-5 relative h-10 flex-1 rounded-lg border border-gray-300 overflow-hidden focus-within:border-transparent focus-within:ring-indigo-400 focus-within:ring-2">
        <input
          onInput={handleKeyPress}
          id="chat" className="block p-2.5 w-full text-sm text-gray-900 bg-white focus:text-indigo-400 focus:outline-none" placeholder="Search ..." defaultValue={""} />

      </div>
    </>
  )
}

export default EarningSearch;