import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import Input from "../Input/input"

export const Autocomplete = forwardRef((props: any, forwardRef) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(false);
  const [filter, setFilter] = useState("");
  const [selected, setSelected] : [any, Function] = useState()

  useImperativeHandle(forwardRef, () => ({
    clearSelected() {
      setSelected(null)
    }
  }));

  useEffect(() => {
    setSelected(props.defaultValue)
  }, [props.defaultValue])

  useEffect(() => {
    // Function to handle click outside of component
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        // Call a function or set a state to handle the click outside event
        console.log('Clicked outside of component!');
        // console.log(props.options)
        setShowOptions(false);
        setFilter("")
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
  
  return(
    <>
      <div ref={ref} className="relative">
        <Input rules={{
          required: props.required,
          disabled: props.disabled,
          }}
          onInput={(e: any) => {
            console.log(e);
            setFilter(e.target.value)
          }}
          onFocus={(e: any) => {
            setShowOptions(true)
          }}
          labelText={props?.title} value={filter || selected?.name || ""} defaultValue={props?.defaultValue} type="text" placeholder={props.placeholder}>
        </Input>
        {showOptions && <div className="absolute w-max min-w-full max-h-48 overflow-y-auto bg-white mt-2 shadow-lg top-full left-0 rounded-lg z-50">
          {props?.options?.filter((opt: any) => opt?.name?.toUpperCase().includes(filter?.toUpperCase())).map((opt: any) => (
            <>
              <div onClick={() => {
                setSelected(opt);
                setFilter("");
                setShowOptions(false);
                props.onChange(opt);
                }} className="text-xs hover:bg-gray-100 hover:cursor-pointer px-2 py-1" key={opt.id}>{opt.name}</div>
            </>
          ))}
        </div>}
        
      </div>

    </>
  )
})