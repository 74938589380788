import { createSlice } from "@reduxjs/toolkit"
import { AUTH_STORE_NAME } from "../../../contants/stores";

interface AuthState {
  loggedInAgent: {
    id?: string;
    isAdmin?: boolean;
    isSuperAdming?: boolean;
  }
}

const initialState: AuthState = {
  loggedInAgent: {}
}

export const slice = createSlice({
  name: AUTH_STORE_NAME,
  initialState,
  reducers: {
    setLoggedInAgent: (state, agent: any) => {
      state.loggedInAgent = agent.payload;
    }
  },
});

export const actions = slice.actions
export const reducer = slice.reducer


