import Modal from "../../../../../components/Dialog/Modal";
import { OrderDetail } from "../OrderDetail";
import { close } from "../../../actions/order.actions";
export function OrderDetailModal(props: any) {

  return (
    <Modal fromSearch={true} title="Order details"
      onClose={() =>{close()}}>

      <div className="flex gap-3">
        <OrderDetail orderId={props?.orderId} order={props?.order} fromSearch={true}/>
      </div>

    </Modal>
  )
}