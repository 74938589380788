import { DATABASE } from "../../../config/firebase";
import { assignValueToObject } from "../types/customer.data";


type Listeners = {
  onUpdate?: Function,
}

type fetchActiveOrdersProps = {
  listeners: Listeners
}


export const fetchCustomer = (id: string, { listeners } : fetchActiveOrdersProps) => {
  try {
    return DATABASE.collection("customers").doc(id).onSnapshot((snapshot: any) => {

      listeners && listeners.onUpdate && listeners.onUpdate(assignValueToObject(snapshot));

    });

  } catch (error) {
    console.log("error while fethcimg customer data: ", error);
  }
};

export const fetchSavedAddresses = async (id: string) => {
  try {
    const addresses = [];
    const query = await DATABASE.collection("customers").doc(id).collection("addresses").get();
    for (let index = 0; index < query.docs?.length; index++) {
      const address = query.docs[index];
      addresses.push(address.data());
    }
    return addresses;
  } catch (error) {
    console.log("error while fethcimg customer saved addresses: ", error);
  }
}

export const save = async(id: string, data: any) => {
  try {
    console.log("save", id, data);
    await DATABASE.collection("customers").doc(id).update(data);
  } catch (e) {
    console.log("Update customer failed: ", e);
  }

}

export const addWallet = async (id: string, entry: any) => {
  try {
    await DATABASE.collection("/customers")
    .doc(id).collection("wallet_transactions")
    .add(entry);
  } catch (error) {
    console.log("error while adding wallet enty: ", error);
  }
};

export const addAddress = async (id: string, address: any) => {
  try {
    const docId = await DATABASE.collection("/customers")
    .doc(id).collection("addresses").add(address)
    .then(function (docRef) {
      // docRef is the reference to the newly created document
      console.log("Document written with ID: ", docRef.id);
      return docRef.id;
    })
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });
    return docId;
  } catch (error) {
    console.log("error while adding the address: ", error);
  }
}

export const updateAddressList = async (id: string, address: any) => {
  try {
    if(! address.id) return;
    DATABASE.collection("/customers")
    .doc(id).collection("addresses").doc(address.id).update(address)
  } catch (error) {
    console.log("error while updating the address: ", error);
  }
}

export const validateAddress = async (id: string, addressId: string, validationStatus: boolean) => {
  try {
    if(! addressId) return;
    await DATABASE.collection("/customers")
    .doc(id).collection("addresses").doc(addressId).update({isValid: validationStatus})
  } catch (error) {
    console.log("error while validating the address: ", error);
  }
}

export const customerSearch = async (phone: string, { listeners } : fetchActiveOrdersProps) => {
  try {
    return DATABASE.collection("customers").
      where("phone", "in", [phone, "965" + phone, "+965" + phone, phone.substring(1)]).
      onSnapshot((snapshot: any) => {
        const customerDoc = snapshot.docs?.find((doc: any) => !doc.data().isDeleted);
        listeners && listeners.onUpdate && listeners.onUpdate(assignValueToObject(customerDoc));
    });
  } catch (error) {
    console.log("customerSearch: ", error)
  }
}
