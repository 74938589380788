/* import React from 'react';
import { Link } from 'react-router-dom'
import Navbar from './components/Navbar/Navbar';
import Agents from './modules/agent/views/agentsPage';
import Login from './modules/auth/views/login';
import Orders from './modules/order/views/ordersPage'; */
import Routing from './routes/routes';

function App() {
  return (
    <Routing />
  );
}

export default App;
