import { useState, useEffect } from "react";

export default function DeliveryTimer(props: any) {

  const [seconds, setSeconds] = useState(0);
  const [deliveryTimer, setDeliveredTimer] = useState('');

  function convertIntoSeconds(date: any) {
    return date.getTime();
  }

  useEffect(() => {
    const i = setInterval(deliveryTime, 1000);
    return () => clearInterval(i);
  }, [props.deliveryAt]);

  function deliveryTime() {
    let timer = convertIntoSeconds(new Date(props.deliveryAt)) - convertIntoSeconds(new Date());

    setSeconds(timer);
    if (timer >= 0) {
      const currentTimer = new Date(timer);
      const hour = currentTimer.getUTCHours();
      const min = currentTimer.getUTCMinutes();
      const sec = currentTimer.getUTCSeconds();
      setDeliveredTimer(`${hour}: ${min}: ${sec}`);
      timer -= 1000;

    } 
    if(timer < 0) {
      timer *= -1;
      timer += 1000;
      
      const currentTimer = new Date(timer);

      const hour = currentTimer.getUTCHours();
      const min = currentTimer.getUTCMinutes();
      const sec = currentTimer.getUTCSeconds();
      setDeliveredTimer(`-${hour}: ${min}: ${sec}`);
    }
  }
  
  return(
    <>
    <div
        className={`text-xs ${seconds > 0 ? "text-black" : "text-red-400"}`}
      >
        {deliveryTimer}
      </div>
    </>
  )
}