export interface BusyMode {
  id: string;
  activatedAt: any,
  deactivatedAt: any,
  activatedBy: any,
  deactivatedBy: any,
  freeBuddies: number,
  freeBuddiesOnDeactivate: number,
  totalActiveOrders: number,
  totalActiveOrdersOnDeactivate: number,
  unassignedOrders: number,
  unassignedOrdersOnDeactivate: number
}
export function assignValueToObject(query: any) {
  return {
    id: query.id,
    activatedAt: query.data().activatedAt?.seconds * 1000,
    deactivatedAt: query.data().deactivatedAt?.seconds * 1000,
    activatedBy: {
      id: query.data().activatedBy?.id,
      name: query.data().activatedBy?.name
    },
    deactivatedBy: {
      id: query.data().deactivatedBy?.id,
      name: query.data().deactivatedBy?.name
    },
    freeBuddies: query.data().freeBuddies,
    freeBuddiesOnDeactivate: query.data().freeBuddiesOnDeactivate,
    totalActiveOrders: query.data().totalActiveOrders,
    totalActiveOrdersOnDeactivate: query.data().totalActiveOrdersOnDeactivate,
    unassignedOrders: query.data().unassignedOrders,
    unassignedOrdersOnDeactivate: query.data().unassignedOrdersOnDeactivate,
  };
}