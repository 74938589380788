import firebase from "firebase";
import { store } from "../../../redux";
import { addAddress, addWallet, customerSearch, fetchCustomer, fetchSavedAddresses, save } from "../services/customer.services";
import { actions as customerActions } from "../stores/customer.store"

export const getCustomerById = (id: string) => {
  try {
    return fetchCustomer(id, {
      listeners: {
        onUpdate: (res: any) => {
          console.log('updated', { ...res })
          store.dispatch(customerActions.setCustomer({ ...res }));
        },
      },
    });
  } catch (error) {
    console.log('get-customer', error)
  }
}

export const getSavedAddressesById = async (id: string) => {
  try {
    const docs = await fetchSavedAddresses(id);
    store.dispatch(customerActions.setSavedAddresses(docs));
  } catch (error) {
    console.log('getSavedAddressesById', error)
  }
}

export const addNewAddress = async (id: string, address: any) => {
  try {
    addAddress(id, address);
  } catch (error) {
    console.log('addNewAddress', error)
  }
}

export const searchCustomerByPhone = async(phone: string) => {
  try {
    return customerSearch(phone, {
      listeners: {
        onUpdate: (res: any) => {
          store.dispatch(customerActions.setSearchCustomer({...res}));
        },
      },
    });
  } catch (error) {
    console.log('searchCustomerByPhone: ', error);
  }
}

export const close = () => {
  store.dispatch(customerActions.setSearchCustomer({}));
}

export const updateTags = async (id: string, tags: string[]) => {
  try {
    await save(id, {
      tags,
    });
  } catch (error) {
    console.log('updateTags', error)
  }
}

export const addWalletEntry = async (id: string, amount: number, reason: string) => {
  try {
    const entry = {
      amount: Math.abs(amount), 
      reason: reason,
      type: amount > 0 ? "IN" : "OUT",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
    await addWallet(id, entry);

  } catch (error) {
    console.log('addWallet', error)
  }
}