import { AGENT_STORE_NAME, AUTH_STORE_NAME, CUSTOMER_STORE_NAME, ORDER_STORE_NAME, PROMOCODE_STORE_NAME, SETTINGS_STORE_NAME, ORDER_LOGS_STORE_NAME, EARNINGS_STORE_NAME, } from '../contants/stores';
import { reducer as agentReducer } from '../modules/agent/stores/agent.store';
import { reducer as authReducer } from "../modules/auth/stores/auth.store";
import { reducer as orderReducer } from "../modules/order/stores/order.store";
import { reducer as customerReducer } from "../modules/customer/stores/customer.store";
import { reducer as promoCodeReducer } from "../modules/promocode/store/promocode.store";
import { reducer as settingsReducer } from "../modules/settings/stores/settings.store";
import { reducer as logsReducer } from "../modules/order-logs/stores/order-logs.store";
import { reducer as earningsReducer } from "../modules/earnings/store/earnings.store";

export default {
  [AUTH_STORE_NAME]: authReducer,
  [AGENT_STORE_NAME]: agentReducer,
  [ORDER_STORE_NAME]: orderReducer,
  [CUSTOMER_STORE_NAME]: customerReducer,
  [PROMOCODE_STORE_NAME]: promoCodeReducer,
  [SETTINGS_STORE_NAME]: settingsReducer,
  [ORDER_LOGS_STORE_NAME]: logsReducer,
  [EARNINGS_STORE_NAME]: earningsReducer,
}