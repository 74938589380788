export interface OrderTimeLine {
  id: string;
  name: string;
  agentName: string;
  type: string;
  comment?: string;
  createdAt: any
}

export function assignValueToObject(query: any) {
  return {
    id: query.id,
    createdAt: query.data().createdAt.seconds * 1000,
    name: query.data().name,
    agentName: query.data().agentName,
    type: query.data().type,
    comment: query.data().comment,
  };
}