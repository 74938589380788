import { Fragment } from 'react'
import { useAppSelector } from '../../../redux';
import { PROMOCODE_STORE_NAME } from "../../../contants/stores";
import { Transition, Menu } from '@headlessui/react'
import { clearFilters, updatePromoCodeFilters } from '../actions/propmoCode.actions';
const PromoCodesFilter = () => {
  // const [stateFilters, setStateFilters]
  const filters = useAppSelector(state => state[PROMOCODE_STORE_NAME].filters);

  // console.log(filters)
  const onChange = (e: any, key: string, idx: number) => {
    const _items = [...filters[key].options];
    const item = { ..._items[idx] };
    _items.splice(idx, 1, { ...item, checked: !item.checked })
    updatePromoCodeFilters({
      ...filters, [key]: {
        ...filters[key],
        options: [..._items]
      }
    });
  }

  return (
    <div className="">
      <Menu >
        {({ open, close }) => (
          <div className="relative">
            <Menu.Button className="h-10 px-2 mr-2 flex justify-between items-center bg-blue-600 text-white font-medium text-xs leading-normal uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
              Select promocode filters 
              <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd"></path></svg>
            </Menu.Button>
            {open && (
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Menu.Items static className="absolute w-60 space-y-3 py-2 px-3 z-50 mt-1 rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <Menu.Item>
                    {({ active }) => (
                      <div className='bg-white max-h-48 overflow-auto'>
                        {Object.entries(filters)?.map(([key, settings]: any) => (
                          <div key={key} className="mb-3" onClick={e => e.stopPropagation()}>
                            <h3 className='mb-2 pb-1 border-b border-gray-100'>{settings.label}</h3>
                            <div className='grid grid-cols-2 '>
                              {settings.options.map((f: any, idx: number) => (
                                <label className="space-x-1"
                                  key={f.value}
                                  onChange={(e) => {
                                    onChange(e, key, idx)
                                  }}>
                                  <input type="checkbox" defaultChecked={f.checked} value={f.value} className="form-tick  bg-white bg-check h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none" />
                                  <span className="text-xs text-gray-700">
                                    {f.value}
                                  </span>
                                </label>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </Menu.Item>

                  <div className="flex gap-1 justify-end sticky bottom-0 !m-0">
                    <button 
                      onClick={() => {
                        clearFilters();
                        close();
                      }}
                      className={`inline-flex justify-center rounded-md p-1 text-sm font-medium text-gray-900 hover:text-opacity-50 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}>
                      <p>Clear</p>
                      <svg className="h-5 w-5"
                        viewBox="0 0 32 32"
                        id="icon"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <defs>
                          <style
                            dangerouslySetInnerHTML={{
                              __html: "\n      .cls-1 {\n        fill: none;\n      }\n    "
                            }}
                          />
                        </defs>
                        <title>clean</title>
                        <rect
                          x={20}
                          y={18}
                          width={6}
                          height={2}
                          transform="translate(46 38) rotate(-180)"
                        />
                        <rect
                          x={24}
                          y={26}
                          width={6}
                          height={2}
                          transform="translate(54 54) rotate(-180)"
                        />
                        <rect
                          x={22}
                          y={22}
                          width={6}
                          height={2}
                          transform="translate(50 46) rotate(-180)"
                        />
                        <path d="M17.0029,20a4.8952,4.8952,0,0,0-2.4044-4.1729L22,3,20.2691,2,12.6933,15.126A5.6988,5.6988,0,0,0,7.45,16.6289C3.7064,20.24,3.9963,28.6821,4.01,29.04a1,1,0,0,0,1,.96H20.0012a1,1,0,0,0,.6-1.8C17.0615,25.5439,17.0029,20.0537,17.0029,20ZM11.93,16.9971A3.11,3.11,0,0,1,15.0041,20c0,.0381.0019.208.0168.4688L9.1215,17.8452A3.8,3.8,0,0,1,11.93,16.9971ZM15.4494,28A5.2,5.2,0,0,1,14,25H12a6.4993,6.4993,0,0,0,.9684,3H10.7451A16.6166,16.6166,0,0,1,10,24H8a17.3424,17.3424,0,0,0,.6652,4H6c.031-1.8364.29-5.8921,1.8027-8.5527l7.533,3.35A13.0253,13.0253,0,0,0,17.5968,28Z" />
                        <rect
                          id="_Transparent_Rectangle_"
                          data-name="<Transparent Rectangle>"
                          className="cls-1"
                          width={32}
                          height={32}
                        />
                      </svg>
                    </button>
                    
                  </div>
                </Menu.Items>
              </Transition>
            )}
          </div>
        )}
      </Menu>
    </div>
  )
}

export default PromoCodesFilter;
