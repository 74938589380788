//call the proper api for each action

import { BASE_URL } from "../../../config/url";

export const callSupportApi = async (data: any) => {
  try {
    await fetch(`${BASE_URL}techSupport`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(data)
    });
  } catch (error) {
    console.log('callSupportApi error:', error)
  }
}