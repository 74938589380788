import { DATABASE } from "../../../config/firebase";
import { assignPaymentValueToObject } from "../types/order.payment";
import { assignValueToObject } from "../types/order.timeLine";

type Listeners = {
  onUpdate?: Function,
  onAdd?: Function,
  onGetAll?: Function
}

type fetchActiveOrdersProps = {
  listeners: Listeners
}


export const fetchFleetConfig = async () => {

  const query = await DATABASE.collection("config").doc("fleet_api").get();
  const data = await query.data(); 
  return {
    courierBaseUrl: data?.courierBaseUrl,
    courierApiKey: data?.courierApiKey,
    courierAuthKey: data?.courierAuthKey
  }

}

export const fetchBusinessConfig = async () => {

  const query = await DATABASE.collection("config").doc("business_config").get();
  const data = await query.data(); 
  return {
    maxCash: data?.maxCash,
    paciArcgis: data?.paciArcgis
  }

}

export const fetchCompensationConfig = async () => {

  const query = await DATABASE.collection("config").doc("compensation_reasons").get();
  const data = await query.data(); 
  return {
    compensation: data?.compensation,
    reasons: data?.reasons
  }

}

export const fetchCustomerTagsConfig = async () => {

  const query = await DATABASE.collection("config").doc("tags").get();
  const data = await query.data(); 
  return {
    positive: data?.cutomerTags.positiveTags,
    negative: data?.cutomerTags.negativeTags,
  }

}

export const fetchCancellationReasons = async () => {
  const query = await DATABASE.collection("config").doc("status_reason").get();
  const data = await query.data(); 
  return {
    courier: data?.courierCancelReasons,
    app: data?.mobileAppCancelReasons,
  }
}

export const fetchDelayReasons = async() => {
  const query = await DATABASE.collection("config").doc("delay_reasons").get();
  const data = await query.data()?.reasons; 
  return data;
}
export async function getStatuses() {
  try {
    const query = await DATABASE.collection("config").doc("order_status").get();
    return query?.data()?.status;
  } catch (error) {
    console.log("orderStatusColors", error);
  }
}

export function fetchOrderTimeline(id: string, { listeners } : fetchActiveOrdersProps) {
  return DATABASE.collection("orders")
  .doc(id)
  .collection("timeline")
  .orderBy("createdAt", "desc")
  .onSnapshot((snapshot) =>{
    snapshot.docChanges().forEach(function (change) {
      if (change.type === "added") {
        listeners && listeners.onAdd && listeners.onAdd();
      }
      if(change.type === "modified") {
        listeners && listeners.onUpdate && listeners.onUpdate({
          id: change.doc.id,
          data: change.doc.data()
        });
      }
    });
    listeners && listeners.onGetAll && listeners.onGetAll({
      orderTimeLine: snapshot.docs.map((q) => {        
        return assignValueToObject(q);
      })
    });
  });

  // return query.docs.map((q) => assignValueToObject(q));
}

export function fetchPayments(id: string, { listeners } : fetchActiveOrdersProps) {
  return DATABASE.collection("orders")
  .doc(id)
  .collection("payments")
  .where("status", "==", "SUCCESS")
  .orderBy("createdAt", "desc")
  .onSnapshot((snapshot) =>{
    snapshot.docChanges().forEach(function (change) {
      if (change.type === "added") {
        listeners && listeners.onAdd && listeners.onAdd();
      }
      if(change.type === "modified") {
        listeners && listeners.onUpdate && listeners.onUpdate({
          id: change.doc.id,
          data: change.doc.data()
        });
      }
    });
    listeners && listeners.onGetAll && listeners.onGetAll({
      orderPayments: snapshot.docs.map((q) => {        
        return assignPaymentValueToObject(q);
      })
    });
  });

  // return query.docs.map((q) => assignValueToObject(q));
}

export const fetchAddressData = async () => {
  const query = await DATABASE.collection('areas').doc('area_index').get();
  const data = await query.data();
  let areas = [];
  for (let i = 0; i < data?.areas.length; i++) {
    const area = data?.areas[i];  
    areas.push({
      id: area.id,
      name: area.name,
    });
  }
  areas.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
  return areas;
}

export const getchAreaDetailsById = async (id: string) => {
  const query = await DATABASE.collection('areas').doc(id).get();
  const data = query.data();
  return data;
}

export const getBlockAndStreets = async (areaId: number, blockName: string) => {
  const query = await DATABASE.collection('areas').where("id", "==", areaId).get();

  if(query.empty) return null;

  const blocks = query.docs[0]?.data()?.blocks;
  const blockDetails = query.docs[0]?.data()?.blocks.find((b: any) => b.name_en === blockName);
  
  return {
    blocks: blocks,
    blockDetails: blockDetails
  };
}

export const getLatLng = async (area_id: number, block_id: number, street_id: number) => {
  const query = await DATABASE.collection('areas').where("id", "==", area_id).get();
  if(query.empty) return null;

  const block = query.docs[0]?.data()?.blocks.find((b: any) => b.id === block_id);
  if(!block) return null;

  const street = block.streets.find((s: any) => s.id === street_id);
  console.log({
    lat: street?.latitude,
    lng: street?.longitude
  })
  return {
    lat: street.latitude,
    lng: street.longitude
  }
}