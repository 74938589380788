import { MutableRefObject, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import Button from "../../../../../components/Button/Button";
import Input from "../../../../../components/Input/input";
import { ORDER_STORE_NAME } from "../../../../../contants/stores";
import { useAppSelector } from "../../../../../redux";
import { calculateCartUpdates, updateCart, uploadItemImage } from "../../../actions/order.actions";
import OrderCartItem from "./orderCartItem";

export default function EditOrder(props: any) {
  const [items, setItems] = useState(props?.orderInfo?.items.length ? [...props?.orderInfo?.items] : []);
  const formSubmitButtonRef = useRef() as MutableRefObject<{ click: Function }>;
  const maxCash = useAppSelector(state => state[ORDER_STORE_NAME].businessConfig?.maxCash);
  const [cashToCollect, setCashToCollect] = useState(0);


function handleChange(e: any, idx: number) {
    const { name, value, type } = e.target;
    const _items = [...items];
    const item = { ..._items[idx] };
    _items.splice(idx, 1, { ...item, [name]: type === 'number' ? +value : value });
    setItems(_items);
  }

  function addCartItem() {
    const item: any = {
      id: `m-${Math.random()} - ${new Date().toISOString()}`,
      imageUrl: null,
      name: null,
      price: 0,
      status: null,
      quantity: 1,
      description: null,
      isCancelled: false,
    };
    setItems([...items, item]);
  }

  function removeCartItem(itemId: string) {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId
          ? { ...item, isCancelled: true }
          : item
      )
    );
    console.log("removeCartItem: ", items)
  }
  

  async function saveChanges() {
    const updatedOrder = await calculateCartUpdates(props?.id, items);
    const cashToCollect = updatedOrder.cashToCollect;
    setCashToCollect(cashToCollect);
    const orderValidation = {
      ...props?.orderValidation,
      cartValidate: {
        ...props?.orderValidation.cartValidate,
        updated: true
      }
    }
    await updateCart(props?.id, props?.orderNo, items, updatedOrder, props?.fleetId, orderValidation);
  }

  return (
    <>
      <div className="-mx-2 my-4 px-2 py-3 bg-blue-light/20 rounded-lg">
        <div className=" text-black p-2 text-sm">
          <div className="flex space-x-2 border-b pb-3 border-gray-300 justify-between items-center mb-3">
            <h4 className="font-bold text-xl">Cart Items</h4>
            {props?.orderType !== 'SELLER' && props?.primaryStatus !== 'OTHERS' ? 
              <button onClick={() => addCartItem()} type="button" className="pl-5 pr-2 flex justify-between items-center bg-blue-600 text-white font-medium text-xs leading-normal uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
              <span>ADD ITEM</span>
              <svg width={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 7.5C12.4142 7.5 12.75 7.83579 12.75 8.25V11.25H15.75C16.1642 11.25 16.5 11.5858 16.5 12C16.5 12.4142 16.1642 12.75 15.75 12.75H12.75V15.75C12.75 16.1642 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1642 11.25 15.75V12.75L8.25 12.75C7.83579 12.75 7.5 12.4142 7.5 12C7.5 11.5858 7.83579 11.25 8.25 11.25L11.25 11.25V8.25C11.25 7.83579 11.5858 7.5 12 7.5Z" fill="#fff" />
              </svg>
            </button>
            : <></>}
          </div>
          <form onSubmit={(e: any) => {
            e.preventDefault();
            formSubmitButtonRef?.current?.click();
          }} className="flow-root">
            <ul className="">
              {items?.map((item: any, idx: number) => (
                <OrderCartItem
                  length={items.length}
                  primaryStatus={props?.primaryStatus}
                  orderType={props?.orderType}
                  item={item}
                  key={idx}
                  idx={idx}
                  orderId={props.id}
                  onRemove={() => { removeCartItem(item.id) }}
                  onChange={(e: any) => handleChange(e, idx)}
                />
              ))}
            </ul>
            { cashToCollect > maxCash ? 
              <div className="text-xs text-red-500">
                **Max cash value exceeded {maxCash} KD.** <br></br>
                Please contact customer and make sure the payment is done by the customer.
              </div>
              : <></>
            }
            {props?.primaryStatus !== 'OTHERS' ? 
              <div className="flex items-center justify-end">
                <Button
                  ref={formSubmitButtonRef}
                  type="submit"
                  notify={false}
                  id="editCart"
                  cb={saveChanges}
                  text="Save"
                  loadingText="Saving..."
                  successMsg="Changes saved successfully"
                />
              </div> : 
            <></>}
          </form>
        </div>
      </div>
    </>
  )
}
