import React, { useEffect, useState } from 'react';
import '../actions/agent.actions'
import { AgentsList } from './agentsList';
import AgentsStatusDialog from './agentsStatusChangeDialog';
import { deleteAgent, getAgentsData } from '../actions/agent.actions';
import AgentSearch from './agentsSearch';
import { AGENT_STORE_NAME, AUTH_STORE_NAME } from '../../../contants/stores';
import { useAppSelector } from '../../../redux';

const Agents = () => {
  const agents = useAppSelector(state => state[AGENT_STORE_NAME].agents);
  const [agent, setSelectedAgent] = useState(undefined);
  const [action, setAction] = useState('Add');
  const loggedInAgent = useAppSelector((state) => state[AUTH_STORE_NAME].loggedInAgent);

  useEffect(() => {
    getAgentsData();
  }, []);
  
  return (
    <div className="shadow-md sm:rounded-lg flex flex-col items-center py-4 bg-white m-4 p-4">
      <div className="w-full flex justify-between">
        <AgentSearch/>
        {loggedInAgent.isSuperAdmin && <button className="h-10 pl-5 pr-2 flex justify-between items-center bg-blue-600 text-white font-medium text-xs leading-normal uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out" onClick={() => {
          setSelectedAgent({
            name: '',
            email: '',
          } as any);
          setAction('Add');
        }}>
          <span>Add New Agent</span>
          <svg width={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 7.5C12.4142 7.5 12.75 7.83579 12.75 8.25V11.25H15.75C16.1642 11.25 16.5 11.5858 16.5 12C16.5 12.4142 16.1642 12.75 15.75 12.75H12.75V15.75C12.75 16.1642 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1642 11.25 15.75V12.75L8.25 12.75C7.83579 12.75 7.5 12.4142 7.5 12C7.5 11.5858 7.83579 11.25 8.25 11.25L11.25 11.25V8.25C11.25 7.83579 11.5858 7.5 12 7.5Z" fill="#fff" />
          </svg>
        </button>}
      </div>
      <AgentsList
        agents={agents}
        onPressItem={async (agent: any, formAction: string) => {
          if (formAction === 'EDIT') {
            setAction('Edit');
            setSelectedAgent(agent);
          } else {
            await deleteAgent(agent.id);
          }
        }}
      />
      {agent ? <AgentsStatusDialog action={action} agent={agent} setSelectedAgent={setSelectedAgent} onClose={() => setSelectedAgent(undefined)} /> : null}
    </div>
  );
}

export default Agents;

