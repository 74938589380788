import { useState, useRef, MouseEventHandler, useEffect } from 'react';
import { ORDER_STORE_NAME } from '../../../../../contants/stores';
import { useAppSelector } from '../../../../../redux';
import { formatDate } from '../../../../../utils/helpers';
import { useIntersection } from '../../../services/observer';
import DeliveryTimer from './deliveryTimer';
import StatusTimer from './statusTimer';


export default function OrdersListItem(props: any) {
  const selectedOrderId = useAppSelector(state => state[ORDER_STORE_NAME].selectedOrderId);
  const [isInView, setIsInView] = useState(false);
  const divRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useIntersection(divRef, () => {
    setIsInView(true);
  });

  const date = `${formatDate(props.order.placedAt)}`;
  const pickupAddress = `${props.order.pickupAddress.area || (props.order.deliveryAddress.address && props.order.deliveryAddress.address.split(",")[0]) || props.order.pickupAddress.addressStr}`;
  const deliveryAdress = `${props.order.deliveryAddress.area || (props.order.deliveryAddress.address && props.order.deliveryAddress.address.split(",")[0]) || props.order.deliveryAddress.addressStr}`;
  const address = `${pickupAddress} to ${deliveryAdress}`;

  return (
    <>
      <div
        className={`min-h-[8rem] rounded-lg ${props.order.priorityStatus?.length ? "border-red-300 border-2" : "border-gray-300 border"}  px-3 py-2 m-3 ${selectedOrderId === props.order.id ? "bg-indigo-500 border-indigo-500" : "bg-white"} transform hover:translate-y-1 hover:shadow-xl transition duration-500`}
        ref={divRef}
        onClick={props.onPress}
      >
        {isInView && (
          <div className={`text-${selectedOrderId === props.order.id ? "white" : "black"} leading-none`}>
            <div className="">
              <div className="card cursor-pointer">
                <div className='flex justify-between items-center mb-2 '>
                  <div className="flex">
                    {props?.order?.orderValidation && (props?.order?.orderValidation.cart && props?.order?.orderValidation.pickup && props?.order?.orderValidation.delivery) &&
                      <svg className="w-4 h-4 mr-2 text-mashkorGreen" fill="none" stroke="currentColor" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>}
                    <p className="font-bold">{props.order.orderNo}</p>
                  </div>
                  <div className="flex">
                    {props.orderStatus.maxTimeDelayFromPreviousStatus > 0 ?
                      <StatusTimer timestamps={props.timestamps} pickToDropTime={props.pickToDropTime} orderStatus={props.orderStatus} /> :
                      <></>}
                    <DeliveryTimer orderNo={props.order?.orderNo} deliveryAt={props.order?.deliveryAt} />
                  </div>
                </div>
                <div className="flex justify-between items-center my-2">
                  <div className="flex">
                    <svg className={`w-3.5 h-3.5 ${selectedOrderId === props.order.id ? 'text-white' : 'text-gray-500'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd"></path></svg>
                    <p className="text-sm pl-2 truncate" title={date}>
                      <span style={{ marginRight: "4px" }}>
                        {date}
                      </span>
                    </p>
                  </div>
                  <div className="text-blue-900">
                    {props.order?.orderInfo?.isBulk ? <p className={`text-sm ${selectedOrderId === props.order.id ? 'text-white' : ""}`}>Bulk</p> : <></>}
                    {props.order?.orderInfo?.isGrouped ? <p className={`text-sm ${selectedOrderId === props.order.id ? 'text-white' : ""}`}>Grouped</p> : <></>}
                  </div>
                  {props?.order?.priorityStatus?.find((p: any) => p.tag === "FTO") && <p className="text-sm font-bold bg-amber-500 text-white rounded-lg px-2 py-1">
                    {props?.order?.priorityStatus[0].tag}</p>}
                  {props?.order?.tripInfo && <p className="text-sm font-bold bg-lime-500 text-white rounded-lg px-2 py-1">
                    {props?.order?.tripInfo.type}</p>}
                </div>
                <div className="flex items-center my-2">
                  <svg className={`w-4 h-4 ${selectedOrderId === props.order.id ? 'text-white' : 'text-gray-500'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path></svg>
                  <p className='flex-1 text-sm pl-2 truncate' title={address}>
                    {address}
                  </p>
                </div>
                <div className="flex justify-between items-center gap-2 font-bold">
                  <p className="text-sm bg-gray-300 text-white rounded-lg px-2 py-1">{props.order.orderType}</p>
                  {props.order.slotId ? <p className="text-sm bg-blue-200 text-white rounded-lg px-2 py-1">Scheduled</p>
                    : props.order.tag ? <p className="text-sm bg-blue-500 text-white rounded-lg px-2 py-1">{props.order.tag}</p> : <></>}
                  <span style={{ "backgroundColor": `${props.orderStatus.color}` }}
                    className="text-white text-sm inline rounded-lg px-2 py-1 align-top float-right truncate">{props.order.subStatus}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}