import { FormEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../redux";
import { AUTH_STORE_NAME, SETTINGS_STORE_NAME } from "../../../../contants/stores";
import Button from "../../../../components/Button/Button";
import Select from "../../../../components/Select/select";
import Input from "../../../../components/Input/input";
import { addCourierBusyMode, deactivateCourierBusyMode } from "../../services/settings.services";
import { BusyModeTimer } from "./busyModeDuration";
import { getCourierBusyModeConfig } from "../../actions/settings.actions";

export default function CourierBusyMode(props: any) {
  const submitButtonRefBusyMode = useRef() as MutableRefObject<{ click: Function }>;
  const config = useAppSelector(state => state[SETTINGS_STORE_NAME].businessConfig);
  const fleetConfig = useAppSelector(state => state[SETTINGS_STORE_NAME].fleetConfig);
  const status = useAppSelector(state => state[SETTINGS_STORE_NAME].courierBusyModeStatus);
  const types = useAppSelector(state => state[SETTINGS_STORE_NAME].courierBusyModeTypes);
  const agent = useAppSelector(state => state[AUTH_STORE_NAME].loggedInAgent);

  const [isBusyModeActive, setIsBusyModeActive] = useState(false);
  const [duration, setDuration] = useState(1);
  const [reason, setReason] = useState('');
  const [clarification, setClarificaton] = useState('');


  useEffect(() => {
    if (status && status.active) {
      setIsBusyModeActive(true);
      setReason(status.mode_type)
    } else {
      setIsBusyModeActive(false);
    }
  }, [status]);

  const handleSubmit = async () => {
    const busyModeObj = {
      "duration": +duration,
      "type_id": types?.find((type: any) => type.mode_type === reason)?.id,
      "reason": reason,
      "activated_by": agent.name,
      "clarification": clarification
    }
    if(!isBusyModeActive) {
      await addCourierBusyMode(fleetConfig, busyModeObj)
    } else {
      await deactivateCourierBusyMode(fleetConfig, status.id)
    }
    await getCourierBusyModeConfig();

  }

  const getTimeDifference = (activation: string, deactivation: string): number => {
    const start = new Date(activation);
    const end = new Date(deactivation);
    const diffTime = Math.abs(end.getTime() - start.getTime());
    const minutes = Math.floor(diffTime / 60000);
    return minutes;
  };


  return (
    <>
      {!config.showCourierSettings ?
        <div className="my-2 mb-4">
          <p className="font-bold">
            Courier Dashboard busy mode configuration
          </p>
          <p className="mt-1 text-sm">
            Counfiguration is not available
          </p>
        </div>

        : <form onSubmit={(e: FormEvent) => {
          e.preventDefault();
          e.stopPropagation()
          submitButtonRefBusyMode?.current?.click();
        }} className="text-sm p-2">
          <div className="my-2 mb-4">
            <p className="font-bold">
              Courier Dashboard business configuration
            </p>
            <p className="mt-1 text-sm">
              Update courier busy mode configuration
            </p>
          </div>
          <div className="py-2 border-t border-gray-300">
            <p className="font-semibold my-2">
              Busy mode status
            </p>
            <label className="flex items-center gap-2"
            >
              <span className="font-normal text-gray-900">
                {`${isBusyModeActive ? 'Deactivate' : 'Activate'} busy mode`}
              </span>
            </label>
          </div>
          {isBusyModeActive ?
            <>
              <div className="flex justify-between -mx-2 text-black px-2 py-2 my-2 border-t border-gray-300">
                <div className="flex flex-col gap-2 mt-2">
                  <div className="flex items-center"><p className="w-32">{status.activated_at === status.warned_at ? 'Activated at' : 'Time to Activate'}</p>:
                    <span className="font-bold ml-2"> {status.activated_at === status.warned_at ? new Date(status.activated_at).toLocaleString() : <BusyModeTimer activatedAt={Date.parse(status.activated_at)} type='courier' />}</span></div>
                  <div className="flex items-center"><p className="w-32">Deactivation scheduled at</p>:<span className="font-bold ml-2"> {new Date(status.expired_at).toLocaleString()}</span></div>
                  <div className="flex items-center"><p className="w-32">Duration</p>:<span className="font-bold ml-2"> {getTimeDifference(status?.activated_at, status?.expired_at)} min</span></div>
                  <div className="flex items-center"><p className="w-32">Reason</p>:<span className="font-bold ml-2"> {status.mode_type}</span></div>
                </div>
              </div>
            </> :
            <div className="py-2 border-t border-gray-300">
              <p className="font-semibold my-2">
                Details
              </p>
              <div className="flex items-center gap-3 my-2">
                <p className="mr-2 w-20">Reason</p>
                <div className="flex items-center">
                  <Select rules={{
                    required: isBusyModeActive ? false : true,
                    disabled: isBusyModeActive ? true : false
                  }} className="h-9 w-64" value={reason}
                    id="status" onChange={(e: any) => setReason(e.target.value)} name="">
                    <option value={status?.reason ?? ''} disabled hidden>{status?.reason ?? 'Choose type'}</option>
                    {types && types?.map((t: any) => (
                      <option key={t.id} value={t.mode_type}>{t.mode_type}</option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex items-center gap-3 my-2">
                <p className="mr-2 w-20">Duration</p>
                <div className="flex items-center w-52">
                  <Input
                    onKeyDown={(e: any) => e.key === 'Enter' && e.preventDefault()}
                    className="h-9 w-64" type="number" value={1}
                    onChange={(e: any) => {
                      setDuration(e.target.value)
                    }}
                    rules={{
                      required: isBusyModeActive ? false : true,
                      min: 1,
                      max: 1000,
                      step: "any",
                      disabled: isBusyModeActive ? true : false
                    }}
                  />
                </div>
              </div>
              <div className="flex items-center gap-3 my-2">
                <p className="mr-2 w-20">Clarification</p>
                <div className="flex items-center w-52">
                  <Input className="h-9 w-64" type="text" value=''
                    onKeyDown={(e: any) => e.key === 'Enter' && e.preventDefault()}
                    onChange={(e: any) => {
                      setClarificaton(e.target.value)
                    }}
                    rules={{
                      required: false,
                      min: 20,
                      max: 100
                    }}
                  />
                </div>
              </div>
            </div>}
          <div className="py-2 border-t border-gray-300">
            <div className="w-fit float-right">
              <Button
                ref={submitButtonRefBusyMode}
                type="submit"
                notify={false}
                id="busyMode"
                cb={handleSubmit}
                text={isBusyModeActive ? 'Deactivate' : 'Activate'}
                loadingText="Saving..."
                successMsg="Changes successfully"
                errorMsg="Failed to update busy mode status"
              />
            </div>

          </div>

        </form>}
    </>
  )
}