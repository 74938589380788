export interface PromoCode {
  id: string;
  title: string;
  header: string;
  headerAr: string;
  description: string;
  descriptionAr: string;
  createdAt: any;
  expiresAt: any;
  usage: any;
  user: any;
  discount: any;
  orderType: string;
  time: any;
  toPreload: boolean;
  isListed: boolean;
  isPromoCodeActive: boolean;
  isNewUserCode: boolean;
  isCompensationCode: boolean
}

export function assignValueToObject(query: any) {
  return {
    id: query.id,
    createdAt: query.data().createdAt.seconds * 1000,
    expiresAt: query.data().expiresAt.seconds * 1000,
    title: query.data().title,
    header: query.data().header,
    headerAr: query.data().headerAr,
    description: query.data().description,
    descriptionAr: query.data().descriptionAr,
    discount: query.data().discount,
    orderType: query.data().orderType,
    time: query.data().time,
    toPreload: query.data().toPreload ?? true,
    isListed: query.data().isListed,
    isPromoCodeActive: query.data().isPromoCodeActive,
    isNewUserCode: query.data().isNewUserCode,
    isCompensationCode: query.data().isCompensationCode,
    user: query.data().user,
    usage: query.data().usage
  };
}