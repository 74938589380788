import React, { useEffect, useState } from 'react';
import { deletePromoCode, getPromoCodes, updateQuery } from '../actions/propmoCode.actions';
import { PromoCodesList } from './promoCodesList';
import PromoCodeDialog from './promoCodeDialog';
import PromoCodeSearch from './promoCodesSearch';
import { useAppSelector } from '../../../redux';
import { PROMOCODE_STORE_NAME } from '../../../contants/stores';
import { useLocation } from "react-router-dom";
import PromoCodesFilter from './promoCodesFilter';

const PromoCodes = () => {
  const location = useLocation();
  const promoCodesInitial = useAppSelector(state => state[PROMOCODE_STORE_NAME].promoCodes);
  const appliedFilters = useAppSelector(state => state[PROMOCODE_STORE_NAME].filters);
  const [code, setSelectedPromoCode] = useState(undefined);
  const [action, setAction] = useState('Add');
  const [titles, setTitles] = useState([]);

  function isOrderInFilter(promo: any) {
    const checkOrderType = appliedFilters.orderType.options.some((t: any) => t.checked) ? appliedFilters.orderType.options.map((ot: any) => ot.checked && ot.path.toUpperCase()).includes(promo.orderType) : true;
    const checkDiscountType = appliedFilters.discountType.options.some((t: any) => t.checked) ? appliedFilters.discountType.options.map((t: any) => t.checked && t.name.toUpperCase()).includes(promo.discount.on) : true;
    const isListed = appliedFilters.codeInfo.options[3].checked ? promo.isListed === true : true;
    const isCompensation = appliedFilters.codeInfo.options[2].checked ? promo.isCompensationCode === true : true;
    const isFTO =  appliedFilters.codeInfo.options[0].checked ? promo.isNewUserCode === true : true;
    const toPreload = appliedFilters.codeInfo.options[1].checked ? promo.toPreload === true : true;


    return checkOrderType && checkDiscountType && isListed && isCompensation && isFTO && toPreload;
  }

  let promoCodes = promoCodesInitial.filter((promo: any) => isOrderInFilter(promo));

  console.log(promoCodes)

  useEffect(() => {
    getPromoCodes();
  }, []);

  useEffect(() => {
    let ts = promoCodes?.map((code:any) => {return code.title.toUpperCase()});
    setTitles(ts);
  }, [promoCodesInitial]);

  useEffect(() => {
    // Clear search input when location changes
    updateQuery('');
  }, [location.pathname]);


  const deleteCode = async (id: string, title: string) => {
    let isSure = window.confirm(`Are you sure you want to delete this promoCode ${title}?`)
    if (isSure) {
      await deletePromoCode(id);
    }
  }

  return (
    <div className="shadow-md sm:rounded-lg flex flex-col items-center py-4 bg-white m-4 p-4">
      <div className="w-full flex justify-between">
        <PromoCodeSearch/>
        <PromoCodesFilter/>
        <button className="h-10 pl-5 pr-2 flex justify-between items-center bg-blue-600 text-white font-medium text-xs leading-normal uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out" onClick={() => {
          setSelectedPromoCode({
            id: null,
            title: '',
            header: '',
            headerAr: '',
            description: '',
            descriptionAr: '',
            isPromoCodeActive: true,
            isListed: true,
            isCompensationCode: false,
            isNewUserCode: false,
            createdAt: new Date(),
            expiresAt: new Date().toISOString().split('T')[0],
            orderType: null,
            discount: {
              on: null,
              type: null,
              value: null,
            },
            user: {
              type: null,
              reference: '',
            },
            time: {
              from: '',
              to: ''
            },
            usage: {
              code: {
                maxUsage: 1,
                dailyUsage: 1,
                totalUsage: null,
                recentDayUsage: null,
                recentUsageAt: null
              },
              user: {
                maxUsage: 1,
                monthlyLimit: 1000,
                dailyUsage: 1,
              }
            }
          } as any);
          setAction('Add');
        }}>
          <span>Add New Promo Code</span>
          <svg width={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 7.5C12.4142 7.5 12.75 7.83579 12.75 8.25V11.25H15.75C16.1642 11.25 16.5 11.5858 16.5 12C16.5 12.4142 16.1642 12.75 15.75 12.75H12.75V15.75C12.75 16.1642 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1642 11.25 15.75V12.75L8.25 12.75C7.83579 12.75 7.5 12.4142 7.5 12C7.5 11.5858 7.83579 11.25 8.25 11.25L11.25 11.25V8.25C11.25 7.83579 11.5858 7.5 12 7.5Z" fill="#fff" />
          </svg>
        </button>
      </div>
      <PromoCodesList
        promoCodes={promoCodes}
        onPressItem={async (code: any, formAction: string) => {
          // console.log(code)
          if (formAction === 'EDIT') {
            setAction('Edit')
            setSelectedPromoCode({ ...code, expiresAt: new Date(code.expiresAt).toISOString().split('T')[0] })
          } else {
            await deleteCode(code.id, code.title);
          }

        }}
      />
      {code ? <PromoCodeDialog titles={titles} code={code} action={action} onClose={() => setSelectedPromoCode(undefined)} /> : null}
    </div>
  );
}

export default PromoCodes;

