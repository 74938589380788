import { useState, useEffect } from "react";

export function BusyModeTimer(props: any) {

  const [busyModeTimer, setBusyModeTimer] = useState('');

  function convertIntoSeconds(date: any) {
    return date.getTime();
  }

  useEffect(() => {
    const i = setInterval(deliveryTime, 1000);
    return () => clearInterval(i);
  }, [props.activatedAt]);

  function deliveryTime() {
    let timer = convertIntoSeconds(new Date(props.activatedAt)) - convertIntoSeconds(new Date());

      if(props.type === 'courier') {
        timer -= 1000;
      }
      else {
        timer *= -1;
        timer += 1000
      };
      
      const currentTimer = new Date(timer);

      const hour = currentTimer.getUTCHours();
      const min = currentTimer.getUTCMinutes();
      const sec = currentTimer.getUTCSeconds();
      setBusyModeTimer(`${hour}: ${min}: ${sec}`);
  }
  
  return(
    <>
    <div
        className=""
      >
        {busyModeTimer}
      </div>
    </>
  )
}

export function BusyModeDuration(props: any){
  const calculatDuration = (timestamp2: number, timestamp1: number) => {
    const date2 = new Date(timestamp2);
    const date1 = new Date(timestamp1);
    const hours = Math.floor(Math.abs(date1.getTime() - date2.getTime()) / (1000 * 60 * 60) % 24);
    const minutes = Math.floor(Math.abs(date1.getTime() - date2.getTime()) / (1000 * 60) % 60);
    const seconds = Math.floor(Math.abs(date1.getTime() - date2.getTime()) / 1000 % 60);
  
    return `${hours} : ${minutes} : ${seconds}`;
  };
  
  return(
    <>
    <div
        className=""
      >
        {calculatDuration(props.deactivatedAt, props.activatedAt)}
      </div>
    </>
  )
}