import { Agent } from "../types/agent.data";
import { useAppSelector, useAppDispatch } from '../../../redux'
import { AGENT_STORE_NAME, AUTH_STORE_NAME } from "../../../contants/stores";
import { formatDate } from "../../../utils/helpers";
import { search, syncAgentCount } from "../actions/agent.actions";
import Table from "../../../components/Table/Table";
import { useEffect, useState } from "react";

export const AgentsList = (props: any) => {
  const loggedInAgent = useAppSelector((state) => state[AUTH_STORE_NAME].loggedInAgent);
  const query = useAppSelector(state => state[AGENT_STORE_NAME].searchQuery);
  const headers = ["Name", "Role", "Active orders", "Last login", "Status", "Action"];
  const [agents, setAgents] = useState(props.agents);

  useEffect(() => {
    updateSearchList()
  }, [props.agents, query])


  const updateSearchList = () => {
    if (!query.length) {
      setAgents(props.agents);
    } else {
      setAgents(search(query));
    }
  }
  
  return (
    <Table headers={headers}>
      {agents && agents.map((agent: Agent) => (
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={agent.id}>
          <th scope="row" className="flex items-center py-4 px-6 text-gray-900 whitespace-nowrap dark:text-white">
            <div>
              <div className="text-base font-semibold">{agent.name}</div>
              <div className="font-normal text-gray-500">{agent.email}</div>
            </div>
          </th>
          <td className="py-4 px-6">
            {agent.isSuperAdmin ? 'Tech admin' : agent.isAdmin ? 'OM admin' : 'Agent'}
          </td>
          <td className="flex items-center gap-2 py-4 px-6">
            <div onClick={() => syncAgentCount(agent.id)}
              className="inline-flex justify-center rounded-md cursor-pointer bg-white p-2 text-sm font-medium text-gray-900 hover:text-opacity-50 hover:bg-indigo-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              <svg
                className="h-4 w-4"
                fill="#000000"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
              >
                <path d="M19.91,15.51H15.38a1,1,0,0,0,0,2h2.4A8,8,0,0,1,4,12a1,1,0,0,0-2,0,10,10,0,0,0,16.88,7.23V21a1,1,0,0,0,2,0V16.5A1,1,0,0,0,19.91,15.51ZM12,2A10,10,0,0,0,5.12,4.77V3a1,1,0,0,0-2,0V7.5a1,1,0,0,0,1,1h4.5a1,1,0,0,0,0-2H6.22A8,8,0,0,1,20,12a1,1,0,0,0,2,0A10,10,0,0,0,12,2Z" />
              </svg>
            </div>

            {(agent.activeGroupOrders ?? 0) + (agent.activeOrders ?? 0)}
          </td>
          <td className="py-4 px-6">
            {formatDate(agent?.lastLogin)}
          </td>
          <td className="py-4 px-6">
            <div className="flex items-center">
              <div className={`h-2.5 w-2.5 rounded-full bg-${agent.status === "ONLINE" ? "green-400" : agent.status === "OFFLINE" ? "red-500" : "yellow-400"} mr-2`}></div> {agent.status}
            </div>
          </td>
          <td className="flex gap-2 py-4 px-6">
            <button
              onClick={() => props.onPressItem(agent, 'EDIT')}
              data-modal-toggle="editUserModal" className="font-medium text-blue-600 hover:underline">Edit</button>
            {loggedInAgent.isSuperAdmin && <button
              onClick={() => props.onPressItem(agent, 'DELETE')}
              data-modal-toggle="editUserModal" className="font-medium text-red-600 hover:underline">Delete</button>}
          </td>
        </tr>
      ))}
    </Table>
  );
}