export default function OrderInvoiceModel(props: any) {
  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative m-auto w-[300px]">
          <button onClick={() => props.setInvoice('')} type="button" className="absolute z-50 top-3 right-2.5 bg-gray-200 text-gray-900 hover:bg-red-400 hover:text-gray-50  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-hide="popup-modal">
            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="rounded-lg shadow-lg relative flex flex-col w-full h-full  min-h-[300px] bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5">
              <img className="rounded-lg" src={props.image} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}