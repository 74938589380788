//getAgents

//changeAgentStatus

//updateAgentsActiveOrdersCount

import { DATABASE, FUNCTION } from "../../../config/firebase";
import { BASE_URL } from "../../../config/url";
import { Agent, assignValueToObject } from "../types/agent.data";

type Listeners = {
  onUpdate?: Function,
  onAdd?: Function,
  onGetAll?: Function
}

type fetchActiveOrdersProps = {
  listeners: Listeners
}

export const fetchAgentData = (id: string, { listeners } : fetchActiveOrdersProps) => {
  try {
    return DATABASE.collection("agents").doc(id).onSnapshot((snapshot: any) => {

      listeners && listeners.onUpdate && listeners.onUpdate(assignValueToObject(snapshot));

    });

  } catch (error) {
    console.log("error while fethcimg agent data: ", error);
  }
};

export const callCreateAgent = async (agent: any) => {
  try {
    const callableReturnMessage = FUNCTION.httpsCallable('createAgent');
    const result = await callableReturnMessage({agent});
    console.log("Create agent response: ", result.data);
    return result.data;
  } catch (error) {
    console.error(`error: ${JSON.stringify(error)}`);
    console.error("Failed to create agent");
    throw error;
  }
  
}

export const Delete = async (id: string) => {
  try {
    await DATABASE.collection("agents").doc(id).delete();
  } catch (error) {
    console.log("Agent delete error: ", error)
  }
}

export const fetchAgents = async ({ listeners } : fetchActiveOrdersProps) => {
  try {

    return DATABASE.collection("agents").onSnapshot((snapshot) => {
      snapshot.docChanges().forEach(function (change) {
        if (change.type === "added") {
          listeners && listeners.onAdd && listeners.onAdd();
        }
        if(change.type === "modified") {
          listeners && listeners.onUpdate && listeners.onUpdate({
            id: change.doc.id,
            data: change.doc.data()
          });
        }
      });
      listeners && listeners.onGetAll && listeners.onGetAll({
        agents: snapshot.docs.map((q) => {        
          return assignValueToObject(q);
        })
      });
    });
  } catch (error) {
    console.log(`An error was occured while logging in ${error}`);
  }
};

export const updateStatus = async (id: string, status: string) => {
  try {
    await DATABASE.collection("agents").doc(id).update({status});
  } catch (error) {
    console.log(`An error was occured while logging in ${error}`);
  }
}

export const reassignOnLogin = async (agent: Agent) => {
  try {
    console.log("reassignAgentOnLogin", agent)
    const { id, name, email, isAdmin } = agent!;
    const url = `${BASE_URL}reassignAgentOnLogin`;
    const requestBody = {
      id,
      name,
      email,
      isAdmin,
    };
    const request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(url, request);
    const responseJson = await response.json();
    console.log(`Reassign API response: ${responseJson}`);
  } catch (error) {
    console.log(`An error was occured while reassigning orders ${error}`);
  }
};

export const fetchOrderTransfer = async(currentAgent: any, replacementAgent: any, orders: any, action: string) => {

  try {
    const url = `${BASE_URL}transferOrders`;
    const requestBody = {
      currentAgent:{
        id: currentAgent?.id,
        name: currentAgent?.name,
        email: currentAgent?.email
      },
      replacementAgent: replacementAgent ? {
        id: replacementAgent?.id,
        name: replacementAgent?.name,
        email: replacementAgent?.email
      } : null,
      orders,
      action
    };
    const request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(url, request);
    const responseJson = await response.json();
    console.log(`Reassign API response: ${responseJson}`);
  } catch (error) {
    console.log(`An error was occured while reassigning orders ${error}`);
  }
}

export const updateAgent = async (id:string, data: any) =>{
  await DATABASE.collection("agents").doc(id).update(data);
}

export const getAgentCount = async (id: string) => {
  try {
    let indifidual = 0;
    let group = 0

    const query = await DATABASE.collection("orders").where("primaryStatus", "!=", "OTHERS").where("agent.id", "==", id).get();

    if(! query.empty) {
      indifidual = query.docs.filter(doc => ((!doc.data().orderInfo?.groupedOrderId || doc.data().orderInfo?.groupedOrderId?.length === 0))).length;
      group = query.docs.filter(doc => (doc.data().orderInfo?.groupedOrderId?.length > 0)).length; 
    }
    
    await DATABASE.collection("agents").doc(id).update({
      activeOrders: indifidual,
      activeGroupOrders: group
    });

    return {
      activeOrders: indifidual,
      activeGroupOrders: group
    }
  } catch (error) {
    console.log("sync error: ", error)
  }

}