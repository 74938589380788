import { ORDER_LOGS_STORE_NAME } from "../../../contants/stores";
import { store } from "../../../redux";
import { fetchOrders, update } from "../services/order-logs.services";
import { actions as logsActions } from "../stores/order-logs.store";

export const getBuyOrders = async() => {
  const lastOrder = store.getState()[ORDER_LOGS_STORE_NAME].lastOrder;
  const docs : any | undefined[] = await fetchOrders(lastOrder);  
  store.dispatch(logsActions.setOrders(docs));
  store.dispatch(logsActions.setLastOrder(docs[docs?.length - 1]?.placedAt));
  store.dispatch(logsActions.setHasMore((docs?.length || !(docs.length < 10)) ?? false));
}

export const updateLogsVerification = async (id: string) => {
  store.dispatch(logsActions.updateOrder({ id, buyChangesVerified: true }));
  await update(id);
}