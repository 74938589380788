import { DATABASE } from "../../../config/firebase";
import { assignValueToObject } from "../types/order-logs";
import { OrderLog } from "../types/order-logs";

export const fetchOrders = async (lastOrder: any) => {
  console.log('lastOrder', lastOrder)
  try {
    const arr = [];
    //the previous date
    const yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date);
    yesterday.setHours(0, 0, 0, 0);

    // query orders of the previous date

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const baseQuery = await DATABASE.collection('orders')
    .where('placedAt', '>=', yesterday)
    .where('placedAt', '<', today)
    .where('orderType', '==', 'BUY')
    .where('subStatus', '==', 'DELIVERED')
    .orderBy('placedAt')
    .limit(10)

    let docsArr = [];

    if(lastOrder) {
      const query = await baseQuery.startAfter(lastOrder).get();
      docsArr = query.docs
    } else {
      const query = await baseQuery.get();
      docsArr = query.docs
    }

    for (let index = 0; index < docsArr?.length; index++) {
      const order = docsArr[index];
      const orderQuery = await DATABASE.collection('orders')
      .doc(order.id).collection('change_logs').orderBy('updatedAt').get();
      
      const orderObj: { changeLogs: OrderLog[], orderId: string, orderNo: string, pickupImages: string[], buyChangesVerified: boolean, placedAt: any } = {
        changeLogs: [],
        orderId: order.id,
        placedAt: order.data().placedAt,
        orderNo: order.data().orderNo,
        pickupImages: order.data().orderInfo?.pickupImages,
        buyChangesVerified: order.data().buyChangesVerified ?? false
      }
      if(!orderQuery.empty) {
        orderQuery.docs.map((q) => orderObj?.changeLogs?.push(assignValueToObject(q)));
      }
      arr.push(orderObj)
    }
    
    return arr;
  } catch (error) {
    console.log("Error getting last day orders: ", error)
  }
}

export const update = async(id: string) => {
  await DATABASE.collection('orders').doc(id).update({buyChangesVerified: true})
}