export interface Customer {
  id?: string;
  name?: string;
  email?: string;
  phone?: string;
  isBlocked?: boolean;
  isNewHome?: boolean;
  orders?: object[];
  walletBalance?: number;
}



export function assignValueToObject(query: any) {
  return {
    id: query.id,
    ...query?.data()
  };
}
