import { useEffect, useState } from "react";
import toast, { useToasterStore } from "react-hot-toast"

import { forwardRef, useImperativeHandle, useRef } from 'react';

const Button = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    click() {
      notify();
    },
  }));

  const [status, setStatus]: [string | undefined, Function] = useState();
  const { toasts } = useToasterStore();

  const notify = () => {
    toast.promise(props.cb(), {
      loading: 'Loading',
      success: props.successMsg,
      error: props.errorMsg ?? 'Error'
    }, {
      id: props.id
    })
  }

  useEffect(() => {
    const currentToast = toasts.find(t => t.id === props.id);
    if (!currentToast) return setStatus()
    setStatus(currentToast.type)
  }, [toasts]);

  return (
    <button
      onClick={props.notify ? notify : () => { }}
      type={props.type || 'button'}
      disabled={status === 'loading'}
      className="rounded-md flex justify-center items-center w-full border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700">
      {status === 'loading' ? <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg> : <></>}
      {status === 'loading' ? props.loadingText : props.text}
    </button>
  )
});

export default Button;