import { FormEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../redux";
import { SETTINGS_STORE_NAME } from "../../../../contants/stores";
import Input from "../../../../components/Input/input";
import Button from "../../../../components/Button/Button";
import CreatableSelect from "react-select/creatable";
import { updateAppTaggingConfig } from "../../actions/settings.actions";

export default function AppTagging(props: any) {
  const submitButtonRefAppTagging = useRef() as MutableRefObject<{ click: Function }>;
  const appTagging = useAppSelector(state => state[SETTINGS_STORE_NAME].appTagging);
  const [inputValue, setInputValue] = useState("");
  const [excludedKeyWords, setExcludedKeyWords] : [any, Function] = useState([]);
  const [maxOrderAmount, setMaxOrderAmount] = useState(0);


  useEffect(() => {
    const keyWords = appTagging?.excludedPickups?.map((word: string) => ({
      value: word,
      label: word,
    }));
    setExcludedKeyWords(keyWords);
    setMaxOrderAmount(appTagging?.maxOrderAmount)
  }, []);

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  const handleOnChange = (value: string) => {
    // console.log(value)
    // setExcludedKeyWords([...excludedKeyWords, value]);
    setExcludedKeyWords(value);
  };

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  const handleKeyDown = (event: any) => {

    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setInputValue('');
        setExcludedKeyWords([...excludedKeyWords, createOption(inputValue)]);
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const handleSubmit = async() => {
    let arr: string[] = [];
    excludedKeyWords.forEach((element: any) => {
      arr.push(element.value);
    });
    await updateAppTaggingConfig(arr, maxOrderAmount)
  }

  return (
    <>
    {!appTagging.enableConfig ?
      <>
        <div className="my-2 mb-4">
          <p className="font-bold">
            Mashkor App tagging configuration
          </p>
          <p className="mt-1 text-sm">
            Counfiguration is not available
          </p>
        </div>
      </> :
      <form onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('submit')
        submitButtonRefAppTagging?.current?.click();
      }} className="text-sm p-2">
        <div className="my-2 mb-4">
          <p className="font-bold">
            Mashkor App tagging configuration
          </p>
          <p className="mt-1 text-sm">
            Update app easy buy (CB) configuration
          </p>
        </div>
        <div className="py-2 border-t border-gray-300">
          <div className="flex items-center gap-3">
            <p className="mr-2">Order maximum amount</p>
            <div className="flex items-center">
              <Input type="number" value={maxOrderAmount}
                onChange={(e: any) => {
                  setMaxOrderAmount(e.target.value);
                }}
                rules={{
                  required: true,
                  min: 0,
                  max: 9999999,
                  step: "any",
                }}
              />
            </div>
          </div>
        </div>
        <div className="py-2 border-t border-gray-300">
          <p className="font-semibold my-2">
            Excluded keywords
          </p>
          <CreatableSelect className="focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            // components={{
            //   DropdownIndicator: null,
            // }}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={(value: any) => handleOnChange(value)}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Type something and press tab..."
            value={excludedKeyWords} />
        </div>
        <div className="py-2 border-t border-gray-300">
          <div className="w-fit float-right">
            <Button
              ref={submitButtonRefAppTagging}
              type="submit"
              notify={false}
              id="appTagging"
              cb={handleSubmit}
              text="Save"
              loadingText="Saving..."
              successMsg="Changes successfully"
              errorMsg="Incorrect email or password"
            />
          </div>
        </div>
      </form>}
    </>
  )
}