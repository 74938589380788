import Select from "../../../../../../components/Select/select"
import { Agent } from "../../../../../agent/types/agent.data"

export function OrderTransferDialogContent(props: any) {
  return (
    <>
      <div className="flex justify-between items-center gap-3 my-2 py-2 mx-3">
        <div className="flex-1">
          {props?.agents?.length? 
            <Select
              rules={{
                required: true,
              }} className="h-9" value={props?.selectedAgent?.id}
              id="reason" onChange={(e: any) => {
                props.setSelectedAgent(props.agents.find((agent: Agent) => agent.id === e.target.value))
              }} name="Agent name">
              {props?.agents?.map((agent: any) => (
                  <option key={agent.id} value={agent.id}>
                    {agent.name} - Active orders : {agent.activeGroupOrders + agent.activeOrders}
                  </option>
              ))}
            </Select>:
            <div>No other active agent to transfer to.</div>}
        </div> 
      </div>
    </>
  )
}