import { AUTH_STORE_NAME, ORDER_STORE_NAME } from "../../../contants/stores";
import { store } from "../../../redux";
import { applyPromoCode, callOmsOrderCartUpdate, callOrderETAService, fetchActiveOrders, save, saveCart, saveAddressInCourier, callOmsOrderComplete, callUnAssignAPI, changeOrderStatus, changeOrderType, changePaymentInCourier, savePaymentDetails, sendNotifications, getBuddyDetails, callSearch, getOrdersByFleetId, callETAUpdate, serachPaciFromLoc, generateToken, updateToken, uploadFileToStorage, checkDelayNotificationValidity, reschedule } from "../services/order.services";
import { actions as orderActions, initialState } from "../stores/order.store";
import { TOrderItem } from "../types/order.item";
import { addAddress, customerSearch, updateAddressList, validateAddress } from "../../customer/services/customer.services";
import { orderSearch } from "../services/order.services";
import { assignValueToObject, Order } from "../types/order.data";
import { OrderInfo } from "../types/order.orderInfo";
import { triggerPubSubEventCloudFunction } from "../../../utils/pubsub";
import firebase from "firebase";
import { timelineNames, timelineTypes } from "../../../contants/timelineEvents";
import { searchCustomerByPhone } from "../../customer/actions/customer.actions";
import { createPromoCode } from "../../promocode/actions/propmoCode.actions";
import { fetchOrderTransfer } from "../../agent/services/agent.service";
import { Agent } from "../../agent/types/agent.data";

export const getOrdersData = () => {
  try {
    const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;
    const selectedOrderId = store.getState()[AUTH_STORE_NAME].selectedOrderId;
    const isAgent = !agent.isAdmin && !agent.isSuperAdmin;
    return fetchActiveOrders(agent.id, isAgent, {
      listeners: {
        onGetAll: (res: any) => {
          if (res.errors) {
            throw new Error("Could not fetch orders");
          }
          // if(res?.orders?.length) updateSelectedOrder(res?.orders[0]?.id ?? '');
          store.dispatch(orderActions.setOrders(res.orders));
        },
        onAdd: () => {},
        onUpdate: (res: any) => {
          store.dispatch(orderActions.updateOrder({ ...res }));
        },
      },
    });
  } catch (error) {
    console.log("get-orders", error);
  }
}

export const getBuddyActiveOrders = async (fleetId: number) => {
  const orders = await getOrdersByFleetId(fleetId);
  return orders?.map((doc: any) => { 
    return { orderNo: doc.data().orderNo, subStatus: doc.data().subStatus, id: doc.id, tripInfo: doc.data().tripInfo } 
  });
}

export const updateSelectedOrder = (id: string) => {
  store.dispatch(orderActions.setSelectedOrderId<any>(id));
}

export const updateSelectedSubStatuses = (subStatuses: any) => {
  store.dispatch(orderActions.setSelectedSubStatuses(subStatuses));
}

export const updateSelectedPrimaryStatus = (primary: { show: boolean, status: string }) => {
  store.dispatch(orderActions.setSelectedPrimaryStatus(primary));
}

export const updateOrderFilters = (filters: any []) => {
  store.dispatch(orderActions.setAppliedFilters(filters));
}

export const clearFilters = () => {
  store.dispatch(orderActions.setAppliedFilters(initialState.filters));
}

export const changeActiveMode = (activeMode: string) => {
  store.dispatch(orderActions.setActiveMode(activeMode));
}

export const updateOrderPriorityActions = async (orderId: string, orderNo: string, priorityStatus: any) => {
  await save(orderId, { 
    "priorityStatus": priorityStatus, 
  });
}

export const updateOrderInfoOnAddressUpdate = async (address: any, type: string, orderId: string, orderNo: string) => {
  let order = store.getState()[ORDER_STORE_NAME].orders.find((order: Order) => order.id === orderId);
  const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;

  if(!order) order = store.getState()[ORDER_STORE_NAME].searchOrder;
  
  order = type === "pickup" ? {...order, pickupAddress: address} : {...order, deliveryAddress: address};
  const items = order?.orderInfo?.items.map((item: any) => {
    return {
      ...item,
      total: +(item.quantity * item.price).toFixed(2),
    };
  });
  const itemCount = items.filter((item : any) => !item.isCancelled).length;
  const itemTotal = items.filter((item : any) => !item.isCancelled).reduce((acc: any, item: any) => acc + (item.price * item.quantity), 0);

  const ETAData = await calculateOrderETA(order, itemCount, itemTotal);
  const discountData = await calculateDiscount(order?.orderInfo, ETAData);
  await save(orderId, { 
    "orderInfo.discount": discountData, 
    "orderInfo.deliveryFee": ETAData.deliveryFee,
    "orderInfo.serviceFee": ETAData.serviceFee,
    "deliveryDistance": ETAData.distance,
    "etaSeconds": ETAData.etaSeconds,
    "etas.etPickupToDelivery": ETAData.etaSeconds,
  });
  callOmsOrderCartUpdate(orderNo, agent?.name);
  callETAUpdate(orderId, orderNo)
} 

export const saveAddress = async (orderId: string, orderNo: string, type: string, address: any, fleetId: any, orderType: string, userId: string, orderValidation: any, saveAddressId: string, activeMode: string) => {
  const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;
  if(userId && orderType !== 'COURIER' && activeMode === 'add') {//Update saved address
    const id = await addAddress(userId, address);
    updateAddressList(userId, {id: id})
    address = {...address, id: id}
  }

  save(orderId, type === "pickup" ? {pickupAddress: address, ...(orderType !== 'COURIER' ? { orderValidation: orderValidation } : {} )} : {deliveryAddress: address, ...(orderType !== 'COURIER' ? { orderValidation: orderValidation } : {} )});

  const comment = `${type === "pickup" ? "Pickup ": "Delivery "}addredd has been updated.`
  addTimeLineEvent(agent, orderNo, orderId, timelineTypes.ADDRESS, timelineNames.ADDRESS_UPDATE, comment);

  if(fleetId) { //notfy the buddy
    const msgTitle = `Update - ${orderNo}`;
    const msgBody = `${type === "pickup" ? "Pickup" : "Delivery"} address has been updated`;
    buddiesNotifications(msgTitle, msgBody, fleetId);
  }
  if(userId && orderType !== 'COURIER' && activeMode !== 'add') {//Update saved address
    updateAddressList(userId, address);
  }
}

export const updateAddressCourier = async(address: any, mode: string, type: string, orderNo: string, orderType: string, orderId: string) => {
  const fleetConfig = store.getState()[ORDER_STORE_NAME].fleetConfig;
  const payload = mode === 'map' ? {
    "address": address.addressStr,
    "latitude": address.location.latitude ,
    "longitude": address.location.longitude,
    'mobile_number': address.contactPhone,
    'customer_name': `${orderType !== "COURIER" ? (address.name ? address.name + "| ": " " + address.contactName) : address.contactName}`,
    "floor": address.floorNumber,
    "room_number": address.roomNumber,
    "landmark": address.landmark,
  } : {
    "address": address.addressStr,
    "addressName": address.name.trim(),
    "floor": address.floorNumber,
    "room_number": address.roomNumber,
    "area": address.area,
    "area_id": address.area_id,
    "areaDoc_id": address.areaDoc_id || null,
    "block": address.block,
    "block_id": address.block_id,
    "street": address.street,
    "street_id": address.street_id,
    "latitude": address.location.latitude ,
    "longitude": address.location.longitude,
    "landmark": address.landmark,
    'customer_name': `${orderType !== "COURIER" ? (address.name ? address.name + "| " : " " + address.contactName) : address.contactName}`,
    'mobile_number': address.contactPhone,
  };
  saveAddressInCourier(payload, orderNo, type, address, orderId, fleetConfig);
  
}

export const paciSearch = async(lat:number, lng:number) =>{
  try {
    const businessConfig = store.getState()[ORDER_STORE_NAME].businessConfig;
    let res: any = await serachPaciFromLoc(lat, lng, businessConfig.paciArcgis);
    if (res.error) {
      console.log("Paci location search error: ", res.error)
      //Generate token
      const token = await generateToken(businessConfig?.paciArcgis);
      updateToken(token);
      const config = {
        ...businessConfig,
        paciArcgis: {
          ...businessConfig.paciArcgis,
          token: token,
        },
      };
      console.log("token regenerated: ", config);
      store.dispatch(orderActions.setBusinessConfig(config));
      res = await serachPaciFromLoc(lat, lng, config.paciArcgis);
    }
    let results = res.results;
    const locDetials = {
      areaId: results[1].attributes.Nhood_No,
      areaName: results[1].attributes.neighborhoodenglish,
      blockName: results[0].attributes.blockenglish,
    }
    console.log(`Location details for the location: ${lat},${lng} is: ${JSON.stringify(locDetials)}`);
    return locDetials;
  } catch (error) {
    console.log("Error-paciSearch: ", error)
  }
}


let unsubRef: Promise<(() => void) | undefined>;

export const close = () => {
  store.dispatch(orderActions.setSearchOrder({}));
  store.dispatch(orderActions.setSearchQuery(""));
  stopListener(unsubRef)
}

const stopListener = (unsubRef: any) => {
  console.log("unsub")
  // unsubRef();
  return () => {
    if (unsubRef) unsubRef();
  }
};

export const search = async (opt: string, value: string) => {
  switch (opt) {
    case "CUSTOMER":
      return searchCustomerByPhone(value);
    case "ORDER":
      console.log("here")
      store.dispatch(orderActions.setSearchQuery(value));
      let order;
      orderSearch(value, {
        listeners: {
          onGetAll: (res: any) => {
            if (res.errors) {
              throw new Error("Could not fetch orders");
            }
            // if(res?.orders?.length) updateSelectedOrder(res?.orders[0]?.id ?? '');
            order = res.orders[0];
            console.log("onGetAll: ", order);
            store.dispatch(orderActions.setSearchOrder(res.orders[0]));
          },
          onUpdate: (res: any) => {
            // console.log({ ...res })
            store.dispatch(orderActions.setSearchOrder({ ...res }));
            order = { ...res };
          },
        },
      });
      console.log(order)
      store.dispatch(orderActions.setSearchOrder(order))
      console.log("order: ", order);
      return order;
    default:
      break;
  }
};

export const searchForCallSource = async (phone: string) => {

  try {
    //checking if the incoming call is from customer
    //then showing the latest order in the modal
    let query = await callSearch("user.phone", phone);
    
    //if call is not from customer then checking for buddy phone number
    if (query?.empty) {
      query = await callSearch("deliveryInfo.buddyPhone", phone);
    }

    //if call is not from customer, buddy and delivery address
    //then checking for pickup address contact number
    if (query?.empty) {
      query = await callSearch("deliveryAddress.contactPhone", phone);
    }
    if (query?.empty) {
      query = await callSearch("pickupAddress.contactPhone", phone);
    }

    if(query?.empty) {
      console.log("Call search not found");
      return;
    }

    console.log("Call search:", assignValueToObject(query?.docs[0]))
    store.dispatch(orderActions.setSearchOrder(assignValueToObject(query?.docs[0])));

  } catch (error) {
    console.log("Error searching for call source", error)
  } 
}
// export const removeItem = (orderId:string, itemId: string, items: TOrderItem[]) => {
//   const updatedCartItems = items.filter((el) => {
//     return el.id !== itemId;
//   });
// }

export const calculateCartUpdates = async(orderId:string, items: TOrderItem[]) =>{
  let order = store.getState()[ORDER_STORE_NAME].orders.find((order: Order) => order.id === orderId);
  if(!order) order = store.getState()[ORDER_STORE_NAME].searchOrder;
  const orderInfo = order?.orderInfo;
  items = items.map((item: any) => {
    return {
      ...item,
      total: +(item.quantity * item.price).toFixed(2),
    };
  });
  const itemCount = items.filter((item : any) => !item.isCancelled).length;
  const itemTotal = items.filter((item : any) => !item.isCancelled).reduce((acc, item: any) => acc + item.total, 0);

  const ETAData = await calculateOrderETA(order, itemCount, itemTotal);
  const discountData = await calculateDiscount(orderInfo, ETAData);
  const totalAmount =
    itemTotal + ETAData.serviceFee + 
    ETAData.deliveryFee +
    (orderInfo.buddyTip || 0) - 
    (discountData || 0);
  const cashToCollect = totalAmount - orderInfo.onlinePaid - orderInfo.walletPaid;
  return {
    orderNo :order.orderNo,
    items: items,
    itemCount: itemCount,
    itemTotal: itemTotal,
    ETAData: ETAData,
    discountData: discountData,
    cashToCollect: cashToCollect, 
  }
}

export const updateCart = async (orderId:string, orderNo: string, items: TOrderItem[], udatedDate: any, fleetId: any, orderValidation: any) => {
  const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;
  const itemCount = udatedDate.itemCount;
  const itemTotal = udatedDate.itemTotal;

  const ETAData = udatedDate.ETAData;
  const discountData = udatedDate.discountData;

  const comment = "Cart has been updated."


  
  await saveCart(orderId, items, ETAData, discountData, itemCount, itemTotal, orderValidation);
  callOmsOrderCartUpdate(udatedDate.orderNo, agent.name);
  callETAUpdate(orderId, orderNo);
  addTimeLineEvent(agent, orderNo, orderId, timelineTypes.CART, timelineNames.CART_UPDATE, comment);

  if(fleetId) {
    const msgTitle = `Update - ${orderNo}`;
    const msgBody = "Cart has been updated";
    
    buddiesNotifications(msgTitle, msgBody, fleetId);
  }
  
}

const calculateOrderETA = async (order: Order, itemCount: number, itemTotal: number ) => {
  
  const storeId = order.orderType === "BUY"
          ? order.pickupAddress.placeId != null
            ? order.pickupAddress.placeId
            : null
          : order.orderType == "SELLER"
            ? order.sellerInfo.sellerId
            : null;
  const props = {
    pickup: { 
      latitude: order.pickupAddress.location._lat,
      longitude: order.pickupAddress.location._long
    },
    delivery: { 
      latitude: order.deliveryAddress.location._lat,
      longitude: order.deliveryAddress.location._long
    },
    orderType: order.orderType,
    storeId: storeId,
    itemCount: itemCount,
    itemTotal: itemTotal
  };

  return await callOrderETAService(props);
  
}

const calculateDiscount = async(orderInfo: OrderInfo, ETAData: any) => {
  let discount = 0 ;
  if(orderInfo?.promoCode) {
    const body = {
      "title": orderInfo.promoCode.title,
      "serviceFee": ETAData.serviceFee,
      "deliveryFee": ETAData.deliveryFee,
      "itemTotal": orderInfo.itemTotal,
      "appliedDiscount": orderInfo.promoCode.discountValue
    }
    const res = await applyPromoCode(body);
    discount = res.discount;
  }
  return +discount.toFixed(2);
}

export const unssignBuddy = async (orderNo: string, deliveryNo: string, orderId: string) => {
  try {
    const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;
    const fleetConfig = store.getState()[ORDER_STORE_NAME].fleetConfig;
    const res = await callUnAssignAPI(deliveryNo, fleetConfig);
    const comment = "Buddy has been un assigned."
    console.log(res);
    if(res.ok){}
    changeOrderStatus(orderId, deliveryNo);
    await addTimeLineEvent(agent, orderNo, orderId, timelineTypes.ORDER_STATUS, timelineNames.BUDDY_UNASSIGNED, comment);

  } catch (error) {
    console.log("unassign-order", error);
  }
}

export const cancelOrder = async (orderNo: string, orderId: string, reason: string) => {
  try {
    const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;
    const body = {
      "orderNo": orderNo,
      action: "CANCELLATION",
      orderId: orderId,
      reason: reason,
    }

    const comment = `Order has been cancelled by: ${agent.name} due to : ${reason}.`
    await callOmsOrderComplete(body);
    updateSelectedOrder('');
    await addTimeLineEvent(agent, orderNo, orderId, timelineTypes.ORDER_STATUS, timelineNames.CANCELLED, comment);

  } catch (error) {
    console.log("cancel-order", error);
  }
}

export const transferOrders = async (currentAgent: any, replacementAgent: any, orders: any, action: string) => {
  try {
    const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;
    const comment = `Order reassigned from ${currentAgent.name} to ${replacementAgent.name}.`

    orders = orders.map((order: Order) => {
      return {
      "id": order.id,
      "orderNo": order.orderNo,
      "orderType": order.orderType,
      "isBulk": order.orderInfo.isBulk,
      "isGrouped": order.orderInfo.isGrouped,
    }});

    await fetchOrderTransfer(currentAgent, replacementAgent, orders, action);
    await addTimeLineEvent(agent, orders[0].orderNo, orders[0].id, timelineTypes.AGENT, timelineNames.ORDER_TRANSFER, comment);

  } catch (error) {
    console.log("transfer-order", error);
  }
}

export const convertToBuy = async (orderId: string, orderNo: string) => {
  const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;
  const comment = "Change order type yo BUY."
  await changeOrderType(orderId, 'BUY');
  await addTimeLineEvent(agent, orderNo, orderId, timelineTypes.ORDER, timelineNames.ORDER_TYPE, comment);

} 

export const validateOrder = async(orderId: string, orderNo: string, notes: string[], orderValidation: any, notesUpdated: boolean, newNotes: string[], validationUpdated: boolean, fleetId: any, 
  userId: string, pickupId: string, deliveryId: string, isPickupValid: boolean, isDeliveryValid: boolean) => {
  const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;
  const objectValidated = (orderValidation.cart && orderValidation.pickup && orderValidation.delivery);
  try {
    if(orderValidation.pickup && !isPickupValid) {
      await validateAddress(userId, pickupId, true);
    } else if(!orderValidation.pickup && isPickupValid) {
      await validateAddress(userId, pickupId, false);
    }

    if(orderValidation.delivery && !isDeliveryValid) {
      await validateAddress(userId, deliveryId, true); 
    } else if(!orderValidation.delivery && isDeliveryValid) {
      await validateAddress(userId, deliveryId, false); 
    }
    await save(orderId, {
      notes: notes,
      orderValidation: orderValidation,
      'pickupAddress.isValid': orderValidation.pickup,
      'deliveryAddress.isValid': orderValidation.delivery,
    });
    if (fleetId) {
      if (notesUpdated) {
        const msgTitle = `You have a new note - ${orderNo}`;
        const msgBody = newNotes.join(". ");
        const comment = `New note has been sent: ${msgBody}.`
        buddiesNotifications(msgTitle, msgBody, fleetId);
        await addTimeLineEvent(agent, orderNo, orderId, timelineTypes.BUDDY, timelineNames.BUDDY_NOTIFICATION_NOTE, comment);
      }
      if (validationUpdated && objectValidated) {
        const msgTitle = `Validation - ${orderNo}`;
        const msgBody = "The order Cart, pickup address and delivery address are valid";
        const comment = "Order has been validated"
        buddiesNotifications(msgTitle, msgBody, fleetId);
        await addTimeLineEvent(agent, orderNo, orderId, timelineTypes.BUDDY, timelineNames.BUDDY_NOTIFICATION_VALIDATION, comment);
      }
    }
  } catch (error) {
    console.log("validate order-error: ", error);
  }
}

export const compensate = async(orderId: string, orderNo: string, compensation: any, user: any) => {
  try {
    const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;
    await save(orderId, {
      compensation: compensation.reason,
    });
    await createPromoCode(compensation.promoCode);
    const payload = {
      "msgTitle": `Hello ${user.name}, redeem your code` ,
      "msgBody": `Use the promocode ${compensation.promoCode.title} on your next order!`,
      "customers": [{
        id: user.id,
        pushTokens: user.settings.pushTokens
      }]
    }
    const comment = "Customer has been compensated"
    await sendNotifications(payload, "omsSendCustomerNotification");
    await addTimeLineEvent(agent, orderNo, orderId, timelineTypes.CUSTOMER, timelineNames.CUSTOMER_COMPENSATION, comment);
  } catch (error) {
    console.log("Compensation error: ", error)
  }
}

export const rescheduleCourierOrder = async(orderId: string, orderNo: string) => {
  try {
    const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;
    const fleetConfig = store.getState()[ORDER_STORE_NAME].fleetConfig;
    await reschedule(orderNo, fleetConfig);

    const comment = `Order has been rescheduled by: ${agent.name}.`
    await addTimeLineEvent(agent, orderNo, orderId, timelineTypes.ORDER_STATUS, timelineNames.RESCHEDULED, comment);
  } catch (error) {
    console.log("Reschedule error: ", error)
  }
}

export const changePaymentDetails = async (orderId: string, orderNo: string, paymentType: string, cashToCollect: number) => {
  const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;
  const fleetConfig = store.getState()[ORDER_STORE_NAME].fleetConfig;
  let type = paymentType === "CASH" ? 1 : 2;
  let cash = +cashToCollect;
  const res = await changePaymentInCourier(orderNo, type, cash, fleetConfig);
  if(res?.ok){
    const comment = `Payment type has been updated to ${paymentType}.`
    await savePaymentDetails(orderId, paymentType, cash);
    await addTimeLineEvent(agent, orderNo, orderId, timelineTypes.PAYMENT, timelineNames.PAYMENT_METHOD, comment);
  }
}

export const addDelayToOrder = async (orderId: string, deliveryAt: Date, time: number, delay: any) => {
  const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;
  const deliveryDateAfterDelay = new Date(new Date(deliveryAt).getTime() + time * 60000);
  await save(orderId, { 
    deliveryAt: deliveryDateAfterDelay,
  }); 
  const manualDelayObj = {
    'etaMessage': delay.etaMessage,
    'etaMessageAr': delay.etaMessageAr,
    'reason': delay.reason,
  }
  await checkDelayNotificationValidity(orderId, manualDelayObj);
}

const buddiesNotifications = async(msgTitle: string, msgBody: string, fleetId: number) => {
  const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;
  const sentBy = {
    id: agent.id,
    name: agent.name,
    email: agent.email,
  };
  const buddy = await getBuddyDetails(fleetId);
    const payload = {
      "msgTitle": msgTitle,
      "msgBody": msgBody,
      "sentBy": sentBy,
      "buddies": [buddy]
    }
  sendNotifications(payload, "buddiesSendNotifications");
}

const addTimeLineEvent = async (agent: Agent, orderNo: string, orderId: string, type: string, name: string, comment: string) => {
  triggerPubSubEventCloudFunction({
    topicName: "TIMELINE_EVENT",
    data: {
      "orderId": orderId,
      "orderNo": orderNo,
      "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
      type: type,
      name: name,
      "actorId": agent.id,
      "actorType": "AGENT",
      "agentId": agent.id,
      "agentName": agent.name,
      "referenceId": "",
      "referenceCollection": "",
      "comment": comment
    },
  })
}

export const uploadItemImage = async (orderId: string, file: any) => {
  return await uploadFileToStorage(orderId, file);
}
