import { useState, useEffect } from "react";

export default function StatusTimer(props: any) {

  const [isDelayed, setIsDelayed] = useState(false);
  const [statusTimer, setStatusTimer] = useState('');

  function convertIntoSeconds(date: any) {
    return date.getTime();
  }

  useEffect(() => {
    const i = setInterval(StatusTime, 1000);
    return () => clearInterval(i);
  }, [props.orderStatus.subStatus, props.pickToDropTime]);

  function StatusTime() {

    const statusStartedAt = convertIntoSeconds(new Date(props.timestamps[props.orderStatus.timestampName].seconds * 1000));
    if(statusStartedAt && props.orderStatus.maxTimeDelayFromPreviousStatus > 0) {
      const nowInSeconds = convertIntoSeconds(new Date());

      let seconds = 0;
      if(props.orderStatus.subStatus === "IN_DELIVERY"){
        seconds = statusStartedAt + (props.pickToDropTime * 1000) - nowInSeconds;
      }
      else if(props.orderStatus.subStatus === "IN_PICKUP"){
        seconds = nowInSeconds -  statusStartedAt;
      }
      else{
        seconds = statusStartedAt + props.orderStatus.maxTimeDelayFromPreviousStatus - nowInSeconds;
      } 

      if(seconds !== 0) {
        showTimer(seconds);
      }
    }
  }

  const showTimer = (seconds: number) => {
    if(seconds >= 0) {
      setIsDelayed(false);
      if(props.orderStatus.subStatus !== "IN_PICKUP"){
        seconds -= 1000;
      }else{
        seconds += 1000;
        if(seconds > props.orderStatus.maxTimeDelayFromPreviousStatus){
          setIsDelayed(true);
        }
      }      
      const secToDate = new Date(seconds);
      const hour = secToDate.getUTCHours();
      const min = secToDate.getUTCMinutes();
      const sec = secToDate.getUTCSeconds();
      setStatusTimer(`${hour}: ${min}: ${sec}`);
    }
    if (seconds < 0) {
      seconds *= -1;
      seconds += 1000;
        const secToDate = new Date(seconds);
        const hour = secToDate.getUTCHours();
        const min = secToDate.getUTCMinutes();
        const sec = secToDate.getUTCSeconds();
        setIsDelayed(true);
        setStatusTimer(`-${hour}: ${min}: ${sec}`);
    }
  }
  
  return(
    <>
    <div
        className="text-xs"
      >
        <span className={`${!isDelayed ? "text-black" : "text-red-400"}`}>{statusTimer}</span>
        <span className="text-gray-200 mx-1"> | </span>
      </div>
    </>
  )
}