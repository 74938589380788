import React, { useEffect, PropsWithChildren } from 'react';
import { AUTH } from "../../../config/firebase";
import { useNavigate } from 'react-router-dom';
import { store } from '../../../redux';
import { AUTH_STORE_NAME } from '../../../contants/stores';
import { actions as authActions } from "../stores/auth.store"
import { getLoggedInAgentData } from '../../agent/actions/agent.actions';


export interface IAuthRouteProps { }

const AuthRoute: React.FunctionComponent<PropsWithChildren<IAuthRouteProps>> = (props) => {
  const { children } = props;
  const auth = AUTH.currentUser;
  const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;

  const navigate = useNavigate();

  useEffect(() => {
    const AuthCheck = AUTH.onAuthStateChanged(async (user: any) => {   
      
      if(user?.uid) { 
        console.log('user', agent)  
        
        if(!agent.id) {
          await getLoggedInAgentData(user?.uid?.toString() ?? "");
          console.log("AuthCheck:", agent)

        } else {
          navigate('/orders')
        }
      }
      if (!user) {
        navigate('/')
      }
    });
    return () => AuthCheck();
  },[auth, agent]);

  return <>{children}</>;
};

export default AuthRoute;