import { PromoCode } from "../types/promocode.data";
import { useAppSelector } from '../../../redux';
import { PROMOCODE_STORE_NAME } from "../../../contants/stores";
import { formatDate } from "../../../utils/helpers";
import Table from "../../../components/Table/Table";
import { search } from "../actions/propmoCode.actions";
import { useEffect, useState } from "react";

export const PromoCodesList = (props: any) => {
  const query = useAppSelector(state => state[PROMOCODE_STORE_NAME].searchQuery)
  // const promoCodes = useAppSelector(state => state[PROMOCODE_STORE_NAME].promoCodes);
  const headers = ["Title", "Expires at", "Discount", "Order type", "User type", "Action"];
  const [codes, setCodes] = useState(props.promoCodes);

  useEffect(() => {
    updateSearchList()
  }, [props.promoCodes])

  useEffect(() => {
    updateSearchList()
  }, [query]);

  const updateSearchList = () => {
    if (!query.length) {
      setCodes(props.promoCodes);
    } else {
      setCodes(search(query));
    }
  }

  return (
    <>
      {query.length && !codes.length ?
        <div className="w-full order-1 flex-1 bg-white rounded-lg m-4 text-xs">
          No results found
        </div> :
        <Table headers={headers}>
          {codes && codes?.map((code: PromoCode) => (
            <tr className="bg-white border-b hover:bg-gray-50 text-xs" key={code?.id}>
              <th scope="row" className="flex items-center py-4 px-6 text-gray-900 whitespace-nowra text-xs">
                <div className="">
                  {/* <div className="text-base font-semibold">{code?.title}</div> */}
                  <div className="flex items-center">
                    <div className={`h-2.5 w-2.5 rounded-full bg-${code?.isPromoCodeActive ? "green-400" : "red-500"} mr-2`}></div>
                    <div className="text-sm font-semibold text-gray-700">{code?.title}</div>
                  </div>
                  <div className="font-normal text-gray-500">{formatDate(code?.createdAt)}</div>
                </div>
              </th>
              <td className="py-4 px-6">
                {formatDate(code?.expiresAt)}
              </td>
              <td className="py-4 px-6">
                <p className="text-xs font-semibold text-gray-700">{code?.discount?.on}</p>
                <p className="font-normal text-gray-500">{code?.discount?.value?.toFixed(2)} {code?.discount?.type === 'PERCENTAGE' ? ' %' : ' KD'}</p>
              </td>
              <td className="py-4 px-6">
                {code?.orderType}
              </td>
              <td className="py-4 px-6">
                <p className="text-xs font-semibold text-gray-700">{code?.user?.type}</p>
                <p className="font-normal text-gray-500">{code?.user?.reference ?? ''}</p>
              </td>
              <td className="flex gap-2 py-4 px-6">
                <button
                  onClick={() => props.onPressItem(code, 'EDIT')}
                  data-modal-toggle="editUserModal" className="font-medium text-blue-600 hover:underline">Edit</button>
                <button
                  onClick={() => props.onPressItem(code, 'DELETE')}
                  data-modal-toggle="editUserModal" className="font-medium text-red-600 hover:underline">Delete</button>
              </td>
            </tr>
          ))}
        </Table>}
    </>
  );
}