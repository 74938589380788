export interface Agent {
  
  id: string;
  name: string;
  email: string;
  status?: string;
  isAdmin?: boolean;
  activeOrders?: number;
  pinnedOrders?: string[];
  busyModeAccess: boolean;
  activeGroupOrders?: number;
  completedGroupOrders?: number;
  isSuperAdmin: boolean | undefined;
  completedOrders?: number;
  lastLogin?: any;
}

export function assignValueToObject(query: any) {
  return {
    id: query.id,
    name: query.data().name,
    email: query.data().email,
    status: query.data().status,
    isAdmin: query.data().isAdmin,
    isSuperAdmin: query.data().isSuperAdmin,
    activeOrders: query.data().activeOrders,
    pinnedOrders: query.data().pinnedOrders,
    busyModeAccess: query.data().busyModeAccess,
    activeGroupOrders: query.data().activeGroupOrders,
    completedOrders: query.data().completedOrders,
    completedGroupOrders: query.data().completedGroupOrders,
    lastLogin: query.data().lastLogin.seconds * 1000,
  };
}