import Select from "../../../../../../components/Select/select";
import { ORDER_STORE_NAME } from "../../../../../../contants/stores";
import { useAppSelector } from "../../../../../../redux";

export function DelayDialogContent(props: any) {
  const delayReasons = useAppSelector(state => state[ORDER_STORE_NAME].delayReasons);

  return (
    <>
      <div>
        <p className="text-sm pl-2 truncate" title={props.date}>
          <span style={{ marginRight: "4px" }}>
            Delivery time: {props.date}
          </span>
        </p>
        <div className="flex justify-between items-center gap-3 my-2 mx-3">
          <div className="flex-1">
            <Select
              rules={{
                required: true,
              }} className="h-9" value={props.selectedDelayTime}
              id="time" onChange={(e: any) => { props.setSelectedDelayTime(+e.target.value) }} name="Delay time">
              {props.delayTimeOptions?.map((delay: any) => (
                <option key={delay.value} value={delay.value}>{delay.label}</option>
              ))}
            </Select>
          </div>
          <div className="flex-1">
            <Select
              rules={{
                required: true,
              }} className="h-9" value={props.selectedDelayReason?.reason}
              id="reason" onChange={(e: any) => {
                props.setSelectedDelayReason(delayReasons.find((r: any) => r.reason === e.target.value));
              }} name="Delay reason">
              {delayReasons?.map((delay: any) => (
                <option key={delay.reason} value={delay.reason}>{delay.reason}</option>
              ))}
            </Select>
          </div>
        </div>
        {props.orderDelays?.find((o: any) => o.type === "AUTOMATIC") ? <div className="flex pt-2 mx-3">
          <svg
            width="800px"
            height="800px"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-3 h-3 mr-2 text-yellow-400"
          >
            <path
              clipRule="evenodd"
              d="M16 15H0V12L7 0H9L16 12V15ZM7 4H9V9H7V4ZM7 11H9V13H7V11Z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
          <p className="text-xs">Automatic delay notification has been sent to the customer!</p>
        </div> : <></>}

      </div>
    </>
  )
}
