import { useEffect } from "react";
import {
  changeActiveTab,
  getBusinessConfig,
  getCourierBusyModeConfig,
  getFleetConfig,
  getTaggingConfig,
  getZonesData,
} from "../actions/settings.actions";
import AppBusyMode from "./busyMode/appBusyMode";
import BusyModeHistory from "./busyMode/busyMoodHistory";
import { AUTH_STORE_NAME, SETTINGS_STORE_NAME } from "../../../contants/stores";
import { useAppSelector } from "../../../redux";
import CourierBusyMode from "./busyMode/courierBusyMode";
import AppTagging from "./orderTagging/appTagging";
import CourierTagging from "./orderTagging/courierTagging";

const tabs = [
  {
    id: "busyModeHistory",
    component: <BusyModeHistory id="busyModeHistory" />,
  },
  {
    id: "appBusyMode",
    component: <AppBusyMode id="appBusyMode" />,
  },
  {
    id: "courierBusyMode",
    component: <CourierBusyMode id="courierBusyMode" />,
  },
  {
    id: "appTagging",
    component: <AppTagging id="appTagging" />,
  },
  {
    id: "courierTagging",
    component: <CourierTagging id="courierTagging" />,
  },
];
export default function Settings() {
  const activeTab = useAppSelector(
    (state) => state[SETTINGS_STORE_NAME].activeTab
  );
  const agent = useAppSelector((state) => state[AUTH_STORE_NAME].loggedInAgent);
  console.log(agent);
  useEffect(() => {
    getBusinessConfig();
    getTaggingConfig();
    getZonesData();
    // getFleetConfig();
    getCourierBusyModeConfig();
  }, []);
  return (
    <>
      <div className="m-3 h-[calc(100vh-4.5rem)]">
        <div className="flex bg-white rounded-lg h-full">
          <div className="w-1/4 bg-gray-50 rounded-lg">
            <div className="flex flex-col justify-start p-4">
              {agent.busyModeAccess && (
                <>
                  <p className="p-2 font-semibold">Busy mode</p>
                  <TabButton tag="busyModeHistory" title="History" />
                  <TabButton tag="appBusyMode" title="Mashkor App" />
                  <TabButton tag="courierBusyMode" title="Courier Dashboard" />
                </>
              )}
              <p className="p-2 font-semibold">Order tagging</p>
              <TabButton tag="appTagging" title="Mashkor App" />
              <TabButton tag="courierTagging" title="Courier Dashboard" />
            </div>
          </div>

          <div className="w-3/4">
            <div className="flex flex-col justify-start p-4">
              {tabs.find((tab: any) => tab.id === activeTab)?.component}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function TabButton(props: any) {
  const activeTab = useAppSelector(
    (state) => state[SETTINGS_STORE_NAME].activeTab
  );

  return (
    <>
      <button
        onClick={() => changeActiveTab(props.tag)}
        className={`flex justify-between text-left ${
          props.tag === activeTab
            ? "bg-indigo-500 text-white"
            : "bg-gray-300 hover:bg-gray-200 text-gray-500 text-base"
        } font-bold rounded-lg text-sm px-4 py-3 mb-2`}
      >
        {props.title}
        <svg
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 50 50"
          enableBackground="new 0 0 50 50"
          xmlSpace="preserve"
          strokeWidth={2}
          className="w-5 h-5 hover:text-white font-bold flex items-center"
        >
          <path
            fill="currentColor"
            d="M15.563,40.836c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l15-15 c0.391-0.391,0.391-1.023,0-1.414l-15-15c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l14.293,14.293L15.563,39.422 C15.172,39.813,15.172,40.446,15.563,40.836z"
          />
        </svg>
      </button>
    </>
  );
}
