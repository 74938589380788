import firebase from "firebase";

export default function AddNewAddressButton(props: any) {
  return (
    <>
      <button onClick={() => {
        props.changeActiveMode('add');
        props.setSavedAddressId(null);
        props.setAddressType('HOME')
        const location = new firebase.firestore.GeoPoint(29.241971299229583, 47.96440653977878);

        props?.setLocation(location);
        const updatedAddress = {
          ...props?.updatedAddress,
          type: 'HOME',
          addressStr: 'Kuwait city',
          location: location,
          area_id: null,
          block_id: null,
          areaAr: null,
          streetAr: null,
          blockAr: null,
          area: null,
          street_id: null,
          block: null,
          floorNumber: null,
          roomNumber: null,
          avenue: null,
        };
        
        props?.setUpdatedAddress(updatedAddress);
        }} type="button" className="flex gap-2 w-full p-3 bg-indigo-700/10 rounded-md">
        <svg
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 p-1 bg-white text-indigo-700 rounded-sm font-bold"
        >
          <path fill="currentColor" d="M14 7v1H8v6H7V8H1V7h6V1h1v6h6z" />
        </svg>
        <p className="text-sm text-gray-700">
          Add new address
        </p>
      </button>
    </>
  )
}