import { MutableRefObject, useEffect, useRef } from "react";

export default function Input(props: any) {
  const inputRef: MutableRefObject<HTMLInputElement | undefined> = useRef();

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  function setValue(value: any) {
    if (inputRef?.current) {
      inputRef.current.value = value;
    }
  }
  return (
    <>
      <label className="flex-1">
        <span className="font-normal text-xs capitalize">{props?.labelText ?? ""}</span>
        <input
          ref={inputRef}
          type={props.type} defaultValue={props.defaultValue ?? ""}
          {...props.rules}
          // autoComplete="off"
          // onInvalid={(e : React.ChangeEvent<HTMLInputElement>) => e.target.setCustomValidity("Please enter a valid value")}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          onFocus={props.onFocus}
          onInput={props.onInput}
          className={`form-control
            block
            w-full
            px-3
            py-1.5
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded-lg
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ${props.className}`}
            aria-describedby="emailHelp124" placeholder={props.placeholder}  name={props.name}/>
      </label>
    </>
  )
}