import { useRef, MutableRefObject } from "react";
import Modal from "../../../../../components/Dialog/Modal";

export default function ConfirmPinPoint(props: any) {

  return (
    <>
      <Modal title="PACI details" 
      inset="top-1/2 -translate-y-1/2 right-0"
      width="min-w-[460px]"
      onClose={() => {
        props.setOpenModal(false);
        props.setConfirmPin(false);
      }}>
        <div className="bg-white rounded-lg p-2 m-2">
          <p className="text-xs m-2">
            PACI details have been updated, <br/>
            adjust the pin to accurately select <br/>
            the address on the map.
          </p>
          <div className="flex justify-center items-center gap-2">
            <button className="font-semibold text-indigo-700 bg-indigo-400/10 p-2 m-2 w-full rounded-lg" 
            onClick={() => {
              props.setOpenModal(false);
              props.setConfirmPin(false);
            }}>Cancel</button>
            <button className="font-semibold text-white bg-indigo-700 p-2 m-2 w-full rounded-lg" 
            onClick={() => {
              props.setConfirmPin(true);
              props.setOpenModal(false);
            }}>Confirm</button>
          </div>
        </div>
      </Modal>
    </>
  )
}