import { useEffect, useState } from 'react'
import { ORDER_STORE_NAME } from '../../../../contants/stores'
import { useAppSelector } from '../../../../redux'
import { Tab } from '@headlessui/react'
import { updateSelectedPrimaryStatus, updateSelectedSubStatuses } from '../../actions/order.actions'
import { Order } from '../../types/order.data'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function OrdersGrouping() {
  const orders = useAppSelector(state => state[ORDER_STORE_NAME].orders);

  const primaryStatuses = useAppSelector((state) => state[ORDER_STORE_NAME].primaryStatuses)
  const [selectedPrimaryStatus, setSelectedPrimaryStatus] = useState({
      show: true,
      status: primaryStatuses[0]?.primaryStatus
    })
  const [selectedSubStatuses, setSelectedSubStatuses]: any = useState({});

  useEffect(() => {
    if(primaryStatuses.length) {
      setSelectedPrimaryStatus({
        show: true,
        status: primaryStatuses[0].primaryStatus
      });
      setSelectedSubStatuses({
        [primaryStatuses[0].primaryStatus] : primaryStatuses[0]?.subStatuses?.map((s: any) => s.subStatus)
      });
    }
  },[primaryStatuses]);

  function setSubStatuses(status: any) {
    setSelectedSubStatuses({
      ...selectedSubStatuses,
      [status.primaryStatus]: status.subStatuses.map((s: any) => s.subStatus)
    })
  }

  function toggleSelectedSubStatus(primary: any, subStatus: string) {
    const sbs = [...selectedSubStatuses[primary]]
    const idx = sbs.findIndex((sb) => sb === subStatus)
    if (sbs?.length === primaryStatuses.find((ps: any) => ps.primaryStatus === primary)?.subStatuses?.length) {
      setSelectedSubStatuses({
        ...selectedSubStatuses,
        [primary]: [subStatus]
      });
      return;
    }
    if (idx >= 0) sbs.splice(idx, 1)
    else sbs.push(subStatus)
    setSelectedSubStatuses({
      ...selectedSubStatuses,
      [primary]: [...sbs]
    });
  }

  useEffect(() => {
    updateSelectedSubStatuses(selectedSubStatuses);
    updateSelectedPrimaryStatus(selectedPrimaryStatus);
  }, [selectedPrimaryStatus, selectedSubStatuses]);

  return (
    <div className="relative">
      <Tab.Group>
        <Tab.List className="flex justify-between gap-1 border-t py-1 border-gray-300 px-3">
          {Object.values(primaryStatuses).map((s: any, idx: number) => (
            <Tab
              key={idx}
              onClick={() => {
                if (selectedPrimaryStatus.status === s.primaryStatus) return setSelectedPrimaryStatus({
                  ...selectedPrimaryStatus,
                  show: !selectedPrimaryStatus.show
                })
                setSelectedPrimaryStatus({
                  show: true,
                  status: s.primaryStatus
                })
                if (!selectedSubStatuses[s.primaryStatus]) {
                  setSubStatuses(s)
                }
              }}
              className={({ selected }) =>
                classNames(
                  'rounded-lg py-1 px-2 text-xs font-medium text-gray-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-gray-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-gray-700 hover:bg-white/[0.12] hover:text-gray-500'
                )
              }
            >
              <div className="flex">
                <span className="text-xs">{s.primaryStatus} </span>
                <span className="text-xs">{`(${ orders.filter((order: Order) => order.primaryStatus === s.primaryStatus).length})`}</span>
              </div>
              
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className={`${selectedPrimaryStatus.show ? 'block' : 'hidden'} px-3 border-t py-2 border-gray-300`}>
          {Object.values(primaryStatuses).map((s: any, idx: number) => (
            <Tab.Panel
              key={idx}
            >
              <ul className='flex flex-wrap gap-1'>
                {s.subStatuses.map((sb: any) => (
                  <li
                    key={sb.subStatus}
                    className={`relative rounded-md px-2 py-0.5 cursor-pointer ${selectedSubStatuses[s.primaryStatus]?.find((ssb: string) => ssb === sb.subStatus) ? 'bg-green-200' : 'bg-gray-200'}`}
                    onClick={() => toggleSelectedSubStatus(s.primaryStatus, sb.subStatus)}
                  >
                    <h3 className="text-xs text-[0.65rem]">
                    <div className="flex">
                      <span className="text-xs">{sb.subStatus} </span>
                      <span className="text-xs">{`(${ orders.filter((order: Order) => order.subStatus === sb.subStatus).length})`}</span>
                    </div>
                    </h3>
                  </li>
                ))}
              </ul>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
