import { MutableRefObject, useRef, useState } from "react";
import Button from "../../../components/Button/Button"
import Input from "../../../components/Input/input"
import { supportAction } from "../actions/support.actions";
import Select from "../../../components/Select/select";

const actions = [
  {
    name: "Regenerate Payment Link",
    event: "REGENERATE_PAYMENT_LINK"
  },
  {
    name: "Retry buddy request",
    event: "RETRY_BUDDY_REQUEST"
  },
  {
    name: "Sync order status",
    event: "SYNC_ORDER_STATUS"
  }
]
export default function Support() {
  const formSubmitButtonRef = useRef() as MutableRefObject<{ click: Function }>;
  const [action, setAction] : [any, Function] = useState(actions[0]);
  const [orderNo, setOrderNo] = useState('');

  const saveChanges = async () => {
    await supportAction(orderNo, action.event)
  }

  return (
    <>

      <div className="container mx-auto h-[calc(100vh-64px)]">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
              <form
                onSubmit={(e: any) => {
                  e.preventDefault();
                  formSubmitButtonRef?.current?.click();
                }} className="bg-white m-2 p-4 rounded-lg">
                <div className="bg-white px-3 py-2 my-3 w-full rounded-lg overflow-hidden border-gray-300 border" >
                  <Select 
                    rules={{
                      required: true,
                    }} className="h-9 mb-2" value={action.event}
                    id="reason" onChange={(e: any) => {
                      setAction(actions.find((a: any) => a.event === e.target.value));
                    }} name="Support action">
                    {actions?.map((a: any) => (
                      <option key={a.event} value={a.event}>
                        {a.name}
                      </option>
                    ))}
                  </Select>
                  <Input className="h-9 my-2"
                    rules={{
                      required: true,
                    }}
                    onChange={(e: any) => {
                      setOrderNo(e.target.value);
                    }} value="" name="discount.value" type="text" placeholder="C-123456789" />
                </div>
                <div className="flex items-center justify-end">
                  <Button
                    ref={formSubmitButtonRef}
                    type="submit"
                    notify={false}
                    id="editCode"
                    cb={saveChanges}
                    text="Save"
                    loadingText="Saving..."
                    successMsg="Changes saved successfully"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}