import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { AUTH_STORE_NAME } from "../../contants/stores";
import { changeActiveMode } from "../../modules/order/actions/order.actions";
import { useAppSelector } from "../../redux";
import Drawer from "../Drawer/Drawer";

interface IPageProps {
  name?: string;
}

declare global {
  interface Window { "_jorel-toggleJorel": boolean; }
}


export function DraweButton({ isOpen, setIsOpen }: any) {
  return (
    <button
      className="text-white hover:bg-white hover:text-mashkorGreen focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 md:px-5 md:py-2.5 focus:outline-none"
      type="button"
      data-drawer-target="drawer-navigation"
      data-drawer-show="drawer-navigation"
      data-drawer-hidd="drawer-navigation"
      aria-controls="drawer-navigation"
      onClick={() => setIsOpen(!isOpen)}
    >
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16"></path></svg>
    </button>
  );
}

const Navbar: React.FunctionComponent<IPageProps> = props => {
  const agent = useAppSelector(state => state[AUTH_STORE_NAME].loggedInAgent);
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeTab, setActiveTab] = useState('all');
  const [isZiwoActive, setIsZiwoActive] = useState(false);
  const handleModeChange = (index: string) => {
    setActiveTab(index);
    changeActiveMode(index);
  }
  const handleZiwoToggle = () => {
    setIsZiwoActive(!isZiwoActive);
    (window as any)["_jorel-toggleJorel"](isZiwoActive);
  };
  const { pathname } = useLocation();
  const ziwoPaths = [
    'orders',
    'agents',
    'promo-codes',
    'support',
    'settings',
    'order-logs',
    'earnings',
  ]
  const show = ziwoPaths.some(p => pathname.includes(p))
  return (
    <>
      <nav className="relative w-screen flex items-center justify-between px-2 py-1 gap-2 md:gap-4 bg-mashkorGreen h-12">
        <div className="flex items-center">
          {show ? <DraweButton isOpen={isOpen} setIsOpen={setIsOpen} /> : <></>}
          <div className="relative flex justify-between items-center lg:static lg:justify-start lg:flex-row-reverse">
            {(show) ? <a
              className="text-sm text-white font-bold leading-relaxed inline-block mr-2 md:mr-4 py-2 whitespace-nowrap uppercase"
              href="/orders">
              Mashkor
            </a> :
              <div className="text-sm text-white font-bold leading-relaxed inline-block mr-2 md:mr-4 py-2 whitespace-nowrap uppercase">
                Mashkor
              </div>
            }
          </div>
          {pathname.includes("orders") ?
            <div className="flex items-center text-xs shadow-md bg-white overflow-hidden z-10 w-28 md:w-32 h-8 rounded-lg">
              <button onClick={() => handleModeChange('all')} type="button" className={`h-full flex-1 hover:bg-blue-100 ${activeTab === 'all' ? 'bg-blue-200' : ''}`}>
                All
              </button>
              <button onClick={() => handleModeChange('priority')} type="button" className={`h-full flex-1 hover:bg-blue-100 ${activeTab === 'priority' ? 'bg-blue-200' : ''}`}>
                Priority
              </button>
            </div> : <></>}
        </div>

        {show ?
          <div className="flex items-center">
            <div className="fixed -bottom-2 right-0 z-50 cursor-pointer w-20 h-20 flex items-center" onClick={() => handleZiwoToggle()}>
              <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="100%" height="100%" viewBox="0 0 900.000000 527.000000"
                preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,527.000000) scale(0.100000,-0.100000)"
                  fill="#ac3378" stroke="none">
                  <path d="M4285 4839 c-204 -27 -427 -95 -630 -193 -616 -299 -1042 -884 -1130
            -1552 -62 -475 46 -919 325 -1333 94 -140 169 -230 511 -613 380 -424 445
            -497 562 -636 l87 -103 0 259 c0 216 2 262 15 272 11 9 39 8 132 -6 155 -23
            428 -23 573 0 517 81 997 357 1312 751 220 277 381 653 434 1015 24 167 15
            441 -20 606 -60 282 -195 582 -367 814 -86 115 -264 290 -379 373 -113 80
            -358 204 -490 247 -129 43 -274 77 -400 95 -116 16 -419 18 -535 4z m1246
            -2372 c76 -51 74 -141 -6 -302 -90 -180 -239 -321 -450 -425 -199 -99 -347
            -137 -565 -147 -280 -12 -564 60 -767 194 -82 54 -197 166 -254 247 -58 82
            -110 210 -111 275 -2 87 56 151 137 151 44 0 98 -28 114 -59 6 -11 23 -52 37
            -93 15 -40 40 -93 57 -117 92 -134 272 -245 485 -298 107 -27 389 -24 505 5
            306 77 531 257 598 480 7 26 27 56 51 77 32 30 45 35 87 35 33 0 58 -7 82 -23z"/>
                </g>
              </svg>
            </div>
            <div className="flex gap-2 items-center px-2 md:px-3 py-2.5 bg-white text-mashkorGreen focus:ring-4 ring-blue-300 rounded-lg text-sm focus:outline-none">
              <span className={`h-2.5 w-2.5 rounded-full ${agent.status === "ONLINE" ? "bg-green-400" : agent.status === "OFFLINE" ? "bg-red-500" : "bg-yellow-400"}`}></span>
              <p className="capitalize font-medium">
                {agent.name}
              </p>
            </div>
          </div>
          :
          <></>}
      </nav>
      {agent ? <Drawer isOpen={isOpen} setIsOpen={setIsOpen} /> : <></>}
    </>
  );
}

export default Navbar;