import firebase from "firebase";
import { AUTH, DATABASE } from "../../../config/firebase";
import { getLoggedInAgentData } from "../../agent/actions/agent.actions";
import { fetchAgentData, updateAgent, updateStatus } from "../../agent/services/agent.service";
import { assignValueToObject } from "../../agent/types/agent.data";

export const authenticateLogin = async (email: string, password: string) => {
  try {
    const authRes = await AUTH.signInWithEmailAndPassword(email, password);
    const user = authRes.user;
    await getLoggedInAgentData(user?.uid?.toString() ?? "");
    await updateStatus(user?.uid?.toString() ?? "", "ONLINE");
    await updateAgent(user?.uid?.toString()?? "", 
    {
      status: 'ONLINE', 
      lastLogin: firebase.firestore.FieldValue.serverTimestamp(),
    });
  } catch (error) {
    console.log(`An error was occured while logging in ${error}`)
    throw error;
  }
};

export const resetPassword = async (email: string) => {
  try {
    await AUTH.sendPasswordResetEmail(email); 
  } catch (error) {
    console.log("Error while sending password resetting email", email);
  }
}

export const logout = async () => {
  try {
    await AUTH.signOut();
    console.log("Logged out")
    return true;
  } catch (error) {
    console.log("An error happened.")
    throw error;
  }
}
