import { useState } from "react"
import Dialog from "../../../../../components/Dialog/SideDrawer";
import EditOrder from "./orderCartEdit";
import OrderInfoCard from "./orderInfoCard";
import CourierOrderInfo from "./courierOrderInfo";
import { selectContents } from "../../../../../utils/helpers";

export default function OrderInfo(props: any): JSX.Element {
  const [isEditCartDialogOpen, setEditCartDialogOpen] = useState(false);
  
  const excessAmount = ((props?.order?.orderInfo?.totalAmount) < (props?.order?.orderInfo?.onlinePaid + props?.order?.orderInfo?.walletPaid))
    ? +((props?.order?.orderInfo?.walletPaid + props?.order?.orderInfo?.onlinePaid) - props?.order?.orderInfo?.totalAmount - props?.order?.orderInfo?.cardCharges).toFixed(2) : 0;

  return (
    <>
      <div className="mt-2 my-3 mx-3 border border-light rounded-lg">
        <div className="p-3">
          <div className="flex justify-between border-b border-light pb-2">
            {props?.order?.orderType !== "COURIER" ?
            
              <div className="flex text-base">
                {props?.order?.activePaymentInfo ?
                  <div className="flex">
                    <h1 id="KNET" className="text-left font-bold">KNET</h1>
                    <svg onClick={() => {
                        selectContents("KNET");
                        navigator.clipboard.writeText(props?.order?.activePaymentInfo?.knetUrl);
                      }}
                      className="mx-2 cursor-pointer w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"></path></svg>
                  </div> : <></>}
                {props?.order?.activePaymentInfo ?
                  <div className="flex">
                    <h1 id="CARD" className="text-left font-bold">{"  |  " + "CARD"}</h1>
                    <svg onClick={() => {
                        selectContents("CARD");
                        navigator.clipboard.writeText(props?.order?.activePaymentInfo?.cardUrl);
                      }}
                      className="mx-2 cursor-pointer w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"></path></svg>
                  </div> : <></>}
                {props?.order?.applePayRef?.customerRef ?
                  <div className="flex">
                    <h1 id="APPLE_PAY" className="text-left font-bold">{"  |  " + "APPLE PAY REF"}</h1>
                    <svg onClick={() => {
                        selectContents("APPLE_PAY");
                        navigator.clipboard.writeText(props?.order?.applePayRef?.customerRef);
                      }}
                      className="mx-2 cursor-pointer w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"></path></svg>
                  </div> : <></>}
              </div>
              : <h2 className="font-bold">
                Payment method
              </h2>}
            {props?.order?.orderType === 'COURIER' || props?.order?.subStatus === "PAYMENT_PENDING" ? <></> :
              <button
                type="button" onClick={() => setEditCartDialogOpen(true)}
                className="flex items-center text-indigo-400 hover:text-indigo-300"
              >
                <span className="mr-1 text-xs">Edit</span>
                <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd"></path></svg>
              </button>}
          </div>
        </div>
        {isEditCartDialogOpen ? <Dialog open={isEditCartDialogOpen} onClose={() => setEditCartDialogOpen(false)} title="Edit Order">
          <div className="px-2">
            <OrderInfoCard orderInfo={props?.order?.orderInfo} id={props?.order?.id} excessAmount={excessAmount} />
            <EditOrder orderInfo={props?.order?.orderInfo} id={props?.order?.id} orderNo={props?.order?.orderNo} orderValidation={props?.order?.orderValidation}
            orderType={props?.order?.orderType} primaryStatus={props?.order?.primaryStatus} fleetId={props?.order?.deliveryInfo?.fleetId}/>
          </div>
        </Dialog> : null}

        {props?.order?.orderType !== "COURIER" ?
          <div className="flex justify-center items-center gap-3 px-3 pb-3">
            <div className={`flex-1 p-3 h-fit rounded-lg flex flex-col text-white justify-evenly items-center ${excessAmount > 0 ? "bg-red-500" : "bg-blue-500"}`}>
              <h6 className="">{`${excessAmount > 0 ? 'Excess amount' : 'Cash to collect'}`}</h6>
              <span className="text-5xl">{excessAmount > 0 ? excessAmount : props?.order?.orderInfo?.cashToCollect} <span className="text-3xl">KD</span></span>
            </div>
            <div className="flex-1 p-3 h-fit rounded-lg flex flex-col text-white justify-evenly items-center bg-green-500">
              <h6 className="">Paid</h6>
              <span className="text-5xl">{(props?.order?.orderInfo?.onlinePaid + props?.order?.orderInfo?.walletPaid).toFixed(2)} <span className="text-3xl">KD</span></span>
            </div>
          </div> :
          <div className="flex gap-3 px-3">
            <CourierOrderInfo orderInfo={props?.order?.orderInfo} orderNo={props?.order?.orderNo} orderId={props?.order?.id} primaryStatus={props?.order?.primaryStatus}/>
          </div>}

      </div>
    </>
  )
}
