import DriverOrdersItem from "./driverOrdersItem";

export default function DriverOrders(props: any) {
  return (
    <>
      <ul className="my-2 space-y-2">
        {props?.orders?.map((order: any) => (
          <li key={order?.orderNo}>
            <DriverOrdersItem order={order} />
          </li>
        ))}
      </ul>
    </>
  )
}