import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux';
import { ZiwoContainer } from './modules/order/views/ziwo/ziwoContainer';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const ziwo = ReactDOM.createRoot(
  document.getElementById('ziwoContainer') as HTMLElement
);
ziwo.render(
  <Provider store={store}><ZiwoContainer /></Provider>
);

root.render(
  <Provider store={store}>
      <App />
    </Provider>
);
