import { createSlice } from "@reduxjs/toolkit"
import { EARNINGS_STORE_NAME } from "../../../contants/stores";
import { EarningConfig } from '../types/earning.data';

interface EarningsState {
  defaultConfig: any,
  earningConfigs: EarningConfig[],
  searchQuery: string,
  status: string,
  error: Error | undefined
}

export const initialState: EarningsState = {
  defaultConfig: {},
  earningConfigs: [],
  searchQuery: '',
  status: 'idle',
  error: undefined
}

export const slice = createSlice({
  name: EARNINGS_STORE_NAME,
  initialState,
  reducers: {
    setDefaultEarningConfig: (state, action) => {
      let earningConfig = {...action.payload};
      const { configs, apiKey, pricingInfoUrl, buyBonusConfig, ...config } = earningConfig;
      state.defaultConfig = {
        ...config,
        title: "",
      };
    },
    setEarningConfigs: (state, action) => {
      state.earningConfigs = action.payload.configs;
    },
    updateEarningConfigs: (state, action) => {
      state.earningConfigs = action.payload;
    },
    updateSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
});

export const actions = slice.actions
export const reducer = slice.reducer
