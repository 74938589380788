import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from '@headlessui/react';
import { updateOrderPriorityActions } from "../../modules/order/actions/order.actions";
export function ActionsDropdown(props: any) {
  const [statuses, setStatuses] : any = useState([]);

  useEffect(() => {
    setStatuses([...props.priorityStatus])
  },[props.id]);
  
  const onChange = (e: any, pIdx: number, aIdx: number) => {
    const _items = [...statuses];
    let item = { ..._items[pIdx] };
    const actions = [...item.actions];
    const action = { ...actions[aIdx] };
    
    actions.splice(aIdx, 1, { ...action, checked: e.target.checked });
    
    item = {...item, actions: actions};
    _items.splice(pIdx, 1, item);
    setStatuses(_items);

  }

  const saveChanges = async () => {
    if(statuses.length === 0) {
      await updateOrderPriorityActions(props.id, props?.orderNo ,[...props.priorityStatus])
    } else {
      await updateOrderPriorityActions(props.id, props?.orderNo, statuses)
    }
  }

  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        {({ open, close }) => (
          <div className="relative mt-1">
            <div>
              <Menu.Button className="inline-flex w-full justify-center rounded-md bg-white p-2 text-sm font-medium text-gray-900 hover:text-opacity-50 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <div className="flex items-center">
                  Actions
                  
                  {(props?.priorityStatus?.flatMap((obj: any) => obj.actions).every((action: any) => action.checked)) ?
                    <svg className="w-4 h-4 text-mashkorGreen font-[900] mx-1" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                    </svg> : <></>}
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1">
                  {props.priorityStatus?.map((p: any, pIdx: number) => (
                    <Menu.Item key={pIdx}>
                      {({ active }) => (
                        <div
                          className={`${active ? 'bg-indigo-400 text-white' : 'text-gray-900'
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <div key={pIdx} className="w-full mb-3">
                            <h3 className='mb-2 pb-1 border-b border-gray-100'>{p.label}</h3>
                            {p.actions?.map((action: any, aIdx: number) => (
                              <label className="space-x-2 flex items-center"
                                key={action.title}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                onChange={(e) => {
                                  onChange(e, pIdx, aIdx)
                                }}>
                                <input type="checkbox" defaultChecked={action.checked} value={action.title} className="form-tick bg-white bg-check h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none" />
                                <span className="font-normal">
                                  {action.title}
                                </span>
                              </label>
                            ))}
                            
                          </div>
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </div>
                <div className="flex gap-1 justify-end">
                  <button
                    onClick={() => {
                      saveChanges();
                      close();
                    }}
                    className="rounded-md flex justify-center items-center border border-transparent bg-indigo-600 m-1 px-2 py-1 text-base font-medium text-white shadow-sm hover:bg-indigo-700">
                    <p>Save</p>
                  </button>
                </div>
              </Menu.Items>
            </Transition>
          </div>
        )}
      </Menu>
    </div>
  )
}
