import { useEffect, useState } from "react";
import { Avatar } from "../../../assets/images";
import Dialog from "../../../components/Dialog/SideDrawer";
import { AUTH_STORE_NAME, CUSTOMER_STORE_NAME } from "../../../contants/stores";
import { useAppSelector } from "../../../redux";
import { getCustomerById } from "../actions/customer.actions";
import CustomerOrderHistory from "./customerOrderHistory";

const CustomerDetails = (props: any) => {
	const agent = useAppSelector(state => state[AUTH_STORE_NAME].loggedInAgent);
	let customer = useAppSelector(state => state[CUSTOMER_STORE_NAME].customer);
	const customerTagsConfig = useAppSelector(state => state[CUSTOMER_STORE_NAME].customerTagsConfig)
	if(props?.searchCustomer) customer = props?.searchCustomer
	const [isCustomerDialogOpen, setCustomerDialogOpen] = useState(false);
	const [positiveTags, setPositiveTags] : [any, Function] = useState([]);
	const [negativeTags, setNegativeTags] : [any, Function]= useState([]);

	useEffect(() => {
		if(props.orderType !== 'COURIER' && !props.searchCustomer) {
			const unsubscribe = getCustomerById(props.customerId);
			return () => {
				if (unsubscribe) unsubscribe();
			}
		}
  }, [props.customerId]);

	useEffect(() => {
		setPositiveTags(customerTagsConfig?.positive);
		setNegativeTags(customerTagsConfig?.negative);
		
	}, [customerTagsConfig]);

	return (
		<>

			<div className="card mx-3 mt-2 border border-light rounded-lg bg-white">
				<div className="p-3">
					<div className={`flex items-center justify-between ${customer?.tags ? "border-b" : ""} border-light ${customer?.tags ? "pb-2 mb-2" : ""}`}>
						<div className="flex">
							<img
								className="inline-block h-10 w-10 rounded-full ring-2 ring-white m-2"
								src={Avatar}
								alt=""
							/>
							<div className="text-sm m-2 font-bold">
								<p className="text-gray-900 text-left leading-none">{props?.customerName || customer?.name}</p>
								<p className="text-gray-600 cursor-pointer" onClick={() => {
									(window as any)["_jorel-callPhoneNumber"](props?.customerPhone || customer?.phone);
								}}>{props?.customerPhone || customer?.phone}</p>
								{(props.orderType !== "COURIER") ? <p className="text-gray-600">{props?.customerEmail || customer?.email}</p> : <></>}
								{(props.vendorOrderId) ? <p className="text-gray-500">Vendor order Id: {props?.vendorOrderId}</p> : <></>}
							</div>
						</div>
						{(props.orderType !== "COURIER") ? <div>
						
							{(props?.primaryStatus !== 'OTHERS') ?
								<button
									type="button" onClick={() => setCustomerDialogOpen(true)}
									className="flex items-center text-indigo-400 hover:text-indigo-300"							>
									<span className="mr-1 text-xs">View</span>
									<svg
										className="w-4 h-4 text-indigo-400"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill="none"
											stroke="currentColor"
											strokeWidth={2}
											d="M18.5,21 C20.9852814,21 23,18.9852814 23,16.5 C23,14.0147186 20.9852814,12 18.5,12 C16.0147186,12 14,14.0147186 14,16.5 C14,18.9852814 16.0147186,21 18.5,21 L18.5,21 Z M10,7 L14,7 M1.5,14.5 C1.5,14.5 5.5,5 6,4 C6.5,3 7.5,3 8,3 C8.5,3 10,3 10,5 L10,16 M5.5,21 C3.01471863,21 1,18.9852814 1,16.5 C1,14.0147186 3.01471863,12 5.5,12 C7.98528137,12 10,14.0147186 10,16.5 C10,18.9852814 7.98528137,21 5.5,21 L5.5,21 L5.5,21 Z M22.5,14.5 C22.5,14.5 18.5,5 18,4 C17.5,3 16.5,3 16,3 C15.5,3 14,3 14,5 L14,16 M10,16 L14,16"
										/>
									</svg>
								</button>
								: <></>}
						</div> : <></>}
					</div>
					<div className="grid grid-cols-4">
						{props.orderType !== "COURIER" && customer?.tags?.map((tag: string) => (
							<div key={tag} className={`flex justify-center items-center m-1 px-2 py-1 border ${positiveTags?.includes(tag) ? "border-green-300 bg-green-200 text-green-700": ""}
							${negativeTags?.includes(tag) ? "border-red-300 bg-red-200  text-red-700": ""} rounded-lg text-base font-medium`}>
								<div className="truncate flex-initial max-w-full leading-none text-xs font-normal">{tag}</div>
							</div>
						))}
					</div>
				</div>
				{isCustomerDialogOpen ? <Dialog open={isCustomerDialogOpen} onClose={() => setCustomerDialogOpen(false)} title="Customer history">
					<CustomerOrderHistory tags={customer?.tags} orders={customer?.orders} walletBalance={customer?.walletBalance} id={customer?.id} positiveTags={positiveTags} negativeTags={negativeTags}/>
				</Dialog> : null}
			</div>
		</>

	)
}

export default CustomerDetails;
