import { ORDER_STORE_NAME } from '../../../../contants/stores';
import { useAppSelector } from '../../../../redux';
import CustomerDetails from '../../../customer/views/customerDetails';
import { Order } from '../../types/order.data';
import DeliveryInfo from '../delivery/deliveryInfo';
import OrderTimelineEvents from '../timeline/timeLineEvents';
import AddressDetailsBox from './address/addressDetailsBox';
import OrderInfo from './info/orderDetails';
import OrderSummary from './info/orderSummary';

export function OrderDetail(props: any) {
  let selectedItem = useAppSelector(state => state[ORDER_STORE_NAME].orders.find((order: Order) => order.id === props.orderId));
  if(!selectedItem) {
    selectedItem = props?.order;
  }
  const orderStatuses = useAppSelector(state => state[ORDER_STORE_NAME].orderStatuses);

  return (
    <>
      <div style={{flex: props?.fromSearch ? 2 : 0}} className="flex flex-col gap-1 bg-white rounded-lg m-h-64 h-[calc(100vh-4.5rem)]">
        <OrderSummary order={selectedItem} />
        <CustomerDetails customerId={selectedItem?.user?.id} customerName={selectedItem?.user?.name} customerPhone={selectedItem?.user?.phone} customerEmail={selectedItem?.user?.email} 
        orderType={selectedItem?.orderType} primaryStatus={selectedItem?.primaryStatus} vendorOrderId={selectedItem?.orderInfo?.vendorOrderId} isNewHome={selectedItem?.orderInfo?.isNewHome}/>
        <div className="flex flex-row gap-3 mx-3 mt-2 justify-between rounded-lg">
          <AddressDetailsBox address={selectedItem?.pickupAddress} otherAddress={selectedItem?.deliveryAddress?.name} type="pickup" orderId={selectedItem?.id} orderNo={selectedItem?.orderNo} primaryStatus={selectedItem?.primaryStatus} subStatus={selectedItem?.subStatus} userId={selectedItem?.user?.id}
            deliveryNo={selectedItem?.orderType === 'COURIER' ? selectedItem?.orderNo: selectedItem?.deliveryInfo?.deliveryNo} orderType={selectedItem?.orderType} fleetId={selectedItem?.deliveryInfo?.fleetId} orderValidation={selectedItem?.orderValidation} customerName={selectedItem?.user?.name} customerPhone={selectedItem?.user?.phone}/>
          <AddressDetailsBox address={selectedItem?.deliveryAddress} otherAddress={selectedItem?.pickupAddress?.name} type="delivery" orderId={selectedItem?.id} orderNo={selectedItem?.orderNo} primaryStatus={selectedItem?.primaryStatus} subStatus={selectedItem?.subStatus} userId={selectedItem?.user?.id}
            deliveryNo={selectedItem?.orderType === 'COURIER' ? selectedItem?.orderNo: selectedItem?.deliveryInfo?.deliveryNo} orderType={selectedItem?.orderType} fleetId={selectedItem?.deliveryInfo?.fleetId} orderValidation={selectedItem?.orderValidation} customerName={selectedItem?.user?.name} customerPhone={selectedItem?.user?.phone}/>
        </div>
        <OrderInfo order={selectedItem} />
      </div>
      <div style={{flex: props?.fromSearch ? 1 : 0}} className="flex flex-col gap-4 rounded-lg m-h-64 h-[calc(100vh-4.5rem)]">
        <div className="bg-white rounded-lg h-1/2 w-full border p-3 border-gray-50">
          {selectedItem?.deliveryInfo?.fleetId ?
            <DeliveryInfo deliveryInfo={selectedItem?.deliveryInfo}
              orderStatus={orderStatuses.find((st: any) => st.primaryStatus === selectedItem?.primaryStatus && st.subStatus === selectedItem?.subStatus)} /> :
            <div className="card p-3 h-full border border-light rounded-lg font-base font-bold">
              Order is not assigned yet
            </div>}
        </div>
        <div className="bg-white rounded-lg h-1/2 w-full border border-gray-50 px-3">
          <OrderTimelineEvents orderId={selectedItem?.id} />
        </div>
      </div>
    </>
  )
}