import firebase from "firebase";
import { useEffect, useState } from "react";
import Select from "../../../../../../components/Select/select";
import { ORDER_STORE_NAME } from "../../../../../../contants/stores";
import { useAppSelector } from "../../../../../../redux";


export function OrderCompensationModal(props: any) {
  const compensationConfig = useAppSelector(state => state[ORDER_STORE_NAME].compensationConfig);
  const [compensationLevel, setCompensationLevel] = useState(compensationConfig.reasons[0].level);

  useEffect(() => {
    let reason = compensationConfig.reasons.find((config: any) => config.level == compensationLevel);
    let value = props.deliveryFee;
    switch (reason.type) {
      case 'AMOUNT':
        value = reason.value;
        break;
      case 'PERCENTAGE':
        value = (reason.value / 100) * props.deliveryFee;
        break;
      default:
        break;
    }
    let currentDate = new Date(); 
    let nextYear = currentDate.getFullYear() + 1; 
    currentDate.setFullYear(nextYear); 
    let oneYearFromNow = currentDate.toISOString().split('T')[0];
    const date = new Date(oneYearFromNow); // Create a new Date object from the timestamp
    const formattedDate = date.toLocaleDateString('en-US', { // Format the date using locale options
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });
    let description = reason.description + formattedDate.toString();
    let descriptionAr = reason.descriptionAr + formattedDate.toString();
    const user = {...compensationConfig.compensation.user, reference: props.userPhone};
    const discount = {...compensationConfig.compensation.discount, value: value, initialValue: value};
    const title = `MASHKOR_CARE_${props?.deliveryNo?.slice(2)}`
    reason = {...reason, title: title}
    const promoCode = {...compensationConfig.compensation, isCompensationCode: true,
      expiresAt: oneYearFromNow, user, discount, title, description, descriptionAr,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()};
    props.setCompensation({
      promoCode, 
      reason
    })
  }, [compensationLevel])

  return (
    <>
      <div className="flex justify-between items-center gap-3 my-2 py-2 mx-3">
        <div className="flex-1">
            <Select
              rules={{
                required: true,
              }} className="h-9" value={compensationLevel}
              id="reason" onChange={(e: any) => {
                setCompensationLevel(e.target.value);
              }} name="Compensation reason">
              {compensationConfig?.reasons?.map((c: any) => (
                <option key={c.level} value={c.level}>
                  level ( {c.level} ) : {c.reason} - {c.value} {`${c.type === 'AMOUNT' ? 'KD' : '% of delivery fee'}`}
                </option>
              ))}
            </Select>
            <div className="my-2" >**Please note that a promocode will be created for the customer with the selected amount.**</div>
        </div> 
      </div>
    </>
  )
}