import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes, useRoutes } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Agents from '../modules/agent/views/agentsPage';
import AuthRoute from "../modules/auth/views/AuthRoute";
import Login from '../modules/auth/views/login';
import Orders from '../modules/order/views/ordersPage';
import IRoute from "./route.interface"
import PromoCodes from "../modules/promocode/views/promoCodesPage";
import Support from "../modules/support/views/supportActionsList";
import Settings from "../modules/settings/views/settingsPage";
import OrderLogs from "../modules/order-logs/views/orderLogs";
import Earnings from "../modules/earnings/views/earningsPage";

// Core
export const AUTH = "/login";

// // Public Routes
const SIGN_IN = "/";

// Private Routes
export const ORDERS = "/orders";

const PROMO_CODES = "/promo-codes";

const AGENTS = "/agents";

const SUPPORT = "/support";

const SETTINGS = "/settings";

const ORDER_LOGS = "/order-logs";

const EARNINGS = "/earnings"

const routes: IRoute[] = [
  {
    path: '/',
    exact: true,
    component: Orders,
    name: 'Orders Page',
    protected: true
  },
  {
    path: '/cart',
    exact: true,
    component: Agents,
    name: 'Agents Page',
    protected: true
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    name: 'Login Page',
    protected: false
  },

];

const Routing = () => {
  // useRoutes(['/', '/login'].map(path => ({path, element: <Login />})))
  return (
    <BrowserRouter>
      <Toaster />
      <AuthRoute>
        <Navbar />
      </AuthRoute>
      <div className="max-w-screen overflow-auto">
      <Routes>
        <Route
          path={ORDERS}
          element={
            <AuthRoute>
              <Orders />
            </AuthRoute>
          }
        />
        <Route
          path={AGENTS}
          element={
            <AuthRoute>
              <Agents />
            </AuthRoute>
          }
        />
        <Route
          path={PROMO_CODES}
          element={
            <AuthRoute>
              <PromoCodes />
            </AuthRoute>
          }
        />
        <Route
          path={SUPPORT}
          element={
            <AuthRoute>
              <Support />
            </AuthRoute>
          }
        />
        <Route
          path={SETTINGS}
          element={
            <AuthRoute>
              <Settings />
            </AuthRoute>
          }
        />
        <Route
          path={ORDER_LOGS}
          element={
            <AuthRoute>
              <OrderLogs />
            </AuthRoute>
          }
        />
        <Route
          path={EARNINGS}
          element={
            <AuthRoute>
              <Earnings />
            </AuthRoute>
          }
        />
        {['login', '/', 'auth'].map(path => <Route key={path} path={path} element={<Login />}/>)}
        {/* <Route path={SIGN_IN} element={<Login />} /> */}
      </Routes>
      </div>
    </BrowserRouter>
  );
}
export default Routing;