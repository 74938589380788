import React, { useState } from 'react';
import { ORDER_STORE_NAME } from '../../../../contants/stores';
import { useAppSelector } from '../../../../redux';
import Modal from "../../../../components/Dialog/Modal";
import { OrderDetail } from '../details/OrderDetail';
import { search } from '../../actions/order.actions';

export default function DriverOrdersItem(props: any) {

  const toggleSearchDialog = async(orderNo: string) => {
    await search("ORDER", orderNo);
  }


  const status = useAppSelector(state => state[ORDER_STORE_NAME].orderStatuses.find((st: any) => st.subStatus === props?.order?.subStatus));
  return (
    <>
      <div title={status?.subStatus} className="flex items-center px-3 py-2 text-base font-bold text-gray-900 bg-gray-50 rounded-lg hover:bg-gray-100 group hover:shadow">
        <span className="flex flex-1 items-center whitespace-nowrap">
          {props?.order?.tripInfo  && 
            <>
              {props?.order?.tripInfo.type === "ROUND" ?
                <svg
                  fill="#000000"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="justify-center items-center w-4 h-4 mr-1">
                  <path
                    id="primary"
                    d="M14,18H9A6,6,0,0,1,5.54,7.11"
                    style={{
                      fill: "none",
                      stroke: "rgb(0, 0, 0)",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: 2,
                    }}
                  />
                  <path
                    id="primary-2"
                    data-name="primary"
                    d="M10,6h5a6,6,0,0,1,3.46,10.89"
                    style={{
                      fill: "none",
                      stroke: "rgb(0, 0, 0)",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: 2,
                    }}
                  />
                  <polyline
                    id="primary-3"
                    data-name="primary"
                    points="12 16 14 18 12 20"
                    style={{
                      fill: "none",
                      stroke: "rgb(0, 0, 0)",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: 2,
                    }}
                  />
                  <polyline
                    id="primary-4"
                    data-name="primary"
                    points="12 8 10 6 12 4"
                    style={{
                      fill: "none",
                      stroke: "rgb(0, 0, 0)",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: 2,
                    }}
                  />
                </svg>
                : <svg
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 976.729 976.729"
                  className="justify-center items-center w-4 h-4 mr-1"
                  xmlSpace="preserve"
                >
                  <g>
                    <path d="M886.884,299.426l-41.747,42.25v-49.632c0-87.183-70.928-158.11-158.109-158.11h-78.709c-28.995,0-52.5,23.505-52.5,52.5 c0,28.995,23.505,52.5,52.5,52.5h78.709c29.285,0,53.109,23.825,53.109,53.11v48.423l-42.191-41.689 c-20.625-20.379-53.865-20.18-74.245,0.445c-20.379,20.625-20.18,53.866,0.445,74.245l133.271,131.683 c9.83,9.713,23.089,15.155,36.899,15.155c0.104,0,0.211,0,0.314-0.001c13.923-0.083,27.244-5.695,37.03-15.6l129.912-131.479 c20.379-20.625,20.18-53.866-0.445-74.245C940.503,278.602,907.262,278.801,886.884,299.426z" />
                    <path d="M677.506,623.699c-20.625-20.379-53.864-20.18-74.244,0.445L471.578,757.416c-9.786,9.905-15.237,23.291-15.154,37.215 s5.695,27.244,15.6,37.03l131.479,129.912c10.232,10.11,23.567,15.155,36.898,15.155c13.541,0,27.078-5.207,37.347-15.601 c20.379-20.625,20.18-53.865-0.445-74.244l-42.25-41.747h49.634c87.183,0,158.109-70.929,158.109-158.11v-78.709 c0-28.995-23.505-52.5-52.5-52.5s-52.5,23.505-52.5,52.5v78.709c0,29.285-23.825,53.11-53.109,53.11h-48.423l41.688-42.192 C698.33,677.319,698.131,644.078,677.506,623.699z" />
                    <path d="M131.593,684.685c0,87.183,70.928,158.109,158.11,158.109h78.709c28.995,0,52.5-23.505,52.5-52.5s-23.505-52.5-52.5-52.5 h-78.709c-29.285,0-53.11-23.824-53.11-53.109v-48.423l42.192,41.688c10.232,10.11,23.567,15.155,36.898,15.155 c13.541,0,27.078-5.207,37.347-15.601c20.379-20.625,20.18-53.865-0.445-74.244L219.313,471.577 c-9.904-9.787-23.279-15.254-37.215-15.154c-13.923,0.083-27.244,5.695-37.03,15.6L15.156,603.502 c-20.379,20.625-20.18,53.866,0.445,74.245s53.867,20.18,74.245-0.445l41.747-42.25V684.685z" />
                    <path d="M373.228,15.155c-20.625-20.379-53.866-20.18-74.245,0.445c-20.379,20.625-20.18,53.866,0.445,74.245l42.25,41.747h-49.632 c-87.183,0-158.11,70.928-158.11,158.11v78.709c0,28.995,23.505,52.5,52.5,52.5c28.995,0,52.5-23.505,52.5-52.5v-78.709 c0-29.285,23.825-53.11,53.11-53.11h48.422l-41.688,42.192c-20.379,20.625-20.18,53.866,0.445,74.245 c10.231,10.11,23.566,15.155,36.897,15.155c13.541,0,27.078-5.207,37.347-15.6l131.684-133.271 c9.786-9.905,15.237-23.291,15.154-37.215c-0.083-13.923-5.695-27.244-15.6-37.03L373.228,15.155z" />
                  </g>
                </svg>}
          </>}
          <p>{props?.order?.orderNo}</p>
        </span>
        <span onClick={() => { toggleSearchDialog(props?.order?.orderNo) }} className="cursor-pointer border border-[var(--status-color)] text-white bg-[var(--status-color)] hover:bg-white hover:text-[var(--status-color)] inline-flex items-center justify-center px-2 py-0.5 text-sm ml-3 font-medium rounded"
          style={{ "--status-color": status?.color } as React.CSSProperties}
        >Details</span>
      </div>
    </>
  )
}