import { store } from "../../../redux";
import { getStatuses, fetchOrderTimeline, fetchAddressData, getchAreaDetailsById, fetchFleetConfig, fetchCancellationReasons, fetchDelayReasons, fetchBusinessConfig, fetchCompensationConfig, fetchCustomerTagsConfig, getLatLng, getBlockAndStreets, fetchPayments } from "../services/config.services";
import { actions as orderActions } from "../stores/order.store";
import { actions as customerActions } from "../../customer/stores/customer.store";
import { OrderStatus } from "../types/order.status";

export const getFleetConfig = async () => {
  const fleetConfig = await fetchFleetConfig();
  store.dispatch(orderActions.setFleetConfig(fleetConfig));
}

export const getBusinessConfig = async () => {
  const businessConfig = await fetchBusinessConfig();
  store.dispatch(orderActions.setBusinessConfig(businessConfig));

}

export const getRegionConfig = async () => {
  
}

export const getCompensationConfig = async () => {
  const compensationConfig = await fetchCompensationConfig();
  store.dispatch(orderActions.setCompensationConfig(compensationConfig));
}

export const getCustomerTagsConfig = async () => {
  const tagsConfig = await fetchCustomerTagsConfig();
  store.dispatch(customerActions.setcustomerTagsConfig(tagsConfig));
}

export const getOrderStatuses = async () => {
  const primaryStatuses = await getStatuses();
  let statuses = formatOrderStatuses(primaryStatuses);
  store.dispatch(orderActions.setPrimaryStatuses(primaryStatuses.slice(0, -1)));
  store.dispatch(orderActions.setOrderStatuses(statuses));
  // return statuses;
};

export const getCancellationReasons = async () => {
  const cancellationReasons = await fetchCancellationReasons();
  store.dispatch(orderActions.setcancellationReasons(cancellationReasons));
}

export const getDelayReasons = async () => {
  const delayReasons = await fetchDelayReasons();
  
  store.dispatch(orderActions.setDelayReasons(delayReasons));
}

export const formatOrderStatuses = (arr: any) => {
  const statuses: OrderStatus[] = [];
  arr.forEach((pStatus: any) => {
    pStatus.subStatuses.forEach((status: any) => {
      const statusObj = {
        primaryStatus: pStatus.primaryStatus,
        subStatus: status.subStatus,
        color: status.color,
        maxTimeDelayFromPreviousStatus: status.maxTimeDelayFromPreviousStatus,
        timestampName: status.timestampName,
      };
      return statuses.push(statusObj);
    });
  });
  return statuses;
};

export const getTimelineEvents = (id: string) => {
  return fetchOrderTimeline(id, {
    listeners: {
      onGetAll: (res: any) => {
        if (res.errors) {
          throw new Error("Could not fetch orders");
        }
        store.dispatch(orderActions.setOrderTimeLine(res.orderTimeLine));
      },
      onAdd: () => {},
      onUpdate: () => {},
    },
  });
};

export const getSuccessfulOrderPayments = (id: string) => {
  return fetchPayments(id, {
    listeners: {
      onGetAll: (res: any) => {
        if (res.errors) {
          throw new Error("Could not fetch payments");
        }
        console.log(res)
        store.dispatch(orderActions.setOrderPayments(res.orderPayments));
      },
      onAdd: () => {},
      onUpdate: () => {},
    },
  });
};

export const clear = () => {
  store.dispatch(orderActions.setOrderPayments(null));
}

export const indexAreas = async () => {
  const areas = await fetchAddressData();
  store.dispatch(orderActions.setAreas(areas));
  return areas;
}

export const fetchBlocks = async (id:string) => {
  const blocks = await getchAreaDetailsById(id);
  return blocks;
}

export const fetchBlocksAndStreets = async (areaId:number, blockName:string) => {
  return await getBlockAndStreets(areaId, blockName);
}

export const getInitialLatLngFromStreet = async(area_id: number, block_id: number, street_id: number) => {
  return await getLatLng(area_id, block_id, street_id);
}