import { createSlice } from "@reduxjs/toolkit"
import { PROMOCODE_STORE_NAME } from "../../../contants/stores";
import { BusyMode } from '../types/settings.busyMode';

interface SettingsState {
  activeTab: string,
  historyDocs: BusyMode[],
  businessConfig: any,
  appTagging: any,
  courierTagging: any,
  zones: any[],
  zonesSummary: any,
  fleetConfig: any
  courierBusyModeStatus: any,
  courierBusyModeTypes: any[],
  status: string,
  error: Error | undefined
}

const initialState: SettingsState = {
  activeTab: 'appTagging',
  historyDocs: [],
  businessConfig: '',
  appTagging: {},
  courierTagging: {},
  zones: [],
  zonesSummary: {},
  fleetConfig: {},
  courierBusyModeStatus: {},
  courierBusyModeTypes: [],
  status: 'idle',
  error: undefined
}

export const slice = createSlice({
  name: PROMOCODE_STORE_NAME,
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setBusinessConfig: (state, action) => {
      state.businessConfig = action.payload;
    },
    setBusyModeHistory: (state, action) => {
      state.historyDocs = action.payload;
    },
    setAppTaggingConfig: (state, action) => {
      state.appTagging = action.payload;
    },
    setCourierTaggingConfig: (state, action) => {
      state.courierTagging = action.payload;
    },
    setZones: (state, action) => {
      state.zones = action.payload;
    },
    setZonesSummary: (state, action) => {
      state.zonesSummary = action.payload;
    },
    setFleetConfig: (state, action) => {
      state.fleetConfig = action.payload;
    },
    setCourierBusyModeStatus: (state, action) => {
      state.courierBusyModeStatus = action.payload;
    },
    setCourierBusyModeTypes: (state, action) => {
      state.courierBusyModeTypes = action.payload;
    },
  },
});

export const actions = slice.actions
export const reducer = slice.reducer

