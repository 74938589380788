import { useState } from "react";
import Input from "../../../components/Input/input";
import { addWalletEntry } from "../actions/customer.actions";

export default function AddWalletEntry(props: any) {
  const [amount, setAmount] = useState(0);
  const [reason, setReason] = useState('');

  const handleChange = (e: any) => {
    if(e.target.name === 'amount') setAmount(+e.target.value);
    if(e.target.name === 'reason') setReason(e.target.value);
  }
  async function confirmWalletEntry() {
    await addWalletEntry(props.id, amount, reason); 
    props.setShowWallet(false);
  }

  return (
    <div className="p-3 mt-3 border border-light rounded-lg">
      <form className="flex justify-between items-end gap-2">

        <Input type="number" min="0" labelText="Amount" placeholder="4" name="amount" onChange={(e: any) => handleChange(e)} />
        <Input type="text" labelText="Reason" placeholder="Cancelled order" name="reason" onChange={(e: any) => handleChange(e)} value="Cancelled order"/>
        <div onClick={() => { confirmWalletEntry() }} className="cursor-pointer font-bold flex items-center text-sm border-gray-300 py-2 h-8">
          <span className="bg-indigo-500 rounded p-1 w-8 h-8 flex justify-center items-center text-white text-base">
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
          </span> 
        </div>

      </form>
      <p className="text-sm font-normal text-gray-500 mt-2">Add or remove balance from the customer wallet.</p>
    </div>
  )
}