import OrdersSearch from "./ordersSearch";

export default function OrdersList(props: any) {
  return (
    <>
      <div className="flex flex-col bg-white h-full rounded-lg">
        <OrdersSearch />
        <div className="flex-1 overflow-y-auto">
          {props.children}
        </div>
      </div>
    </>
  )
}
