import { store } from "../../../redux";
import { Delete, callCreateAgent, fetchAgentData, fetchAgents, fetchOrderTransfer, getAgentCount, reassignOnLogin, updateAgent, updateStatus } from "../services/agent.service";
import { actions as agentActions } from "../stores/agent.store";
import { actions as authActions } from "../../auth/stores/auth.store";
import { Agent } from "../types/agent.data";
import { Order } from "../../order/types/order.data";
import { triggerPubSubEventCloudFunction } from "../../../utils/pubsub";
import { timelineNames, timelineTypes } from "../../../contants/timelineEvents";
import { AGENT_STORE_NAME, AUTH_STORE_NAME } from "../../../contants/stores";
import firebase from "firebase";

export const getLoggedInAgentData = (id: string) => {
  return fetchAgentData(id, {
    listeners: {
      onUpdate: (res: any) => {
        store.dispatch(authActions.setLoggedInAgent({ ...res }));
      },
    },
  });
}
export const getAgentsData = async () => {
  try {
    // const agents = await fetchAgents();
    // if (agents) {
    //   store.dispatch(agentActions.setAgents(agents));
    // }
    return fetchAgents({
      listeners: {
        onGetAll: (res: any) => {
          if (res.errors) {
            throw new Error("Could not fetch agents");
          }
          store.dispatch(agentActions.setAgents(res.agents));
        },
        onAdd: () => {},
        onUpdate: (res: any) => {
          store.dispatch(agentActions.updateAgent({ ...res }));
        },
      },
    });
  } catch (error) {
    console.log('get-agents', error)
  }
}

export const createNewAgent = async (agent: any) => {
  try {
    const res = await callCreateAgent(agent);
    return res;
  } catch (error) {
    console.error("Error creating agent:", error);
    return error; 
  }
}


export const updateAgentStatus = async (agent: Agent, status: string, replacementAgent: Agent | undefined) => {
  try {
    console.log(agent, status, replacementAgent)
    const id = agent.id;
    updateStatus(id, status);
    if (status === "OFFLINE") await fetchOrderTransfer(agent, replacementAgent, [], "REASSIGN");

    if(status === "ONLINE" && agent) await reassignOnLogin(agent);

  } catch (error) {
    console.log("update agent status error:", error);
  }

}

export const updatePinnedOrders = async(id: string, pinnedOrders: any[]) => {
  await updateAgent(id, {pinnedOrders});
}

export const transferOrders = async (currentAgent: any, replacementAgent: any, orders: any, action: string) => {
  try {
    const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;

    orders = orders.map((order: Order) => {
      return {
      "id": order.id,
      "orderNo": order.orderNo,
      "orderType": order.orderType,
      "isBulk": order.orderInfo.isBulk,
      "isGrouped": order.orderInfo.isGrouped,
    }})
    await fetchOrderTransfer(currentAgent, replacementAgent, orders, action);
    await triggerPubSubEventCloudFunction({
      topicName: "TIMELINE_EVENT",
      data: {
        "orderId": orders[0].id,
        "orderNo": orders[0].orderNo,
        "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
        type: timelineTypes.AGENT,
        name: timelineNames.AGENT_CHANGED,
        "actorId": agent.id,
        "actorType": "AGENT",
        "agentId": agent.id,
        "agentName": agent.name,
        "referenceId": "",
        "referenceCollection": "",
        "comment": `Order reassigned from ${currentAgent.name} to ${replacementAgent.name}.`
      },
    });

  } catch (error) {
    console.log("transfer-order", error);
  }
}

export const syncAgentCount = async (id: string) => {
  const ordersCount = await getAgentCount(id);
  console.log("Orders Sync: ", ordersCount);
}

export const deleteAgent = async (id: string) => {
  await Delete(id);
}

export const updateQuery = (query: string) => {
  store.dispatch(agentActions.updateSearchQuery(query));
}

export function search(query: string) {
  const agents = store.getState()[AGENT_STORE_NAME].agents;
  
  return agents.filter((item: any) => {
    return (
      item.name.toLowerCase().includes(query.toLowerCase()) || 
      item.email.toLowerCase().includes(query.toLowerCase()) || 
      item.status.toLowerCase().includes(query.toLowerCase())
    );
  });
}