import React from "react";
import { useNavigate } from "react-router-dom";
import { AUTH_STORE_NAME } from "../../contants/stores";
import { useAppSelector } from "../../redux";
import { logoutAction } from "../../modules/auth/actions/auth.actions";

export function DrawerItem({ title, svg, onClick }) {
  return (
    <li onClick={onClick}>
      <div className="flex items-center p-2 text-base font-normal cursor-pointer rounded-lg">
        {svg}
        <span className="ml-3">{title}</span>
      </div>
    </li>
  );
}
export default function Drawer({ isOpen, setIsOpen }) {
  const agent = useAppSelector((state) => state[AUTH_STORE_NAME].loggedInAgent);
  const navigate = useNavigate();
  return (
    <div
      id="drawer-navigation"
      className={
        "fixed z-40 h-screen p-4 overflow-y-auto bg-white w-80" +
        (isOpen
          ? " transition-opacity opacity-100 duration-500 translate-x-0  "
          : " transition-all delay-500 opacity-0 hidden translate-x-full  ")
      }
      tabIndex="-1"
      aria-labelledby="drawer-navigation-label"
    >
      <h5
        id="drawer-navigation-label"
        className="text-base font-semibold text-gray-500 uppercase"
      >
        Menu
      </h5>
      <button
        type="button"
        data-drawer-dismiss="drawer-navigation"
        aria-controls="drawer-navigation"
        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center"
        onClick={() => setIsOpen(false)}
      >
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="sr-only">Close menu</span>
      </button>
      <div className="py-4 overflow-y-auto">
        <ul className="space-y-2">
          <DrawerItem
            onClick={() => {
              navigate("orders");
              setIsOpen(false);
            }}
            title="Orders"
            svg={
              <svg
                width="800px"
                height="800px"
                viewBox="0 -30.23 346.453 346.453"
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              >
                <defs>
                  <style>
                    {
                      ".a{fill:#ffffff;}.b{fill:#ececec;}.c{fill:#211715;}.d{fill:#c6c6c6;}"
                    }
                  </style>
                </defs>
                <path
                  className="a"
                  d="M208.451,232.553c.344,3.608.551,6.367.674,8.863.211,4.268.6,8.7.968,12.982.867,9.993,3.453,15.633,13.9,17.62,3.016.574,4.757,1.8,5.637,3.7s1.012,7.831-1.951,7.831c-5.5,0-99.749-.022-108.683-.022-2.895,0-3.157-5.905-2.277-7.809s2.621-3.127,5.636-3.7c10.445-1.987,13.031-7.627,13.9-17.62.372-4.283.757-8.714.967-12.982.124-2.5.331-5.255.675-8.863"
                />
                <path
                  className="a"
                  d="M2.4,20.907c.086,45.661.389,148.547.482,191.77a18.546,18.546,0,0,0,18.475,18.512c59.381.23,243.407-.142,304.174-.247a18.538,18.538,0,0,0,18.517-18.55c-.021-44.272-.092-149.625-.116-191.488A18.538,18.538,0,0,0,325.388,2.371c-63-.008-244.3-.043-304.436-.053A18.548,18.548,0,0,0,2.4,20.907Z"
                />
                <path
                  className="a"
                  d="M13.223,229.534A18.552,18.552,0,0,1,2.665,212.826c-.01-4.638-.022-9.964-.049-15.839,44.718-.075,333.748-1.086,338.735-1.37l2.46-.141c.016,6.342.019,12.089.021,17.065a18.538,18.538,0,0,1-18.517,18.55c-60.768.1-244.793.477-304.174.248A18.47,18.47,0,0,1,13.223,229.534Z"
                />
                <path
                  className="b"
                  d="M20.976,182.6c59.381.179,245.734-.112,306.5-.194,0-16.368-.144-145.885-.144-164.489-63-.007-246.63-.034-306.764-.042C20.57,35.759,20.976,162.988,20.976,182.6Z"
                />
                <path
                  className="c"
                  d="M206.051,232.553c.657,6.953.957,13.932,1.559,20.891.4,4.648.9,9.681,3.559,13.673,2.641,3.97,6.91,6.095,11.475,7.072a9.586,9.586,0,0,1,3.754,1.253c.069.05.6.532.589.517a6.042,6.042,0,0,1,.442.716c.307.5-.01-.339.123.364.031.165.2,1.072.158.707a10.723,10.723,0,0,1,.064,1.552,4.937,4.937,0,0,1-.078.92,7.124,7.124,0,0,1-.183.718c-.1.275-.1.3-.015.071a3.314,3.314,0,0,1-.216.375c.022-.166.543-.236.228-.233-.188,0-.376,0-.565,0h-7.387l-18.223,0-48.215-.01-33.169-.006c-.234,0-.468,0-.7,0-.4.009-.029-.017-.033.043-.007.106-.551-.354.023.027q.321.285.018,0,.222.335-.014-.019a5.018,5.018,0,0,1-.539-1.893,11.588,11.588,0,0,1-.011-1.548c0,.038.091-.74.034-.386-.041.247.107-.432.125-.507.14-.58-.37.544.062-.174a5.837,5.837,0,0,1,.442-.716s.489-.445.589-.517a9.581,9.581,0,0,1,3.753-1.253,21.316,21.316,0,0,0,7.069-2.733,16.537,16.537,0,0,0,6.919-10.488,55.561,55.561,0,0,0,1.088-8c.318-3.688.624-7.377.819-11.074.165-3.119.405-6.229.7-9.338a2.418,2.418,0,0,0-2.4-2.4,2.451,2.451,0,0,0-2.4,2.4c-.673,7.112-.98,14.251-1.6,21.368a35.452,35.452,0,0,1-1.195,7.13c-.129.43-.27.857-.429,1.277a6.892,6.892,0,0,1-.485,1.077,13.2,13.2,0,0,1-.754,1.29,4.274,4.274,0,0,1-.674.873,11.661,11.661,0,0,1-4.751,3.1c-2.664.956-5.667,1.033-8.082,2.626a7.932,7.932,0,0,0-3.61,6.2,9.946,9.946,0,0,0,1.373,6.372c1.556,2.221,3.844,2.057,6.2,2.057h6.15l19.443,0,49.447.01c10.271,0,20.548.105,30.818.006,4.78-.045,5.617-5.305,5.048-9.085a7.992,7.992,0,0,0-3.833-5.731c-2.464-1.491-5.456-1.562-8.11-2.567a10.878,10.878,0,0,1-6.883-7.78,38.623,38.623,0,0,1-1.159-7.342c-.333-3.847-.657-7.7-.86-11.553-.165-3.119-.405-6.229-.7-9.338a2.466,2.466,0,0,0-2.4-2.4,2.417,2.417,0,0,0-2.4,2.4Z"
                />
                <path
                  className="c"
                  d="M142.061,271.959h62.327c3.089,0,3.094-4.8,0-4.8H142.061c-3.089,0-3.094,4.8,0,4.8Z"
                />
                <path
                  className="c"
                  d="M0,21.056Q.051,48.074.126,75.092.21,108,.3,140.9q.078,29.211.151,58.422.014,5.576.026,11.15c.011,5.046.677,9.82,3.624,14.076a21.127,21.127,0,0,0,12.671,8.664c3.408.753,6.977.533,10.448.543q21.351.061,42.7.053,27.781.006,55.562-.021,30.625-.024,61.25-.068,29.883-.041,59.766-.092,25.638-.042,51.274-.089l19.972-.036c2.978-.006,6,.138,8.974-.055a21.109,21.109,0,0,0,14.069-6.585,21.385,21.385,0,0,0,5.658-14.612c.012-3.581,0-7.163,0-10.745l-.015-26.211-.039-63.489-.039-61.456-.015-24.136c0-4.266.2-8.545-1.378-12.6A21.059,21.059,0,0,0,334.638,2.275C330.552.308,326.389.12,321.955.12l-9.347,0L290.7.115,236.936.106,175.756.1,114.4.084,60.125.074l-22.3,0-9.575,0c-3.041,0-6.174-.2-9.2.1A21.2,21.2,0,0,0,0,21.056c-.033,3.089,4.767,3.092,4.8,0A16.393,16.393,0,0,1,11.05,8.233c3.565-2.773,7.616-3.366,11.987-3.366l18.4,0,48.419.009,58.074.011,61.255.011,57.1.01,46.467.007,9.076,0c4.071,0,7.924.027,11.578,2.127a16.312,16.312,0,0,1,7.958,11.59c.536,3.448.171,7.2.173,10.68q.017,26.946.034,53.891.019,31.95.04,63.9.017,27.709.032,55.418c0,3.644.161,7.329-.018,10.968a16.154,16.154,0,0,1-6.579,12.092c-5.878,4.257-13.764,3.111-20.635,3.124l-44.718.08q-27.93.048-55.859.092-30.177.045-60.352.08-29.133.033-58.264.046-24.548.009-49.1-.014-9.451-.012-18.9-.037c-3.164-.009-6.533.287-9.618-.476a16.365,16.365,0,0,1-10.816-8.857c-1.843-3.853-1.507-8.094-1.516-12.239l-.027-11.3q-.035-13.725-.07-27.449Q5.089,136.651,5,104.666q-.079-30.354-.154-60.709-.026-11.45-.049-22.9C4.794,17.968-.006,17.962,0,21.056Z"
                />
                <path
                  className="c"
                  d="M2.921,199.391q7.6,0,15.192-.008l28.1-.023,37.738-.037,44.1-.049,47.36-.06q23.492-.031,46.983-.068,21.751-.035,43.5-.075,18.373-.035,36.748-.08,13.359-.035,26.717-.084c4.47-.018,8.942-.012,13.411-.086.294,0,.588,0,.882-.021,3.072-.168,3.091-4.969,0-4.8-3.208.175-6.451.07-9.662.086q-11.883.06-23.768.085-17.3.048-34.6.081-21.078.043-42.155.076-23.307.039-46.613.07-23.72.033-47.437.062l-45.154.052-39.594.04-30.76.027-18.649.012H2.921c-3.088,0-3.093,4.8,0,4.8Z"
                />
                <path
                  className="d"
                  d="M180.61,214.323c0,4.023-3.011,6.978-7.306,6.978-4.926,0-7.466-3.366-7.466-6.981a7.08,7.08,0,0,1,7.179-7.18A7.471,7.471,0,0,1,180.61,214.323Z"
                />
                <path
                  className="c"
                  d="M136.274,69.583c9.671.147,19.351.032,29.022.018q20.72-.03,41.439-.045,3.816,0,7.631.012c.74,0,2.229-.269,2.858.129.835.53-.041,2.452-.212,3.2q-2.57,11.31-5.177,22.611-1.232,5.351-2.466,10.7-.459,1.983-.918,3.967c-.3,1.31-.583,1.385-1.865,1.389q-8.7.023-17.41.059-18.4.069-36.8.134c-.561,0-1.122,0-1.683,0-3.088,0-3.094,4.8,0,4.8,11.538,0,23.075-.079,34.613-.122q9.693-.036,19.387-.069a23.038,23.038,0,0,0,3.817-.112,5.72,5.72,0,0,0,4.529-4.623q1.062-4.577,2.115-9.156,2.78-12.054,5.534-24.113.561-2.459,1.12-4.917c.48-2.119.959-4.21-.352-6.163-2.011-3-5.625-2.516-8.765-2.523-13.569-.03-27.137.006-40.706.027-11.392.017-22.785.083-34.177.007-.51,0-1.021-.007-1.532-.015-3.09-.047-3.091,4.753,0,4.8Z"
                />
                <path
                  className="c"
                  d="M143.036,92.123c7.651,0,15.3-.033,22.954-.054l30.026-.088c2.721-.007,5.441-.019,8.162-.02,3.088,0,3.093-4.8,0-4.8-6.724,0-13.448.035-20.172.055q-15.4.045-30.8.087-5.085.013-10.169.02c-3.088,0-3.094,4.8,0,4.8Z"
                />
                <path
                  className="c"
                  d="M155.666,67.4c1.417,12.776,2.648,25.572,4.048,38.35.289,2.631.569,5.265.911,7.889a2.479,2.479,0,0,0,2.4,2.4,2.413,2.413,0,0,0,2.4-2.4c-1.678-12.864-2.813-25.812-4.161-38.713-.262-2.509-.52-5.019-.8-7.526a2.472,2.472,0,0,0-2.4-2.4,2.416,2.416,0,0,0-2.4,2.4Z"
                />
                <path
                  className="c"
                  d="M186.134,76.186q-1.232,15.351-2.4,30.706c-.177,2.364-.352,4.729-.511,7.094a2.4,2.4,0,0,0,4.8,0c.695-10.378,1.556-20.746,2.381-31.113q.266-3.343.534-6.687a2.419,2.419,0,0,0-2.4-2.4,2.448,2.448,0,0,0-2.4,2.4Z"
                />
                <path
                  className="c"
                  d="M103.821,51.51q3.024,0,6.048.007l3.389.006a17.676,17.676,0,0,1,3.229,0c1.145.231,1.41,3.07,1.691,4.085,1.28,4.631,2.6,9.251,3.917,13.87q5.216,18.249,10.55,36.465,2.25,7.706,4.521,15.406.811,2.75,1.631,5.5a13.413,13.413,0,0,0,1,2.854,5.753,5.753,0,0,0,5.069,2.8c10.193.166,20.4.036,30.6.054q14.562.025,29.125.047h1.264c3.088,0,3.094-4.8,0-4.8q-12.929,0-25.857-.04-16.43-.029-32.857-.058c-.753,0-1.519.038-2.27,0-.906-.051-.969-.548-1.2-1.326q-.769-2.565-1.527-5.132-2.229-7.532-4.428-15.072-5.334-18.252-10.578-36.532-2.055-7.179-4.069-14.37c-.438-1.578-.769-3.234-1.323-4.775-1.764-4.905-7.751-3.775-11.874-3.782q-3.024,0-6.048-.007c-3.089,0-3.094,4.8,0,4.8Z"
                />
                <path
                  className="c"
                  d="M152.578,142.016c.22,0,.439.019.659.025.68.019-.445-.115.207.026a9.566,9.566,0,0,1,1,.255c.087.029.687.291.173.05.119.056.238.112.354.172.174.091.343.188.509.292.1.067.726.574.295.192a9.357,9.357,0,0,1,.692.659c.006.006.418.477.181.194s.142.208.15.219c.19.285.314.647.519.914s-.16-.5.021.046c.082.246.168.488.233.739.045.173.168,1.12.123.523.025.338.045.673.039,1.012q-.006.312-.03.621c-.008.108-.122.756-.009.155a10.594,10.594,0,0,1-.256,1.112c-.054.175-.12.346-.178.519.024-.073.225-.463.031-.079-.158.314-.338.606-.515.909-.063.108-.279.348.072-.072-.109.13-.216.261-.333.385-.1.1-.2.2-.3.3s-.619.465-.1.113c-.238.162-.473.322-.726.461-.154.084-.313.157-.471.234.557-.271.026-.02-.094.021a7.945,7.945,0,0,1-.9.244c-.1.022-.739.118-.126.042-.2.025-.4.042-.6.054a10.51,10.51,0,0,1-1.255,0c-.137-.008-.273-.021-.409-.032-.657-.053.477.124-.148-.026-.308-.074-.616-.132-.918-.226-.175-.054-.345-.118-.517-.18.582.209-.021-.022-.15-.091a7.906,7.906,0,0,1-.8-.5c.53.365-.166-.174-.279-.289s-.22-.258-.339-.377c.346.346.152.215.067.082-.22-.344-.415-.687-.6-1.05.036.069.178.49.037.079-.059-.173-.122-.344-.174-.519-.09-.3-.147-.611-.216-.92-.14-.624.018.509-.021-.145-.012-.2-.033-.408-.041-.613a10.924,10.924,0,0,1,.034-1.227c-.04.532-.018.095.016-.049.051-.223.1-.446.159-.667.054-.187.119-.369.183-.553.2-.58-.231.4.063-.133.2-.365.435-.7.643-1.061.177-.3-.372.379.041-.036.18-.181.345-.373.533-.547.093-.086.192-.168.288-.252-.435.382.19-.124.3-.189q.207-.131.422-.244c.108-.058.758-.326.235-.131a8.475,8.475,0,0,1,1.168-.349c.086-.018.758-.1.195-.045.291-.027.58-.042.872-.045a2.4,2.4,0,0,0,0-4.8,9.945,9.945,0,0,0-9.566,7.109c-1.09,3.781.015,8.375,3.186,10.825a11.064,11.064,0,0,0,11.531.792c3.485-1.85,5.173-6,4.739-9.817a9.942,9.942,0,0,0-9.89-8.909,2.4,2.4,0,0,0,0,4.8Z"
                />
                <path
                  className="c"
                  d="M197.431,142.016c.22,0,.439.019.659.025.68.019-.444-.115.207.026a9.52,9.52,0,0,1,1,.255c.087.029.686.291.172.05.119.056.238.112.355.172.173.091.343.188.508.292.106.067.727.574.295.192a9.371,9.371,0,0,1,.693.659c0,.006.418.477.181.194s.142.208.149.219c.19.285.314.647.519.914s-.16-.5.022.046c.081.246.167.488.233.739.045.173.167,1.12.123.523.025.338.045.673.038,1.012q0,.312-.03.621c-.008.108-.122.756-.009.155a10.114,10.114,0,0,1-.256,1.112c-.053.175-.119.346-.178.519.025-.073.225-.463.032-.079-.158.314-.338.606-.515.909-.063.108-.279.348.072-.072-.109.13-.217.261-.333.385-.1.1-.2.2-.3.3s-.619.465-.1.113c-.237.162-.473.322-.726.461-.154.084-.312.157-.47.234.557-.271.025-.02-.094.021a7.962,7.962,0,0,1-.9.244c-.1.022-.739.118-.125.042-.2.025-.4.042-.6.054a10.526,10.526,0,0,1-1.256,0c-.136-.008-.272-.021-.409-.032-.656-.053.477.124-.148-.026-.307-.074-.615-.132-.918-.226-.175-.054-.344-.118-.516-.18.581.209-.022-.022-.151-.091a8,8,0,0,1-.8-.5c.529.365-.166-.174-.279-.289s-.22-.258-.339-.377c.345.346.152.215.066.082-.22-.344-.415-.687-.6-1.05.036.069.177.49.036.079-.059-.173-.121-.344-.173-.519-.09-.3-.148-.611-.217-.92-.139-.624.018.509-.021-.145-.012-.2-.032-.408-.04-.613a10.692,10.692,0,0,1,.033-1.227c-.04.532-.017.095.016-.049.052-.223.1-.446.16-.667.053-.187.118-.369.182-.553.2-.58-.23.4.064-.133.2-.365.434-.7.643-1.061.176-.3-.372.379.04-.036.18-.181.345-.373.533-.547.094-.086.192-.168.288-.252-.434.382.191-.124.3-.189.138-.087.278-.168.422-.244.108-.058.757-.326.234-.131a8.488,8.488,0,0,1,1.169-.349c.085-.018.757-.1.194-.045.291-.027.58-.042.872-.045a2.4,2.4,0,0,0,0-4.8,9.946,9.946,0,0,0-9.566,7.109c-1.089,3.781.015,8.375,3.187,10.825a11.062,11.062,0,0,0,11.53.792c3.485-1.85,5.173-6,4.739-9.817a9.941,9.941,0,0,0-9.89-8.909,2.4,2.4,0,0,0,0,4.8Z"
                />
              </svg>
            }
          />
          {agent.isAdmin || agent.isSuperAdmin ? (
            <DrawerItem
              onClick={() => {
                navigate("agents");
                setIsOpen(false);
              }}
              title="Agents"
              svg={
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-90"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              }
            />
          ) : (
            <></>
          )}
          {agent.isAdmin || agent.isSuperAdmin ? (
            <DrawerItem
              onClick={() => {
                navigate("promo-codes");
                setIsOpen(false);
              }}
              title="Promo codes"
              svg={
                <svg
                  width="800px"
                  height="800px"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-90"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.4479 0.435794L5.31888 1.56481C5.22738 1.65631 5.10328 1.70772 4.97389 1.70772H3.19561C2.37387 1.70772 1.70772 2.37387 1.70772 3.19561V4.97389C1.70772 5.10328 1.65631 5.22738 1.56481 5.31888L0.435794 6.4479C-0.145264 7.02896 -0.145265 7.97104 0.435794 8.5521L1.56481 9.68112C1.65631 9.77262 1.70772 9.89672 1.70772 10.0261V11.8044C1.70772 12.6261 2.37387 13.2923 3.19561 13.2923H4.97389C5.10328 13.2923 5.22738 13.3437 5.31888 13.4352L6.4479 14.5642C7.02896 15.1453 7.97104 15.1453 8.5521 14.5642L9.68112 13.4352C9.77262 13.3437 9.89672 13.2923 10.0261 13.2923H11.8044C12.6261 13.2923 13.2923 12.6261 13.2923 11.8044V10.0261C13.2923 9.89672 13.3437 9.77262 13.4352 9.68112L14.5642 8.5521C15.1453 7.97104 15.1453 7.02896 14.5642 6.4479L13.4352 5.31888C13.3437 5.22738 13.2923 5.10328 13.2923 4.97389V3.19561C13.2923 2.37387 12.6261 1.70772 11.8044 1.70772H10.0261C9.89672 1.70772 9.77262 1.65631 9.68112 1.56481L8.5521 0.435794C7.97104 -0.145264 7.02896 -0.145265 6.4479 0.435794ZM4.64648 9.64645L9.64648 4.64645L10.3536 5.35356L5.35359 10.3536L4.64648 9.64645ZM5 5V6H6V5H5ZM9 10H10V9H9V10Z"
                    fill="currentColor"
                  />
                </svg>
              }
            />
          ) : (
            <></>
          )}
          {agent.isSuperAdmin ? (
            <DrawerItem
              onClick={() => {
                navigate("support");
                setIsOpen(false);
              }}
              title="Support"
              svg={
                <svg
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="256px"
                  height="256px"
                  viewBox="0 0 256 256"
                  enableBackground="new 0 0 256 256"
                  xmlSpace="preserve"
                  aria-hidden="true"
                  fill="currentColor"
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-90"
                >
                  <path d="M136.896,147.739V92.723h45.017v55.016H136.896z M159.806,85.007c19.885,0,36.202-15.239,37.932-34.673h-75.864 C123.605,69.769,139.922,85.007,159.806,85.007z M197.899,40.093c0-21.038-17.055-38.092-38.092-38.092 c-17.423,0-32.103,11.702-36.637,27.672H96.453v11.807h101.411C197.882,41.019,197.899,40.558,197.899,40.093z M207.295,92.723 H191.01v86.543h13.671v65.878h-90.518l0.006-65.878h13.63V92.723h-16.531c-25.57,0-46.299,20.729-46.299,46.299v40.244 l-28.533-0.042v-40.34c8.81-3.885,14.693-12.47,14.693-22.711c0-10.253-6.039-19.515-14.855-23.393v23.704H16.522V92.779 C7.974,96.766,2,106.118,2,116.173c0,10.049,6.213,18.719,14.758,22.711v40.34C8.672,179.799,2,186.539,2,194.771 c0,8.232,6.385,14.959,14.472,15.534v23.93h19.697v-23.847l40.351,0.059c10.604,0,19.258-8.26,19.934-18.694v-44.014h8.855v106.26 h108.228v-106.26h8.878l0.082,90.42c0,8.611,6.817,15.841,15.427,15.841h0.346c8.61,0,15.73-6.897,15.73-15.507v-99.47 C254,113.452,232.865,92.723,207.295,92.723z" />
                </svg>
              }
            />
          ) : (
            <></>
          )}
          {agent.isAdmin || agent.isSuperAdmin ? (
            <DrawerItem
              onClick={() => {
                navigate("settings");
                setIsOpen(false);
              }}
              title="Settings"
              svg={
                <svg
                  fill="currentColor"
                  width="800px"
                  height="800px"
                  viewBox="0 0 24 24"
                  id="settings"
                  data-name="Line Color"
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-90"
                >
                  <circle
                    id="secondary"
                    cx={12}
                    cy={12}
                    r={3}
                    style={{
                      fill: "none",
                      stroke: "rgb(44, 169, 188)",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: 2,
                    }}
                  />
                  <path
                    id="primary"
                    d="M20,10h-.59a1,1,0,0,1-.94-.67v0a1,1,0,0,1,.2-1.14l.41-.41a1,1,0,0,0,0-1.42L17.66,4.93a1,1,0,0,0-1.42,0l-.41.41a1,1,0,0,1-1.14.2h0A1,1,0,0,1,14,4.59V4a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1v.59a1,1,0,0,1-.67.94h0a1,1,0,0,1-1.14-.2l-.41-.41a1,1,0,0,0-1.42,0L4.93,6.34a1,1,0,0,0,0,1.42l.41.41a1,1,0,0,1,.2,1.14v0a1,1,0,0,1-.94.67H4a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h.59a1,1,0,0,1,.94.67v0a1,1,0,0,1-.2,1.14l-.41.41a1,1,0,0,0,0,1.42l1.41,1.41a1,1,0,0,0,1.42,0l.41-.41a1,1,0,0,1,1.14-.2h0a1,1,0,0,1,.67.94V20a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1v-.59a1,1,0,0,1,.67-.94h0a1,1,0,0,1,1.14.2l.41.41a1,1,0,0,0,1.42,0l1.41-1.41a1,1,0,0,0,0-1.42l-.41-.41a1,1,0,0,1-.2-1.14v0a1,1,0,0,1,.94-.67H20a1,1,0,0,0,1-1V11A1,1,0,0,0,20,10Z"
                    style={{
                      fill: "none",
                      stroke: "currentColor",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: 2,
                    }}
                  />
                </svg>
              }
            />
          ) : (
            <></>
          )}
          {agent.isAdmin || agent.isSuperAdmin ? (
            <DrawerItem
              onClick={() => {
                navigate("order-logs");
                setIsOpen(false);
              }}
              title="Buy logs"
              svg={
                <svg
                  fill="currentColor"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-90"
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <g>
                        <path d="M256,121.655c-74.072,0-134.334,60.263-134.334,134.334S181.929,390.323,256,390.323s134.334-60.263,134.334-134.334 S330.072,121.655,256,121.655z M256,356.223c-55.269,0-100.233-44.965-100.233-100.233c0-55.268,44.965-100.233,100.233-100.233 c55.268,0,100.233,44.965,100.233,100.233C356.233,311.258,311.269,356.223,256,356.223z" />
                        <path fill="rgb(44, 169, 188)" d="M263.005,244.436v-21.087c9.388,0.722,12.132,5.344,16.898,5.344c6.355,0,8.955-7.943,8.955-11.843 c0-9.965-16.898-12.276-25.853-12.565v-3.61c0-2.022-2.745-3.9-5.489-3.9c-3.177,0-5.488,1.878-5.488,3.9v4.044 c-15.309,2.167-28.885,10.977-28.885,29.319c0,18.487,15.599,24.697,28.885,29.753v23.396 c-10.687-1.878-15.599-10.398-21.664-10.398c-5.488,0-9.821,7.221-9.821,12.132c0,9.244,14.154,18.197,31.485,18.775v3.61 c0,2.022,2.311,3.9,5.488,3.9c2.744,0,5.489-1.877,5.489-3.9v-4.189c16.898-2.744,28.452-13.575,28.452-31.485 C291.458,256.134,276.148,249.346,263.005,244.436z M253.473,240.969c-5.488-2.311-9.244-4.91-9.244-9.099 c0-3.466,2.744-6.788,9.244-8.088V240.969z M261.561,287.042v-19.353c5.199,2.455,8.81,5.489,8.81,10.254 C270.371,283.142,266.471,285.886,261.561,287.042z" />
                        <path d="M451.333,36.953l-59.64-34.434c-19.508-11.267-36.585,18.253-17.05,29.531l4.533,2.617H256 c-9.416,0-17.05,7.634-17.05,17.05V88.34c0,9.416,7.634,17.05,17.05,17.05c9.416,0,17.05-7.634,17.05-17.05V68.769h106.123 l-4.532,2.617c-19.512,11.266-2.481,40.809,17.05,29.531l59.64-34.433C462.795,59.869,462.561,43.433,451.333,36.953z" />
                        <path d="M256,406.587c-9.416,0-17.05,7.634-17.05,17.05v19.573H132.826l4.532-2.617c19.509-11.263,2.487-40.813-17.05-29.531 l-59.64,34.433c-11.27,6.504-11.425,22.937,0,29.531l59.64,34.432c19.648,11.348,36.435-18.339,17.05-29.531l-4.532-2.617 h123.175c9.416,0,17.05-7.634,17.05-17.05v-36.623C273.05,414.221,265.418,406.587,256,406.587z" />
                      </g>
                    </g>
                  </g>
                </svg>
              }
            />
          ) : (
            <></>
          )}
          {agent.isSuperAdmin ? (
            <DrawerItem
              onClick={() => {
                navigate("earnings");
                setIsOpen(false);
              }}
              title="Earnings"
              svg={
                <svg
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 480.008 480.008"
                className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-90"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path d="M319.64,114.26c0.136-0.584,0.36-1.632,0.36-2.256v-48c0-4.424-3.576-8-8-8s-8,3.576-8,8V99.98 c-17.64-12.736-39.216-19.976-62.56-19.976c-0.232,0-0.472,0-0.696,0c-17.592,0-34.128,4.2-48.744,11.832V56.004 c0-4.424-3.584-8-8-8s-8,3.576-8,8v46.128c-25.536,19.768-41.992,51.672-41.768,86.384 c0.376,58.872,48.392,107.488,107.184,107.488c0.232,0,0.472,0,0.696,0c59.112,0,106.896-49.76,106.512-108.872 C348.448,159.132,337.408,133.26,319.64,114.26z M242.008,280.004c-0.2,0-0.392,0-0.592,0c-50.016,0-90.864-41.328-91.184-91.416 c-0.328-50.28,40.328-92.584,90.608-92.584c0.2,0,0.392,0,0.592,0c50.016,0,90.864,41.328,91.184,91.416 C332.944,237.7,292.296,280.004,242.008,280.004z" />
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M184,0.004c-4.416,0-8,3.576-8,8v16c0,4.424,3.584,8,8,8s8-3.576,8-8v-16C192,3.58,188.416,0.004,184,0.004z" />
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M312,8.004c-4.424,0-8,3.576-8,8v16c0,4.424,3.576,8,8,8s8-3.576,8-8v-16C320,11.58,316.424,8.004,312,8.004z" />
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M96,184.004c-4.416,0-8,3.576-8,8v64c0,4.424,3.584,8,8,8s8-3.576,8-8v-64C104,187.58,100.416,184.004,96,184.004z" />
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M96,136.004c-4.416,0-8,3.576-8,8v16c0,4.424,3.584,8,8,8s8-3.576,8-8v-16C104,139.58,100.416,136.004,96,136.004z" />
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M400,112.004c-4.424,0-8,3.576-8,8v64c0,4.424,3.576,8,8,8s8-3.576,8-8v-64C408,115.58,404.424,112.004,400,112.004z" />
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M400,64.004c-4.424,0-8,3.576-8,8v16c0,4.424,3.576,8,8,8s8-3.576,8-8v-16C408,67.58,404.424,64.004,400,64.004z" />
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M271.896,207.644l-0.056-7.992c-0.04-6.416-2.568-12.424-7.136-16.928c-4.568-4.496-10.856-7.088-17.016-6.872l-16,0.152 c-0.016,0-0.032,0-0.048,0c-4.384,0-7.976-3.608-8-8l-0.048-8.016c-0.024-4.416,3.536-8.04,7.952-8.064l7.808-0.056 c0.256,0.016,0.48,0.136,0.752,0.136c0.016,0,0.032,0,0.048,0c0.272,0,0.504-0.136,0.768-0.16l22.608-0.144 c4.424-0.032,7.984-3.48,7.952-7.896c-0.032-4.408-3.6-7.8-8-7.8c-0.016,0-0.032,0-0.048,0l-15.384-0.056l-0.048-7.92 c-0.024-4.4-3.608-8.024-8-8.024c-0.016,0-0.032,0-0.048,0c-4.416,0.032-7.976,3.688-7.952,8.104l0.048,7.896h-0.608 c-13.232,0-23.928,10.864-23.848,24.104l0.048,8.024c0.088,13.184,10.84,23.872,24.008,23.872c0.048,0,0.104,0,0.152,0h16 c0.016,0,0.032,0,0.048,0c2.112,0,4.104,0.72,5.624,2.2c1.512,1.504,2.36,3.456,2.376,5.6l0.056,7.968 c0.032,4.416-3.536,8.016-7.952,8.04l-32,0.2c-4.416,0.032-7.976,3.632-7.952,8.048c0.024,4.4,3.608,7.944,8,7.944 c0.016,0,0.032,0,0.048,0l16-0.104l0.048,8.16c0.024,4.4,3.608,7.944,8,7.944c0.016,0,0.032,0,0.048,0 c4.416-0.032,7.984-3.632,7.952-8.056l-0.048-8.152C261.28,231.708,271.976,220.884,271.896,207.644z" />
                  </g>
                </g>
                <g>
                  <g>
                    <circle cx={184} cy={192.004} r={8} />
                  </g>
                </g>
                <g>
                  <g>
                    <circle cx={296} cy={192.004} r={8} />
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M472,312.004h-88c-10.416,0-19.216,6.712-22.528,16h-40.048c-4.328,0-7.808,3.448-7.952,7.744l-66.744-15.144 c-4.816-1.088-9.816-0.712-14.416,1.12l-62.64,24.848c-8.088,3.208-14.016,9.72-16.456,17.592l-122.328-35.88 c-7.456-2.168-15.28-0.784-21.456,3.84C3.44,336.62,0,343.436,0,350.836v17.352c0,7.936,4.032,15.28,10.776,19.64l88.872,57.512 c0.784,0.504,1.656,0.872,2.568,1.08l132.32,30.216c1.792,0.416,3.632,0.616,5.464,0.616c1.84,0,3.672-0.2,5.464-0.616 l69.64-15.976c1.448,1.976,3.68,3.344,6.328,3.344h40.048c3.312,9.288,12.112,16,22.528,16h88c4.424,0,8-3.576,8-8v-152 C480,315.58,476.424,312.004,472,312.004z M311.496,445.076l-69.616,15.968c-1.224,0.288-2.528,0.288-3.768,0L107.16,431.14 l-87.696-56.752C17.296,372.98,16,370.668,16,368.18v-17.352c0-3.168,1.888-5.048,3.016-5.888c2.112-1.576,4.784-2.072,7.36-1.296 l127.016,37.304c3.728,11.024,14.392,19.056,26.928,19.056H240c4.416,0,8-3.576,8-8s-3.584-8-8-8h-59.68 c-6.792,0-12.32-5.256-12.32-11.68c0-4.752,2.976-9.016,7.584-10.848l62.64-24.864c1.584-0.632,3.312-0.768,4.976-0.392 l68.768,15.6L311.496,445.076z M360,448.004h-32v-104h32V448.004z M464,464.004h-80c-4.416,0-8-3.584-8-8v-120 c0-4.416,3.584-8,8-8h80V464.004z" />
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M433.432,432.004h-24c-4.424,0-8,3.576-8,8s3.576,8,8,8h24c4.424,0,8-3.576,8-8S437.856,432.004,433.432,432.004z" />
                  </g>
                </g>
              </svg>
              }
            />
          ) : (
            <></>
          )}
          <DrawerItem
            onClick={() => {
              logoutAction();
              navigate("/");
              setIsOpen(false);
            }}
            title="Logout"
            svg={
              <svg
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                  clipRule="evenodd"
                ></path>
              </svg>
            }
          />
        </ul>
      </div>
    </div>
  );
}
