import { store } from "../../../redux";
import { actions as authActions } from "../stores/auth.store"
import { authenticateLogin, logout, resetPassword } from "../services/auth.services";
import { fetchOrderTransfer, reassignOnLogin, updateStatus } from "../../agent/services/agent.service";
import { AGENT_STORE_NAME, AUTH_STORE_NAME, ORDER_STORE_NAME } from "../../../contants/stores";
import { Order } from "../../order/types/order.data";

export const loginAction = async (email: string, password: string) => {
  try {
    await authenticateLogin(email, password);
    const agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;
    console.log("loginAction", agent)
    store.dispatch(authActions.setLoggedInAgent(agent));
    if(agent) await reassignOnLogin(agent);
    return agent;
  } catch (error) {
    console.log("error-loginAction: ", error);
    throw error;
  }
}

export const logoutAction = async () => {
  try {
    let agent = store.getState()[AUTH_STORE_NAME].loggedInAgent;
    const isAgent = !agent.isAdmin && !agent.isSuperAdmin;
    if(isAgent) { 
      agent = {
        "id": agent.id,
        "name": agent.name,
        "email": agent.email
      }

      let activeAgentsList = store.getState()[AGENT_STORE_NAME].agents
      .filter((a: any) => !a.isAdmin && a.status === "ONLINE" && a.id !== agent.id)
      
      let replacementAgent;

      if(activeAgentsList.length) {
        replacementAgent = activeAgentsList.reduce(function(prev: any, curr: any) {
          console.log(prev, curr)
          return ((prev?.activeOrders ?? 0) + (prev?.activeGroupOrders) ?? 0) < (curr?.activeOrders ?? 0) + (curr?.activeGroupOrders ?? 0) ? prev : curr;
        }, null);
        if(replacementAgent) {
          replacementAgent = {
            "id": replacementAgent?.id,
            "name": replacementAgent?.name,
            "email": replacementAgent?.email
          }
        }
      }
      
      if(! replacementAgent) replacementAgent = null; 

      const orders = store.getState()[ORDER_STORE_NAME].orders.map((order: Order) => {
        return {
        "id": order.id,
        "orderNo": order.orderNo,
        "orderType": order.orderType,
        "isBulk": order.orderInfo.isBulk,
        "isGrouped": order.orderInfo.isGrouped,
      }});
      console.log(agent, replacementAgent, orders);
      
      await fetchOrderTransfer(agent, replacementAgent, orders, "REASSIGN");
    }
    
    await updateStatus(agent.id, "OFFLINE");
    await logout();
    store.dispatch(authActions.setLoggedInAgent<any>(null));
  } catch (error) {
    console.log("error-logoutAction: ", error);
    throw error;
  }
  
}

export const updateAgentPasswrd = async(email: string) => {
  await resetPassword(email); 
}

