import { useEffect, useState } from "react"
import { getBuyOrders, updateLogsVerification } from "../actions/order-logs.actions"
import { useAppSelector } from "../../../redux";
import { ORDER_LOGS_STORE_NAME } from "../../../contants/stores";
import { OrderLog } from "../types/order-logs";
import { formatDate } from "../../../utils/helpers";
import OrderInvoiceModel from "./orderInvoiceModel";
import InfiniteScroll from "react-infinite-scroll-component";

export default function OrderLogs() {
  const orderLogs = useAppSelector(state => state[ORDER_LOGS_STORE_NAME].orders);
  const hasMore = useAppSelector(state => state[ORDER_LOGS_STORE_NAME].hasMore);

  const yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date);
  yesterday.setHours(0, 0, 0, 0);

  const [invoice, setInvoice] = useState('');


  useEffect(() => {
    getBuyOrders();
  }, []);

  const verify = async (id: string) => {
    await updateLogsVerification(id);
  }
  return (
    <>
      <div className="m-3 h-[calc(100vh-4.5rem)]">
        <div id="scrollableDiv" className="p-2 m-2 bg-white rounded-lg h-full overflow-y-scroll">
          <p className="m-2 text-xs">Delivered buy orders on: <span className="font-semibold">{yesterday.toDateString()}</span></p>
          <div className="text-center m-2 h-full">
            <div className="flex py-2 mb-2 text-gray-700 uppercase rounded-lg font-bold bg-gray-100 text-xs divide-x-2 divide-gray-100">
              <div className="p-1 w-32">Order no</div>
              <div className="p-1 flex-1">Time </div>
              <div className="p-1 flex-1">Status</div>
              <div className="p-1 flex-1">Item total</div>
              <div className="p-1 flex-1">Updated by</div>
              <div className="p-1 w-32">Verify</div>
            </div>
            <div className="text-sm h-full">
              <InfiniteScroll
                dataLength={orderLogs.length}
                next={getBuyOrders}
                hasMore={hasMore}
                loader={
                <div className="flex justify-center"> 
                  {/* <svg className="text-indigo-700 animate-spin mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg> */}
                  <p className="font-semibold">Loading...</p>
                </div>}
                scrollableTarget="scrollableDiv"
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {orderLogs?.map((order: any) => (
                  <div className="flex flex-col bg-white hover:bg-gray-50 text-xs rounded-lg mb-2 border" key={order.orderNo}>
                    <div className="flex divide-x-2 divide-gray-100 items-stretch" >
                      <div className="flex justify-center items-center p-1 w-32">{order.orderNo}</div>
                      <div className="flex-1 divide-y-2 divide-gray-100">
                        {order?.changeLogs?.length ? order?.changeLogs?.map((log: OrderLog) => (
                          <div className={`flex w-full divide-x-2 divide-gray-100 ${order?.changeLogs?.length < 2 ? 'h-full' : ''}`} key={log.id}>
                            <div className="p-1 flex-1">{formatDate(log?.updatedAt, {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            })}</div>
                            <div className="p-1 flex-1">{log?.status}</div>
                            <div className="p-1 flex-1">{log?.itemTotal} KD</div>
                            <div className="p-1 flex-1">{log?.updatedBy}</div>
                          </div>
                        )) : <p className="h-full flex justify-center items-center">No updates</p>}
                      </div>
                      <div className="flex p-1 w-32 items-center justify-center">
                        {order.buyChangesVerified || !order?.changeLogs?.length ?
                          <span className="flex justify-center items-center w-5 h-5 bg-mashkorGreen/20 rounded-full">
                            <svg className="w-5 h-5 text-mashkorGreen" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>                            </span>
                          : <button onClick={() => verify(order.orderId)} type="button" className="p-2 flex justify-between items-center bg-blue-600 text-white font-medium text-xs leading-normal uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                            <span>VERIFY</span>
                          </button>}
                      </div>
                    </div>
                    <div className="flex divide-x-2 divide-gray-100">
                      {order?.pickupImages?.map((image: string, i: number) => (
                        <div className="flex-1 flex gap-2 py-1 pl-1 first:pr-0.5 items-center justify-center border-t-2 border-gray-100" key={image}>
                          <svg
                            fill="#000000"
                            width="800px"
                            height="800px"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            data-name="Layer 1"
                            className="w-5 h-5"
                          >
                            <path d="M13,16H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2ZM9,10h2a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Zm12,2H18V3a1,1,0,0,0-.5-.87,1,1,0,0,0-1,0l-3,1.72-3-1.72a1,1,0,0,0-1,0l-3,1.72-3-1.72a1,1,0,0,0-1,0A1,1,0,0,0,2,3V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12ZM5,20a1,1,0,0,1-1-1V4.73L6,5.87a1.08,1.08,0,0,0,1,0l3-1.72,3,1.72a1.08,1.08,0,0,0,1,0l2-1.14V19a3,3,0,0,0,.18,1Zm15-1a1,1,0,0,1-2,0V14h2Zm-7-7H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z" />
                          </svg>
                          <span className="hover:cursor-pointer" onClick={() => { setInvoice(image) }}>Invoice ({i + 1})</span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          </div>
          {(invoice && invoice.length) ? <OrderInvoiceModel image={invoice} setInvoice={setInvoice} /> : <></>}
        </div>
      </div>
    </>
  )
}