import { fetchEarningsConfig, save } from "../services/earnings.services"
import { actions as earningsActions, initialState } from "../store/earnings.store";
import { store } from "../../../redux";
import { EARNINGS_STORE_NAME } from "../../../contants/stores";
import { EarningConfig } from "../types/earning.data";

export const getEarnings = async () => {
  const query = await fetchEarningsConfig();
  store.dispatch(earningsActions.setEarningConfigs(query.data()));
  store.dispatch(earningsActions.setDefaultEarningConfig(query.data()));
}

export const deleteEarningConfig = async (title: string) => {
  let configs = [...store.getState()[EARNINGS_STORE_NAME].earningConfigs.filter((c: EarningConfig) => c.title !== title)];
  await save(configs)
  store.dispatch(earningsActions.updateEarningConfigs(configs));
}

export const updateEarningConfigs = async (config: EarningConfig) => {
  let configs = [...store.getState()[EARNINGS_STORE_NAME].earningConfigs];

  const updateIndex = configs.findIndex((c: EarningConfig) => c.title === config.title);
  if(updateIndex > -1) {
    configs.splice(updateIndex, 1, {
      ...config,
    });
  } else {
    configs.push({...config})
  }
  await save(configs)
  store.dispatch(earningsActions.updateEarningConfigs(configs));
}



export const updateQuery = (query: string) => {
  store.dispatch(earningsActions.updateSearchQuery(query));
}

export function search(query: string) {
  const configs = store.getState()[EARNINGS_STORE_NAME].earningConfigs;
  return configs.filter((item: any) => {
    return (
      item.title.toLowerCase().includes(query.toLowerCase())
    );
  });
}