import { FormEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/input";
import { updateBusinessConfig, updateBusyModeHistory } from "../../actions/settings.actions";
import { SETTINGS_STORE_NAME } from "../../../../contants/stores";
import { useAppSelector } from "../../../../redux";

export default function AppBusyMode(props: any) {
  const submitButtonRefBusyMode = useRef() as MutableRefObject<{ click: Function }>;
  const config = useAppSelector(state => state[SETTINGS_STORE_NAME].businessConfig);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [busyMode, setBusyMode] = useState(false);
  const [isNextDay, setIsNextDay] = useState(false);

  useEffect(() => {
    setStartTime(config.startTime);
    setEndTime(config.endTime);
  }, [config]);

  const changeBusinessConfig = async () => {
    try {
      await updateBusinessConfig(busyMode, startTime, endTime);
      await updateBusyModeHistory(busyMode);
    } catch (error) {
      console.log('error-changeBusinessConfig: ', error)
    }
  }

  useEffect(() => {
    if (getTimeInMins(endTime) < getTimeInMins(startTime)) {
      setIsNextDay(true);
    } else {
      setIsNextDay(false);
    }
  }, [startTime, endTime])

  const getTimeInMins = (time: string) => {
    let parts = [time?.toString()?.split(':')[0], time?.toString()?.split(':')[1]];
    const timeInMins = parseInt(parts[0]) * 60 + parseInt(parts[1]);
    return timeInMins;
  }
  return (
    <>
      <form onSubmit={(e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation()
        submitButtonRefBusyMode?.current?.click();
      }} className="text-sm p-2">
        <div className="my-2 mb-4">
          <p className="font-bold">
            Mashkor App business configuration
          </p>
          <p className="mt-1 text-sm">
            Update business configuration
          </p>
        </div>
        <div className="py-2 border-t border-gray-300">
          <p className="font-semibold my-2">
            Busy mode settings
          </p>
          {config?.busyMode ? <p className="my-2 text-red-400"> Busy mode is active </p> : <></>}
          <div className="flex items-center gap-2">
            <span className={`text-sm text-gray-900 dark:text-gray-300 ${!busyMode ? "font-bold" : ""}`}>Inactive</span>
            <label className="relative inline-flex items-center mr-1 cursor-pointer">
              <input type="checkbox" className="sr-only peer" defaultChecked={config.busyMode} value='' onChange={(e: any) => { setBusyMode(e.target.checked) }} />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-indigo-300 dark:peer-focus:ring-indigo-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-indigo-600"></div>
            </label>
            <span className={`text-sm text-gray-900 dark:text-gray-300 ${busyMode ? "font-bold" : ""}`}>Active</span>
          </div>

        </div>
        <div className="py-2 border-t border-gray-300">
          <p className="font-semibold my-2">
            Operational hours settings
          </p>
          <div className="flex items-center gap-3">
            <p className="mr-2">Customer app operational hours</p>
            <div className="flex items-center">
              <Input type="time" value={config.startTime}
                onChange={(e: any) => {
                  setStartTime(e.target.value)
                }}
                rules={{
                  required: false,
                }}
              />
              <span className="mx-2">To</span>
              <>
              <Input type="time" value={config.endTime}
                onChange={(e: any) => {
                  setEndTime(e.target.value)
                }}
                rules={{
                  required: false,
                }}
              />
              {isNextDay ? <p className="m-2 text-red-400">Next day</p> : <></>}
              </>
            </div>
          </div>
        </div>
        <div className="py-2 border-t border-gray-300">
          <div className="w-fit float-right">
            <Button
              ref={submitButtonRefBusyMode}
              type="submit"
              notify={false}
              id="busyMode"
              cb={changeBusinessConfig}
              text="Save"
              loadingText="Saving..."
              successMsg="Changes successfully"
              errorMsg="Incorrect email or password"
            />
          </div>

        </div>

      </form>
    </>
  )
}