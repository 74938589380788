import { createSlice } from "@reduxjs/toolkit"
import { AGENT_STORE_NAME } from "../../../contants/stores";
import { Agent } from '../types/agent.data';

interface AgentState {
  agents: Agent[],
  searchQuery: string,
  status: string,
  error: Error | undefined
}

const initialState: AgentState = {
  agents: [],
  searchQuery: '',
  status: 'idle',
  error: undefined
}

export const slice = createSlice({
  name: AGENT_STORE_NAME,
  initialState,
  reducers: {
    setAgents: (state, action) => {
      state.agents = action.payload;
    },
    // updateStatus(state, action) {
    //   const agent = state.agents.find((a) => a.id === action.payload.id);
    //   if(agent) {
    //     agent.status = action.payload.status;
    //     // update active agents count
    //   }
    // },
    updateAgent: (state, action) => {
      let orderIndex = state.agents.findIndex(
        (o) => o.id === action.payload.id
      );
      if (orderIndex >= 0) {
        state.agents.splice(orderIndex, 1, {
          ...action.payload.data,
          id: action.payload.id,
        });
      }
    },
    updateSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },

  },
});

export const actions = slice.actions
export const reducer = slice.reducer
