export enum timelineTypes {
  PAYMENT = "PAYMENT",
  CART = "CART",
  ADDRESS = "ADDRESS",
  TRANSFER = "ORDER_TRANSFER",
  ORDER_STATUS = "ORDER_STATUS",
  PRIORITY = "PRIORITY",
  AGENT = "AGENT",
  BUDDY = "BUDDY",
  CUSTOMER="CUSTOMER",
  ORDER = "ORDER"
}
export enum timelineNames {
  NEW = "ORDER PLACED",
  PAYMENT_METHOD = "CHANGE PAYMENT METHOD",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  VERIFIED = "ORDER_VERIFIED",
  SELLER_ORDER_ACCEPTED = "SELLER_ORDER_ACCEPTED",
  SELLER_ORDER_READY = "SELLER_ORDER_READY",
  BUDDY_REQ = "BUDDY_REQ",
  BUDDY_REQ_CONFIRMED = "BUDDY REQ CONFIRMED",
  BUDDY_ACCEPTED = "BUDDY ACCEPTED",
  BUDDY_DECLINED = "BUDDY DECLINED",
  BUDDY_UNASSIGNED = "BUDDY UNASSIGNED",
  BUDDY_ASSIGNED = "BUDDY_ASSIGNED",
  BUDDY_QUEUED = "BUDDY_QUEUED",
  IN_PICKUP = "IN_PICKUP",
  ARRIVED_PICKUP = "ARRIVED_PICKUP",
  PICKED_UP = "PICKED_UP",
  IN_DELIVERY = "IN_DELIVERY",
  ARRIVED_DESTINATION = "ARRIVED_DESTINATION",
  DELIVERED = "DELIVERED",
  CANCELLED = "ORDER_CANCELLED",
  RESCHEDULED = "RESCHEDULED",
  ONLINE_PAYMENT_REQUESTED = "ONLINE_PAYMENT_REQUESTED",
  ORDER_TRANSFER = "ORDER_TRANSFERED",
  CART_UPDATE = "CART_UPDATED",
  ADDRESS_UPDATE = "ADDRESS_UPDATED",
  DELAY = "DELAY",
  ORDER_VERIFIED = "ORDER_VERIFIED",
  ON_HOLD = "ON_HOLD",
  PRIORITY_UPDATED = "PRIORITY_UPDATED",
  AGENT_CHANGED = "AGENT_CHANGED",
  AGENT_LOGOUT = "AGENT_LOGOUT",
  CUSTOMER_COMPENSATION="COMPENSATED CUSTOMER",
  BUDDY_NOTIFICATION_VALIDATION="VALIDATION NOTIFICATION",
  BUDDY_NOTIFICATION_NOTE="NOTE NOTIFICATION",
  ORDER_TYPE = "CHANGE_ORDER_TYPE"

}
