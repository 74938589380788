import Modal from "../../../components/Dialog/Modal";
import CustomerDetails from "./customerDetails";
import { close } from "../actions/customer.actions";

export function CustomerDetailModal(props: any) {

  return (
    <Modal title="Customer details"
      onClose={() =>{close()}}>

      <div className="-mx-2 my-2">
        <CustomerDetails searchCustomer={props?.searchCustomer}/>
      </div>
      
    </Modal>
  )
}