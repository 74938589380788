export default function DistanceDifference(props: any) {
  return (
    <>
      <div className="w-full bg-gray-50 flex gap-2 p-2 rounded-md">
        {props.distance > 400 ? 
        <svg
        viewBox="0 0 512 512"
        fill="currentColor"
        className="w-5 h-5 text-red-400"
      >
      <path d="M449.07 399.08L278.64 82.58c-12.08-22.44-44.26-22.44-56.35 0L51.87 399.08A32 32 0 0080 446.25h340.89a32 32 0 0028.18-47.17zm-198.6-1.83a20 20 0 1120-20 20 20 0 01-20 20zm21.72-201.15l-5.74 122a16 16 0 01-32 0l-5.74-121.95a21.73 21.73 0 0121.5-22.69h.21a21.74 21.74 0 0121.73 22.7z" />
      </svg>
        : <svg
          width="800px"
          height="800px"
          viewBox="0 0 64 64"
          xmlns="http://www.w3.org/2000/svg"
          strokeWidth={3}
          stroke="#000000"
          fill="none"
          className="w-5 h-5"
        >
          <path d="M17.94,54.81a.1.1,0,0,1-.14,0c-1-1.11-11.69-13.23-11.69-21.26,0-9.94,6.5-12.24,11.76-12.24,4.84,0,11.06,2.6,11.06,12.24C28.93,41.84,18.87,53.72,17.94,54.81Z" />
          <circle cx={17.52} cy={31.38} r={4.75} />
          <path d="M49.58,34.77a.11.11,0,0,1-.15,0c-.87-1-9.19-10.45-9.19-16.74,0-7.84,5.12-9.65,9.27-9.65,3.81,0,8.71,2,8.71,9.65C58.22,24.52,50.4,33.81,49.58,34.77Z" />
          <circle cx={49.23} cy={17.32} r={3.75} />
          <path d="M17.87,54.89a28.73,28.73,0,0,0,3.9.89" />
          <path
            d="M24.68,56.07c2.79.12,5.85-.28,7.9-2.08,5.8-5.09,2.89-11.25,6.75-14.71a16.72,16.72,0,0,1,4.93-3"
            strokeDasharray="7.8 2.92"
          />
          <path d="M45.63,35.8a23,23,0,0,1,3.88-.95" />
        </svg>}
        <p className="font-bold">
          Distance difference {props?.distance ?? '100'}mts
        </p>
      </div>
    </>
  )
}