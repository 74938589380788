import { MutableRefObject, useRef, useEffect, useState } from "react"
import Button from "../../../../../components/Button/Button";
import Input from "../../../../../components/Input/input";
import { saveAddress, updateAddressCourier, updateOrderInfoOnAddressUpdate } from "../../../actions/order.actions";
import EditAddressMap from "./editAddressMap";
import EditAddressPaci from "./editAddressPaci";
import AddressTypes from "./addressTypeButton";
import AddNewAddressButton from "./addNewAddressButton";
import SaveAddressesDropDown from "./savedAddressesDropdown";
import RefineAddress from "./refineAddress";
import DistanceDifference from "./addressDisctanceDifference";

import { getInitialLatLngFromStreet } from "../../../actions/config.actions";
import { calculateLinearDistance } from "../../../../../utils/distance";

export default function EditAddress(props: any) {
  const formSubmitButtonRef = useRef() as MutableRefObject<{ click: Function }>;

  const [activeTab, setActiveTab] = useState('map');
  const [activeMode, setActiveMode] = useState('edit');
  const [updatedAddress, setUpdatedAddress]: [any, Function] = useState();
  const [selectedArea, setSelectedArea]: [any, Function] = useState();
  const [selectedBlock, setSelectedBlock]: [any, Function] = useState();
  const [selectedStreet, setSelectedStreet]: [any, Function] = useState();
  const [paciDetails, setPaciDetails]: [any, Function] = useState();
  const [selectedMapAddress, setSelectedMapAddress]: [any, Function] = useState();
  const [location, setLocation]: [any, Function] = useState();
  const [savedAddressId, setSavedAddressId]: [any, Function] = useState(null);
  const [distance, setDistance]: [any, Function] = useState(0);
  const [addressType, setAddressType]: [any, Function] = useState('HOME');

  useEffect(() => {
    
    setLocation(props?.address?.location);

    if(!props?.address?.type) {
      setUpdatedAddress({ ...props?.address, type: addressType });
    } else { 
      setUpdatedAddress({ ...props?.address });
      setAddressType(props?.address?.type);
    }

    console.log("EditAddressv", props?.address);

    async function fetchMyAPI() {
      if(props?.address?.street_id) {
        const latLng : any = await getInitialLatLngFromStreet(props?.address?.area_id, props?.address?.block_id, props?.address?.street_id);
        const d = await calculateLinearDistance(latLng?.lat , latLng?.lng, props?.address?.location?._lat, props?.address?.location?._long);
        setDistance(d);
      }
    }
    fetchMyAPI();

    
  }, [props?.address?.id]);

  function handleChange(e: any, name: string) {
    let address = {...updatedAddress};
    address[name] = e.target.value;
    setUpdatedAddress(address);
  }

  // function handleChange(e: any, name: string) {
  //   const updatedAddress = { ...props?.updatedAddress, [name]: e.target.value };
  //   setUpdatedAddress(updatedAddress);
  // }

  

  const saveChanges = async () => {
    let str = '';
    let additionalText = (updatedAddress.type == 'HOME' || updatedAddress.type == 'STORE') ? 'Landmark' : 'RM';

    str = updatedAddress.type ? str.concat( ` | Type: ${updatedAddress.type}`) : str;
    str = updatedAddress.building ? str.concat( ` | BLDG: ${updatedAddress.building}`) : str;
    str = updatedAddress.floorNumber ? str.concat( ` | FLR: ${updatedAddress.floorNumber}`) : str;
    str = updatedAddress.roomNumber ? str.concat( ` | ${additionalText}: ${updatedAddress.roomNumber}`) : str;
    str = updatedAddress.area ? str.concat( ` | Area: ${updatedAddress.area}`) : str;
    str = updatedAddress.block ? str.concat( ` | Block: ${updatedAddress.block}`) : str;
    str = updatedAddress.street ? str.concat( ` | Street: ${updatedAddress.street}`) : str;
    str = updatedAddress.avenue ? str.concat( ` | ${updatedAddress.avenue}`) : str;

    const address = {
      ...updatedAddress,
      addressStr: str, isValid: false, type: addressType
    }
    
    
    setUpdatedAddress(address);

    const orderValidation = props?.orderType !== 'COURIER' ? props?.type === 'pickup' ? {
      ...props?.orderValidation,
      pickupValidate: {
        ...props?.orderValidation.pickupValidate,
        updated: true
      }
    } : {
      ...props?.orderValidation,
      deliveryValidate: {
        ...props?.orderValidation.deliveryValidate,
        updated: true
      }
    } : {};
    console.log("updatedAddres: ", address)
    
    await Promise.all([
      ...(props?.orderType !== 'COURIER' ? [updateOrderInfoOnAddressUpdate(address, props?.type, props?.orderId, props?.orderNo)] : []),
      ...(props?.deliveryNo ? [updateAddressCourier(address, activeTab, props?.type, props?.deliveryNo, props?.orderType, props?.orderId)] : []),
      saveAddress(props?.orderId, props?.orderNo, props?.type, address, props?.fleetId, props?.orderType, props?.userId, orderValidation, savedAddressId, activeMode)
    ]);
  }

  const changeActiveMode = (mode: string) => {
    setActiveMode(mode);
    setUpdatedAddress();
    setSavedAddressId(null);
    setDistance(0)
  }

  return (
    <>
      <div className="flex flex-col gap-4 p-2">
        {props.orderType !== 'COURIER' ? <>
          <AddNewAddressButton changeActiveMode={changeActiveMode} setUpdatedAddress={setUpdatedAddress} setSavedAddressId={setSavedAddressId} 
          setAddressType={setAddressType} setLocation={setLocation}/>
          <SaveAddressesDropDown setUpdatedAddress={setUpdatedAddress} setSavedAddressId={setSavedAddressId} otherAddress={props.otherAddress} setAddressType={setAddressType}/>
        </> : <></>}
        <div>
          <EditAddressMap updatedAddress={updatedAddress} setUpdatedAddress={setUpdatedAddress} setPaciDetails={setPaciDetails}
            mode={activeTab} setSelectedMapAddress={setSelectedMapAddress} location={location} setLocation={setLocation} />
          <RefineAddress addressStr={updatedAddress?.addressStr} name={updatedAddress?.name} area={updatedAddress?.area} block={updatedAddress?.block} street={updatedAddress?.street}/>
        </div>
        <DistanceDifference distance={distance} />
        <form onSubmit={(e: any) => {
          e.preventDefault();
          formSubmitButtonRef?.current?.click();
        }} className="flow-root">
          <div className="flex gap-2 flex-1 flex-col bg-white rounded-lg h-1/2 w-full">
            <fieldset className="mb-2 border border-solid border-gray-300 px-3 pb-3 rounded-lg">
              <legend className="text-sm px-1">Kuwait location details</legend>
              <EditAddressPaci updatedAddress={updatedAddress} setUpdatedAddress={setUpdatedAddress} location={location} setLocation={setLocation}
                selectedArea={selectedArea} selectedBlock={selectedBlock} selectedStreet={selectedStreet} primaryStatus={props.primaryStatus} paciDetails={paciDetails}
                setSelectedArea={setSelectedArea} setSelectedBlock={setSelectedBlock} setSelectedStreet={setSelectedStreet} setDistance={setDistance}/>
            </fieldset>

            <fieldset className="mb-2 border border-solid border-gray-300 px-3 pb-3 rounded-lg">
              <legend className="text-sm px-1">Address details</legend>
              <AddressTypes updatedAddress={updatedAddress} setUpdatedAddress={setUpdatedAddress} addressType={addressType} setAddressType={setAddressType} onChange={handleChange}
              primaryStatus={props.primaryStatus} orderType={props.orderType}/>
            </fieldset>
            <fieldset className="mb-2 border border-solid border-gray-300 px-3 pb-3 rounded-lg">
              <legend className="text-sm px-1">Contact details</legend>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                  <Input
                    rules={{
                      required: false,
                      minLength: 3,
                      maxLength: 25,
                      disabled: props.primaryStatus === "OTHERS" ? true : false,
                    }}
                    labelText="Contact name" onChange={(e: any) => handleChange(e, "contactName")} value={updatedAddress?.contactName ?? ''} defaultValue={updatedAddress?.contactName ?? ''} type="text" placeholder="contactName" />
                </div>
                <div className="flex-1">
                  <Input
                    rules={{
                      required: false,
                      minLength: 7,
                      maxLength: 11,
                      pattern: "[0-9]{7,11}",
                      disabled: props.primaryStatus === "OTHERS" ? true : false,
                    }}
                    labelText="contact phone" onChange={(e: any) => handleChange(e, "contactPhone")} value={updatedAddress?.contactPhone ?? ''} defaultValue={updatedAddress?.contactPhone ?? ''} type="tel" placeholder="contactPhone" />
                </div>
              </div>
            </fieldset>
            {props?.primaryStatus === "OTHERS" ? <></> : <div className="flex items-center justify-end">
              <Button
                ref={formSubmitButtonRef}
                type="submit"
                notify={false}
                id="editAddress"
                cb={saveChanges}
                text="Save"
                loadingText="Saving..."
                successMsg="Changes saved successfully"
              />
            </div>}
          </div>
        </form>
      </div>
    </>
  )
}
