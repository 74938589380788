
import { MutableRefObject, useEffect, useRef, useState } from "react";
import Dialog from "../../../components/Dialog/SideDrawer";
import Input from "../../../components/Input/input";
import Button from "../../../components/Button/Button";
import Select from "../../../components/Select/select";
import { createPromoCode, updatePromoCode } from "../actions/propmoCode.actions";

const orderTpeOpts = ['ALL', 'CUSTOM_ORDER', 'BUY', 'PICKUP', 'SELLER'];
const discountOnOpts = ['DELIVERY', 'SERVICE', 'DELIVERY_AND_SERVICE'];
const discountTypeOpts = ['PERCENTAGE', 'AMOUNT'];
const userTypeOpts = ['ALL', 'DOMAIN', 'USER', 'ROLE', 'SEGMENT'];

const PromoCodeDialog = (props: any) => {
  const formSubmitButtonRef = useRef() as MutableRefObject<{ click: Function }>;
  const [open, setOpen] = useState(true);
  const [code, setCode] = useState({...props.code, createdAt: new Date(props.code.createdAt), isCompensationCode: props.code.isCompensationCode ?? false});
  const form = useRef() as MutableRefObject<HTMLFormElement>;


  useEffect(() => {
    setOpen(true);
  }, [code.id]);

  function onClose(value: boolean) {
    setOpen(value);
    if (!value) {
      props.onClose();
    }
  }

  const makeNestedObjWithArrayItemsAsKeys = (arr: string[], value: any) => arr.reduceRight(
    (accumulator, item, cIndex) => {
      const newAccumulator: { [key: string]: any } = {};
      newAccumulator[item] = cIndex === arr.length - 1 ? value : Object.assign(
        {},
        accumulator
      );
      return newAccumulator;
    },
    {}
  );

  function isObject(item: any) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  function merge(target: any, ...sources: any[]): any {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          merge(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return merge(target, ...sources);
  }

  const saveChanges = async () => {
    // setCode({ ...code, toPreload: code.isNewUserCode ? true : (code.toPreload ? true : false) });
    if (code.id) {
      await updatePromoCode(code.id, code);
    } else {
      // Create new
      await createPromoCode({ ...code, toPreload: code.isNewUserCode ? true : (code.toPreload ? true : false) });
    }
    props.onClose();
  }

  return (
    <Dialog onClose={onClose} open={open} title={`${props.action} promocode`} code={code}>
      <>
        <form
          ref={form}
          onSubmit={(e: any) => {
            e.preventDefault();
            formSubmitButtonRef?.current?.click();
          }} className="flow-root">
          <input name="id" type="hidden" value={code.id} />
          <div className="flex gap-2 flex-1 flex-col bg-white rounded-lg h-1/2 w-full">
            <fieldset className="mb-2 border border-solid border-gray-300 px-3 pb-3 rounded-lg">
              <legend className="text-sm px-1">Code information</legend>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                  <Input onChange={(e: any) => { 
                    setCode({ ...code, title: e.target.value.toUpperCase() }) 
                    if(e.target.value.length > 2) {
                      if(props.titles?.findIndex((title: string) => title.toUpperCase() === e.target.value.toUpperCase().trim()) > -1) {
                        e.target.setCustomValidity("Title must be unique.");
                      } else {
                        e.target.setCustomValidity("");
                      }                      
                    }
                  }}
                    rules={{
                      required: true,
                      maxLength: 50,
                      minLength: 3
                    }}
                    className="h-9"
                    labelText="Title" name="title" value={code?.title} defaultValue={code?.title} type="text" placeholder="title" />
                </div>
                <div className="flex-1">
                  <Input onChange={(e: any) => { setCode({ ...code, expiresAt: new Date(e.target.value).toISOString().split('T')[0] }) }}
                    rules={{
                      required: true,
                    }}
                    className="h-9"
                    labelText="Expiry Date" name="expiresAt" value={code?.expiresAt} defaultValue={code?.expiresAt} type="date" placeholder="Expires At" />
                </div>
                <div className="flex-1">
                  <Select onChange={(e: any) => { setCode({ ...code, orderType: e.target.value }) }}
                    rules={{
                      required: true,
                    }} value={code?.orderType}
                    name="orderType"
                    className="h-9"
                    id="orderType">
                    <option value={code?.orderType ?? ''} disabled hidden>{code?.orderType ?? 'Choose order type'}</option>
                    {orderTpeOpts?.map((t: any) => (
                      <option key={t} value={t}>{t}</option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                  <Input onChange={(e: any) => { 
                    setCode({ ...code, header: e.target.value}) 
                  }}
                    rules={{
                      required: true,
                      maxLength: 200,
                      minLength: 3
                    }}
                    className="h-9"
                    labelText="Header" name="header" value={code?.header} defaultValue={code?.header} type="text" placeholder="header" />
                </div>
                <div className="flex-1">
                  <Input onChange={(e: any) => { 
                    setCode({ ...code, headerAr: e.target.value}) 
                  }}
                    rules={{
                      required: true,
                      maxLength: 200,
                      minLength: 3
                    }}
                    className="h-9"
                    labelText="Header AR." name="headerAr" value={code?.headerAr} defaultValue={code?.headerAr} type="text" placeholder="headerAr" />
                </div>
              </div>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                  <Input onChange={(e: any) => { 
                    setCode({ ...code, description: e.target.value}) 
                  }}
                    rules={{
                      required: true,
                      maxLength: 200,
                      minLength: 3
                    }}
                    className="h-9"
                    labelText="Description" name="description" value={code?.description} defaultValue={code?.description} type="text" placeholder="description" />
                </div>
                <div className="flex-1">
                  <Input onChange={(e: any) => { 
                    setCode({ ...code, descriptionAr: e.target.value}) 
                  }}
                    rules={{
                      required: true,
                      maxLength: 200,
                      minLength: 3
                    }}
                    className="h-9"
                    labelText="Description AR." name="descriptionAr" value={code?.descriptionAr} defaultValue={code?.descriptionAr} type="text" placeholder="descriptionAr" />
                </div>
              </div>
              <div className="flex gap-2 justify-between items-center text-xs font-medium text-gray-900 mt-3">
                <label className="space-x-1"
                  key="active"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e: any) => {
                    setCode({ ...code, isPromoCodeActive: e.target.checked })
                  }}>
                  <input type="checkbox" defaultChecked={code.isPromoCodeActive} value="active" className="form-tick  bg-white bg-check h-3 w-3 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none" />
                  <span className="font-normal text-gray-700">
                    Active
                  </span>
                </label>
                <label className="space-x-1"
                  key="listed"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e: any) => {
                    setCode({ ...code, isListed: e.target.checked })
                  }}>
                  <input type="checkbox" defaultChecked={code.isListed} value="listed" className="form-tick  bg-white bg-check h-3 w-3 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none" />
                  <span className="font-normal text-gray-700">
                    Listed
                  </span>
                </label>
                <label className="space-x-1"
                  key="user"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e: any) => {
                    setCode({ ...code, isNewUserCode: e.target.checked});
                  }}>
                  <input type="checkbox" defaultChecked={code.isNewUserCode} value="user" className="form-tick  bg-white bg-check h-3 w-3 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none" />
                  <span className="font-normal text-gray-700">
                    New user
                  </span>
                </label>
                <label className="space-x-1"
                  key="preload"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e: any) => {
                    setCode({ ...code, toPreload: e.target.checked});
                  }}>
                  <input type="checkbox" defaultChecked={code.toPreload} value="preload" className="form-tick  bg-white bg-check h-3 w-3 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none" />
                  <span className="font-normal text-gray-700">
                    Preload
                  </span>
                </label>
                <label className="space-x-1"
                  key="compensation">
                  <input type="checkbox" disabled defaultChecked={code.isCompensationCode} value="compensation" className="form-tick  bg-white bg-check h-3 w-3 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none" />
                  <span className="font-normal text-gray-700">
                    Compensation
                  </span>
                </label>
              </div>
            </fieldset>
            <fieldset className="mb-2 border border-solid border-gray-300 px-3 pb-3 rounded-lg">
              <legend className="text-sm px-1">Discount information</legend>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                  <Select onChange={(e: any) => {
                    setCode({
                      ...code,
                      discount: {
                        ...code.discount,
                        on: e.target.value,
                      }
                    })
                  }}
                    rules={{
                      required: true,
                    }} value={code?.discount?.on}
                    className="h-9"
                    id="discountOn" name="discount.on"
                    labelText="On"
                  >
                    <option value={code?.discount?.on ?? ''} disabled hidden>{code?.discount?.on ?? 'Choose type'}</option>
                    {discountOnOpts?.map((t: any) => (
                      <option key={t} value={t}>{t}</option>
                    ))}
                  </Select>
                </div>
                <div className="flex-1">
                  <Select onChange={(e: any) => {
                    setCode({
                      ...code,
                      discount: {
                        ...code.discount,
                        type: e.target.value,
                      }
                    })
                  }}
                    rules={{
                      required: true,
                    }} value={code?.discount?.type}
                    className="h-9"
                    id="discountType" name="discount.type"
                    labelText="Type"
                  >
                    <option value={code?.discount?.type ?? ''} disabled hidden>{code?.discount.type ?? 'Choose type'}</option>
                    {discountTypeOpts?.map((t: any) => (
                      <option key={t} value={t}>{t}</option>
                    ))}
                  </Select>
                </div>
                <div className="flex-1">
                  <Input className="h-9" onChange={(e: any) => {
                    setCode({
                      ...code,
                      discount: {
                        ...code.discount,
                        value: +e.target.value,
                      }
                    })
                  }}
                    rules={{
                      required: true,
                      min: 1
                    }} labelText="Value" name="discount.value" value={+code?.discount.value} defaultValue={code?.discount.value} type="number" placeholder="1" />
                </div>
              </div>
            </fieldset>
            <fieldset className="mb-2 border border-solid border-gray-300 px-3 pb-3 rounded-lg">
              <legend className="text-sm px-1">User information</legend>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                  <Select onChange={(e: any) => {
                    setCode({
                      ...code,
                      user: {
                        ...code.user,
                        type: e.target.value,
                      }
                    })
                  }}
                    rules={{
                      required: true,
                    }} className="h-9"
                    id="userType" name="user.type"
                    labelText="Type" value={code?.user?.type}
                  >
                    <option value={code?.user?.type ?? ''} disabled hidden>{code?.user.type ?? 'Choose type'}</option>
                    {userTypeOpts?.map((t: any) => (
                      <option key={t} value={t}>{t}</option>
                    ))}
                  </Select>
                </div>
                <div className="flex-1">
                  <Input className="h-9" onChange={(e: any) => {
                    setCode({
                      ...code,
                      user: {
                        ...code.user,
                        reference: e.target.value,
                      }
                    })
                  }} rules={{
                    required: code?.user?.type === 'ALL' ? false : true,
                    maxLength: 50,
                    pattern: code?.user?.type === 'USER' ? "[0-9]{7,11}" ? code?.user?.type === 'DOMAIN' : '[a-zA-Z]+\.[a-zA-Z]+' : null,
                    disabled: code?.user?.type === "ALL" ? true : false,
                  }} name="user.reference" labelText="Reference" value={code?.user?.reference} defaultValue={code?.user?.reference ?? ''} type={code?.user?.type === 'USER' ? 'tel' : "text"} placeholder="reference" />
                </div>
              </div>
            </fieldset>
            <fieldset className="mb-2 border border-solid border-gray-300 px-3 pb-3 rounded-lg">
              <legend className="text-sm px-1">Promocode applicable time</legend>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                  <Input onChange={(e: any) => {
                    setCode({
                      ...code,
                      time: {
                        ...code.time,
                        from: e.target.value,
                      }
                    })
                  }} className="h-9"
                    rules={{
                      required: true,
                      pattern: '[0-9]{2}:[0-9]{2}$'
                    }}
                    labelText="From" name="time.from" value={code?.time.from} defaultValue={code?.time.from} type="text" placeholder="00:00" />
                </div>
                <div className="flex-1">
                  <Input onChange={(e: any) => {
                    setCode({
                      ...code,
                      time: {
                        ...code.time,
                        to: e.target.value,
                      }
                    })
                  }} className="h-9"
                    rules={{
                      required: true,
                      pattern: '[0-9]{2}:[0-9]{2}$'
                    }}
                    labelText="To" name="time.to" value={code?.time?.to} defaultValue={code?.time?.to} placeholder="23:59" />
                </div>
              </div>
            </fieldset>
            <fieldset className="mb-2 border border-solid border-gray-300 px-3 pb-3 rounded-lg">
              <legend className="text-sm px-1">Code usage </legend>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                  <Input onChange={(e: any) => {
                    setCode({
                      ...code,
                      usage: {
                        ...code.usage,
                        code: {
                          ...code.usage.code,
                          maxUsage: +e.target.value
                        }
                      }
                    })
                  }} className="h-9"
                    rules={{
                      required: true,
                      min: 1,
                      step: 1
                    }}
                    labelText="Overall code usage" name="usage.code.maxUsage" value={+code?.usage?.code?.maxUsage} defaultValue={+code?.usage?.code?.maxUsage} type="number" placeholder={1000} />
                </div>
                <div className="flex-1">
                  <Input onChange={(e: any) => {
                    setCode({
                      ...code,
                      usage: {
                        ...code.usage,
                        code: {
                          ...code.usage.code,
                          dailyUsage: +e.target.value
                        }
                      }
                    })
                  }} className="h-9"
                    rules={{
                      required: true,
                      min: 1,
                      step: 1
                    }}
                    labelText="Daily code usage" name="usage.code.dailyUsage" value={+code?.usage?.code?.dailyUsage} defaultValue={+code?.usage?.code?.dailyUsage} type="number" placeholder={10} />
                </div>
              </div>
            </fieldset>
            <fieldset className="mb-2 border border-solid border-gray-300 px-3 pb-3 rounded-lg">
              <legend className="text-sm px-1">Code usage/per user </legend>
              <div className="flex gap-2 justify-between items-center text-base font-medium text-gray-900">
                <div className="flex-1">
                  <Input onChange={(e: any) => {
                    setCode({
                      ...code,
                      usage: {
                        ...code.usage,
                        user: {
                          ...code.usage.user,
                          maxUsage: +e.target.value
                        }
                      }
                    })
                  }} className="h-9"
                    rules={{
                      required: true,
                      min: 1,
                      step: 1
                    }}
                    labelText="User code usage" name="usage.user.maxUsage" value={+code?.usage?.user?.maxUsage} defaultValue={code?.usage?.code?.maxUsage} type="number" placeholder={1000} />
                </div>
                <div className="flex-1">
                  <Input onChange={(e: any) => {
                    setCode({
                      ...code,
                      usage: {
                        ...code.usage,
                        user: {
                          ...code.usage.user,
                          monthlyLimit: +e.target.value
                        }
                      }
                    })
                  }} className="h-9"
                    rules={{
                      required: true,
                      min: 1,
                      step: 1
                    }}
                    labelText="User monthly amount" name="usage.user.monthlyLimit" value={+code?.usage?.user?.monthlyLimit} defaultValue={code?.usage?.user?.monthlyLimit} type="number" placeholder={100} />
                </div>
                <div className="flex-1">
                  <Input onChange={(e: any) => {
                    setCode({
                      ...code,
                      usage: {
                        ...code.usage,
                        user: {
                          ...code.usage.user,
                          dailyUsage: +e.target.value
                        }
                      }
                    })
                  }} className="h-9"
                    rules={{
                      required: true,
                      min: 1,
                      step: 1
                    }}
                    labelText="User daily limit" name="usage.user.dailyUsage" value={+code?.usage?.user?.dailyUsage} defaultValue={code?.usage?.code?.dailyUsage} type="number" placeholder={10} />
                </div>
              </div>
            </fieldset>
            <div className="flex items-center justify-end">
              <Button
                ref={formSubmitButtonRef}
                type="submit"
                notify={false}
                id="editCode"
                cb={saveChanges}
                text="Save"
                loadingText="Saving..."
                successMsg="Changes saved successfully"
              />
            </div>
          </div>
        </form>
      </>
    </Dialog>
  )
}
export default PromoCodeDialog;