import { useEffect } from "react";
import { getSuccessfulOrderPayments } from "../../../../actions/config.actions";
import { ORDER_STORE_NAME } from "../../../../../../contants/stores";
import { useAppSelector } from "../../../../../../redux";
import Table from "../../../../../../components/Table/Table";
import { formatDate } from "../../../../../../utils/helpers";
import { OrderPayment } from "../../../../types/order.payment";

export function OrderPayments(props: any) {

  const orderPayments = useAppSelector(state => state[ORDER_STORE_NAME].orderPayments);
  const headers = ["Paid at" , "Invoice id", "Payment id", "Amount", "Type", "Customer"];

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      await getSuccessfulOrderPayments(props.orderId)
    }
    fetchData().catch(console.error);
  }, []);
  
  return (
    <>
      <div className="flex justify-between items-center gap-3 my-2 py-2 mx-3">
      <>
      {!orderPayments?.length ?
        <div className="w-full order-1 flex-1 bg-white rounded-lg m-4 ml-0 text-s">
          No successful payments for this order
        </div> :
        <Table headers={headers}>
          {orderPayments && orderPayments?.map((p: OrderPayment) => (
            <tr className="bg-white border-b hover:bg-gray-50 text-xs" key={p?.id}>
              <td className="text-xs py-4 px-6">
                {formatDate(p?.updatedAt)}
              </td>
              <td className="py-4 px-6">
                <p className="text-xs font-normal text-gray-700">{p?.invoiceId}</p>
              </td>
              <td className="py-4 px-6">
                <p className="text-xs font-normal text-gray-700">{p?.paymentId}</p>
              </td>
              <td className="py-4 px-6">
                <p className="text-xs font-normal text-gray-700">{p?.amount} KD</p>
              </td>
              <td className="py-4 px-6">
                <p className="text-xs font-semibold text-gray-700">{p?.type}</p>
              </td>
              <td className="py-4 px-6">
                <p className="text-xsfont-normal text-gray-700">{p?.customerMobile}</p>
                <p className="text-xs text-gray-500">{p?.customerReference}</p>
              </td>
            </tr>
          ))}
        </Table>}
    </>
      </div>
    </>
  )
}