import { createSlice } from "@reduxjs/toolkit"
import { ORDER_LOGS_STORE_NAME } from "../../../contants/stores";

interface CangeLogsState {
  orders: any[],
  lastOrder: any,
  hasMore: boolean,
  status: string,
  error: Error | undefined
}

const initialState: CangeLogsState = {
  orders: [],
  lastOrder: null,
  hasMore: true,
  status: 'idle',
  error: undefined
}

export const slice = createSlice({
  name: ORDER_LOGS_STORE_NAME,
  initialState,
  reducers: {
    setOrders: (state, action) => {
      const ordersToAdd = Array.isArray(action.payload) ? action.payload : [];
      state.orders = [...state.orders, ...ordersToAdd];
    },
    setLastOrder: (state, action) => {
      state.lastOrder = action.payload;
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    updateOrder: (state, action) => {
      const { id, ...updatedFields } = action.payload;
      let orderIndex = state.orders.findIndex(
        (o) => o.orderId === id
      );
      if (orderIndex >= 0) {
        state.orders.splice(orderIndex, 1, {
          ...state.orders[orderIndex],
          ...updatedFields
        });
      }
    },
  },
});

export const actions = slice.actions
export const reducer = slice.reducer

