import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
export function ZiwoContainer() {

  let contactCenterName = ""; //If left empty Jorel will be in non call center specific mode. User will have to enter its call center name on login.

  // Default display configuration
  let defaultPosition = "bottom-right"; //Choose between 'bottom-right', 'bottom-left', 'top-right' and 'top-left'. Initial if not set is 'bottom-right'.
  let defaultSize = "small"; //Choose between 'normal', 'small' and 'mini'. Initial if not set is 'normal'.

  // Settings
  let defaultRegionCode = ""; //Used for local phone numbers used in the API, if you want to set one use the corresponding 2 letters country code. None set by default;
  let fixedPositionAndSize = 'false'; //By default false;
  let deactivateMinimization = 'false'; //By default false;
  let deactivateConsoleOutput = 'false'; //By default false;

  setJorelConfiguration();
  addJorelScriptLoader();

  function addJorelScriptLoader() {
    const jorelScriptLoader = document.createElement("script");
    jorelScriptLoader.id = "jorelScriptLoader";
    jorelScriptLoader.type = "text/javascript";
    jorelScriptLoader.async = true;
    jorelScriptLoader.src = "https://jorel.ziwo.io/master/latest/jorel.js";
    jorelScriptLoader.src += "?v=" + Date.now(); //Prevents caching
    if (jorelScriptLoader) {
      jorelScriptLoader.onload = (e) => {
        console.log('ziwo', e)
      }
    }
    document.body.prepend(jorelScriptLoader);
  }
  function setJorelConfiguration() {
    if (contactCenterName)
    localStorage.setItem("_jorel-ccName", contactCenterName);
    localStorage.setItem("_jorel-default-position", defaultPosition);
    localStorage.setItem("_jorel-default-size", defaultSize);
    localStorage.setItem(
      "_jorel-default-phone-number-region-code",
      defaultRegionCode
    );
    localStorage.setItem("_jorel-fixed", fixedPositionAndSize);
    localStorage.setItem("_jorel-no-minimization", deactivateMinimization);
    localStorage.setItem("_jorel-no-console-output", deactivateConsoleOutput);
  }
  return null;
}
