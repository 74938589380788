export interface OrderPayment {
  id: string;
  paymentId: string;
  invoiceId: number;
  type: string;
  amount: number;
  createdAt: any;
  updatedAt: any;
  customerMobile: string;
  customerReference: string;
}

export function assignPaymentValueToObject(query: any) {
  return {
    id: query.id,
    type: query.data().type,
    invoiceId: query.data()?.type === "APPLE_PAY" ? query.data()?.invoiceIdApplePay : query.data()?.type === "CARD" ? query.data()?.invoiceIdCard : query.data()?.invoiceId,
    paymentId: query.data()?.paymentId,
    createdAt: query.data()?.createdAt.seconds * 1000,
    updatedAt: query.data()?.updatedAt.seconds * 1000,
    amount: query.data()?.response?.InvoiceValue,
    customerMobile: query.data()?.response?.CustomerMobile,
    customerReference: query.data()?.response?.CustomerReference
  };
}